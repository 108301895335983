import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivateDirective } from './can-deactivate/component-can-deactivate.class';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateDirective> {

  canDeactivate(component: ComponentCanDeactivateDirective): boolean {
    if (!component.canDeactivate()) {
      return confirm('You will lose your unsaved changes if you navigate away without saving. Would you like to continue?');
    }
    return true;
  }
}
