import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Optional,
  Self,
  OnDestroy,
  Injector,
  OnChanges,
  Renderer2,
  Inject,
  HostListener,
  HostBinding,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UntypedFormControl, ControlValueAccessor, NgControl, NgForm, FormGroupDirective } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Subject, Observable, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Type } from '@angular/core';

declare var google: any;

export interface Address {
  lat?: number;
  lng?: number;
  address?: string;
}

@Component({
  selector: 'friskus-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AddressInputComponent
    }
  ],
})
export class AddressInputComponent implements MatFormFieldControl<any>,
  ControlValueAccessor, OnDestroy, OnInit, OnChanges {
  static nextId = 0;

  @Output() selected = new EventEmitter<any>();
  @Input() coordinates: Address;
  @Input() initialCoordinates: Address;
  @ViewChild('search', {static: true})
  public searchElementRef: ElementRef;
  public isAddressInvalid = false;
  public isLoading = false;

  public address: UntypedFormControl;

  readonly stateChanges = new Subject<void>();

  get errorState() {
    return this.ngControl.errors !== null && this.ngControl.touched;
  }

  readonly controlType = 'address-autocomplete-input';
  public focused = false;

  protected _previousNativeValue: any;
  private _inputValueAccessor: { value: any };
  private errorStateMatcher: ErrorStateMatcher;

  @HostBinding() id = `${this.controlType}-${AddressInputComponent.nextId++}`;
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding('attr.aria-describedBy') describedBy = '';

  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private sub: Subscription;
  _value: any;

  propogateChange = (_: any) => { };
  propogateTouched = () => { };


  get empty() {
    return !this.address.value;
  }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.address.disable() : this.address.enable();
    this.stateChanges.next();
  }

  get value(): any {
    return this.empty ? null : this._value || '';
  }
  set value(address) {
    this._value = address;
    this.address.setValue(address);
    this.propogateChange(address);
    this.stateChanges.next();
  }

  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    private injector: Injector,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    private renderer: Renderer2,
    @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    @Optional() @Self() public ngControl: NgControl) {
      this.address = new UntypedFormControl('');

      _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe(origin => {
        if (this.focused && !origin) {
          this.propogateTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
      }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  ngOnInit() {
    this.ngControl = this.injector.get<NgControl>(NgControl as unknown as Type<NgControl>);
    if (this.ngControl != null) { this.ngControl.valueAccessor = this; }

    this.loadPlacesAutocomplete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ngControl) {
      this.stateChanges.next();
    }

    if (changes.initialCoordinates && changes.initialCoordinates.firstChange && changes.initialCoordinates.currentValue) {
      this.getAddressFromCoordinates(changes.initialCoordinates.currentValue as Address);
    }

    if (changes.coordinates && changes.initialCoordinates.currentValue) {
      this.getAddressFromCoordinates(changes.coordinates.currentValue as Address);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propogateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propogateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.propogateChange(this.address.value);
  }

  @HostListener('change', ['$event'])
  onChange() {
    this.value = this.address.value;
  }

  @HostListener('focusout')
  onBlur() {
    this.focused = false;
    this.propogateTouched();
    this.stateChanges.next();
  }

  private loadPlacesAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = google.maps.places.PlaceResult = autocomplete.getPlace();

        if (!place.geometry) {
          this.setInputValidity(false);
          window.alert(`No details available for input: ${place.name}`);
          return;
        }

        const addressObj: Address = {};

        addressObj.lng = place.geometry.location.lng();
        addressObj.lat = place.geometry.location.lat();
        addressObj.address = place.formatted_address;

        this.value = addressObj.address;
        this.setInputValidity(true);

        this.selected.emit(addressObj);
      });
    });
  }

  private getAddressFromCoordinates(coordinates: Address) {
    console.log(coordinates);
    if (!coordinates) {
      return;
    }

    const addressSub = new Observable(observer => {
      const latlng = {
        lng: +coordinates.lng,
        lat: +coordinates.lat
      };
      observer.next(`${latlng.lat}, ${latlng.lng}`);
    });

    this.sub = addressSub.subscribe(value => {
      this.value = value;
      this.cd.detectChanges();
    });
  }

  private setInputValidity(isValid: boolean) {
    if (isValid) {
      this.address.setErrors(null);
    } else {
      this.address.setErrors({incorrect: true});
    }
    this.isAddressInvalid = !isValid;
  }

}
