import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { FriskusHttpClient } from './http.helper';

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService {
  private apiUrl: any;
  private deafultContentType: any = {
    name: 'Content-Type',
    value: 'application/json;charset=UTF-8'
  };
  private deafultAcceptType: any = {
    name: 'Accept',
    value: 'application/json, text/plain, */*'
  };

  constructor(
    private http: FriskusHttpClient,
    private localStorageService: LocalStorageService,
  ) {
    this.apiUrl = environment.apiUrl;
  }

  public get(apiUrlKey: string, path: string, searchParams: any = {}, headerArray: [string, string][] = []): Observable<any> {
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;

    let headers = this.shapeHeaders();
    headerArray.forEach(([k,v]) => {
      if (v !== undefined) {
        headers = headers.set(k, v);
      }
    });

    const httpOptions = {
      headers: headers,
      withCredentials: true,
      params: this.getParams(searchParams)
    };

    return this.http
      .get(fullPath, httpOptions)
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  public getFile(apiUrlKey: string, path: string, searchParams: any = {}, headerArray: [string, string][] = []): Observable<any> {
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;
    const params: HttpParams = this.getParams(searchParams);

    let headers = this.shapeHeaders();
    headerArray.forEach(([k,v]) => {
      if (v !== undefined) {
        headers = headers.set(k, v);
      }
    });

    return this.http
      .get(fullPath, { params, responseType: 'blob', headers: headers })
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  public post(apiUrlKey: string, path: string, body: object = {}): Observable<any> {
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;

    const jsonBody: string = JSON.stringify(body);
    const httpOptions = {
      headers: this.shapeHeaders()
    };

    return this.http.post(fullPath, jsonBody, httpOptions)
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  public del(apiUrlKey: string, path: string): Observable<any> {
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;

    const httpOptions = {
      headers: this.shapeHeaders()
    };

    return this.http.delete(fullPath, httpOptions)
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  public put(apiUrlKey: string, path: string, body: object = {}): Observable<any> {
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;

    const jsonBody: string = JSON.stringify(body);
    const httpOptions = {
      headers: this.shapeHeaders()
    };

    return this.http
      .put(fullPath, jsonBody, httpOptions)
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  // public postFiles(apiUrlKey: string, path: string, files: File[]): Observable<any> {
  //   const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;
  //   const httpOptions = {
  //     headers: this.shapeHeaders()
  //   };

  //   return new Observable(observer => {
  //     const formData: FormData = new FormData();
  //     const xhr: XMLHttpRequest = new XMLHttpRequest();
  //     const userInfo = JSON.parse(localStorage.getItem('auth:user'));
  //     const accessToken = userInfo ? userInfo.access_token : null;

  //     for (const file of files) {
  //       formData.append('files', file, file.name);
  //     }

  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           observer.next(JSON.parse(xhr.response));
  //           observer.complete();
  //         } else {
  //           observer.error(xhr.response);
  //         }
  //       }
  //     };

  //     xhr.open('POST', fullPath, true);

  //     if (Boolean(accessToken)) {
  //       xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
  //     }

  //     console.log(fullPath);

  //     xhr.send(formData);
  //   });
  // }

  postFile(apiUrlKey: string, path: string, file: File): Observable<any> {
    const httpOptions = {
      headers: this.shapeFilesHeaders()
    };
    const formData: FormData = new FormData();
    const fullPath = `${this.apiUrl[apiUrlKey]}${path}`;

    formData.append('file', file);

    return this.http
      .post(fullPath, formData, httpOptions)
      .pipe(
        catchError((error) => this.handleError(error))
      );
  }

  private shapeHeaders(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders()
      .set(this.deafultContentType.name, this.deafultContentType.value)
      .set(this.deafultAcceptType.name, this.deafultAcceptType.value);

    return headers;
  }

  private shapeFilesHeaders(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders()
      .set(this.deafultAcceptType.name, this.deafultAcceptType.value);

    return headers;
  }

  private handleError(responseError: HttpResponse<any> | any): Observable<any> {
    console.log(responseError);

    if (responseError.status === 401) {
      // TODO: Show unauthorized error
      console.log('Not logged in!');
      this.clearSession();
    }

    if (responseError.status === 500) {

    }

    return throwError(responseError);
  }

  private clearSession(): void {
    this.localStorageService.clear();
    // this.router.navigate(['/']);
  }

  public getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key] !== undefined) {
        if (query[key] instanceof Array && query[key].length > 0) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }

    return params;
  }
}
