import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {
  public messageSent$ = new BehaviorSubject(false);
  private apiUrl: string;
  constructor(
    private http: GenericHttpService
  ) {
    this.apiUrl = `${API_URL_MAP.messenger.base}${API_URL_MAP.messenger.threads}`;
  }

  // Messages
  public sendDirectMessage(messageBody: any) {
    const url = `${API_URL_MAP.messenger.base}${API_URL_MAP.messenger.messages}${API_URL_MAP.messenger.direct}`;
    return this.http.post(API_URL_KEY.messenger, url, messageBody);
  }

  public getThreadMessages(thread: string, page: number = 1): Observable<any> {
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = 25;
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.messages}`;
    return this.http.get(API_URL_KEY.messenger, url, searchParams);
  }

  public getUnreadMessagesTotal(): Observable<any> {
    const url =
      `${API_URL_MAP.messenger.base}${API_URL_MAP.messenger.me}${API_URL_MAP.messenger.messages}${API_URL_MAP.messenger.unreadTotal}`;
    return this.http.get(API_URL_KEY.messenger, url);
  }

  public sendThreadMessage(thread: string, messageBody: any): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.messages}`;
    return this.http.post(API_URL_KEY.messenger, url, messageBody);
  }

  public updateThreadMessage(thread: string, messageId: string, messageBody: any): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.messages}/${messageId}`;
    return this.http.put(API_URL_KEY.messenger, url, messageBody);
  }

  public deleteThreadMessage(thread: string, messageId: string): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.messages}/${messageId}`;
    return this.http.del(API_URL_KEY.messenger, url);
  }

  // Participants
  public getThreadParticipants(thread: string): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.participants}`;
    return this.http.get(API_URL_KEY.messenger, url);
  }

  public createParticipant(thread: string, participantsData: any): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.participants}`;
    return this.http.post(API_URL_KEY.messenger, url, participantsData);
  }

  public deleteParticipant(thread: string, participantId: string): Observable<any> {
    const url = `${this.apiUrl}/${thread}${API_URL_MAP.messenger.participants}/${participantId}`;
    return this.http.del(API_URL_KEY.messenger, url);
  }

  public leaveThread(thread: string): Observable<any> {
    const baseUrl = `${API_URL_MAP.messenger.base}${API_URL_MAP.users.me}${API_URL_MAP.messenger.threads}`;
    const url = `${baseUrl}/${thread}${API_URL_MAP.messenger.leave}`;
    return this.http.del(API_URL_KEY.messenger, url);
  }


  // Threads
  public getUserThreads(page: number = 1): Observable<any> {
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = 25;
    const url = `${this.apiUrl}`;
    return this.http.get(API_URL_KEY.messenger, url, searchParams);
  }

  public getThreadById(thread: string): Observable<any> {
    const url = `${this.apiUrl}/${thread}`;
    return this.http.get(API_URL_KEY.messenger, url);
  }

  public createNewThread(threadData: any): Observable<any> {
    const url = `${this.apiUrl}`;
    return this.http.post(API_URL_KEY.messenger, url, threadData);
  }

  public updateThread(thread: string, threadData: any): Observable<any> {
    const url = `${this.apiUrl}/${thread}`;
    return this.http.put(API_URL_KEY.messenger, url, threadData);
  }

  public deleteThread(thread: string): Observable<any> {
    const url = `${this.apiUrl}/${thread}`;
    return this.http.del(API_URL_KEY.messenger, url);
  }

}
