import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthRoutingModule } from './auth-routing.module';
import { JwtInterceptor } from './shared/services/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/services/interceptors/error.interceptor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthSharedModule } from './shared/auth-shared.module';
import { SharedModule } from '@shared/shared.module';
import { VerificationModule } from './verification/verification.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, AuthRoutingModule, AuthSharedModule, SharedModule, FormsModule, ReactiveFormsModule, VerificationModule],
  exports: [

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class AuthModule { }
