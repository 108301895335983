<div class="chat-container">
  <button mat-icon-button
    aria-label="Unread messages number"
    i18n-aria-label="Unread messages number icon@@label.unreadMessagesNumber"
    [routerLink]="['/chat']"
    (click)="totalUnreadMessages = 0"
    tabindex="0">
    <mat-icon class="text-white pointer" aria-hidden="true">chat</mat-icon>
    <span *ngIf="totalUnreadMessages"
          class="badge-label text-center badge-label-accent round pointer"
          tabindex="0">{{totalUnreadMessages}}</span>
  </button>
</div>
