import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { UserInfo } from '../../models/user.model';
import { shareReplay, tap } from 'rxjs/operators';
import { Store } from '@store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private userRes$: Observable<any>;

  constructor(
    private http: GenericHttpService,
    private store: Store,
  ) { }

  public getUserInfo(refreshCache: boolean = false) {
    if (!this.userRes$ || refreshCache) {
      this.userRes$ = this.getUserData().pipe(
        shareReplay()
      );
    }

    return this.userRes$;
  }

  getUsers(page: number = 1, q?: string) {
    const queryParams: any = {};
    queryParams.page = page;
    if (q) {
      queryParams.q = q;
    }
    const url = `${API_URL_MAP.users.base}`;
    return this.http.get(API_URL_KEY.group, url, queryParams);
  }

  getUserData() {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}`;
    return this.http.get(API_URL_KEY.user, url).pipe(
      tap(res => {
        this.store.set('userInfo', res.data);
      })
    );
  }

  loginAsUser(userId: string) {
    const url = `${API_URL_MAP.users.base}/${userId}/login`;
    return this.http.post(API_URL_KEY.identity, url, {});
  }

  getUserProfileById(userId) {
    const url = `${API_URL_MAP.users.base}/${userId}`;
    return this.http.get(API_URL_KEY.user, url);
  }

  getUserEvents() {
    // https://friskus-event-dev.herokuapp.com/api/v1/events
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.events.base}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public getUserAttendedOccurrencesList(startDate: string, endDate: string, params?: any) {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${API_URL_MAP.events.occurrences}` +
      `${usersURL.organizations.joined}${usersURL.events.collection}`;
    const searchParams = params || {};
    searchParams.start_at = startDate;
    searchParams.end_at = endDate;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getUserOpenBookedEvents(page: number = 1, perPage: number = 15) {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.events.slots}`;
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = perPage;

    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  getUserPreviousEvents() {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.events.base}${usersURL.events.attend}?past=true`;
    return this.http.get(API_URL_KEY.event, url);
  }

  getUserAttendedEvents() {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.events.base}${usersURL.events.attend}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  getUserAttendedOccurenses(page: number = 1, perPage: number = 15) {
    const usersURL = API_URL_MAP.users;
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = perPage;
    const url = `${usersURL.base}${usersURL.me}${API_URL_MAP.events.occurrences}${usersURL.organizations.joined}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  getUserPreviousOccurenses(page: number = 1, perPage: number = 15) {
    const usersURL = API_URL_MAP.users;
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = perPage;
    searchParams.past = true;
    const url = `${usersURL.base}${usersURL.me}${API_URL_MAP.events.occurrences}${usersURL.organizations.joined}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  getUserOrganizations(page: number = 1, permissions?: string, query: any = {}) {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.organizations.base}${usersURL.organizations.joined}`;
    const searchParams: any = {page, ...query};
    searchParams.perPage = 30;
    if (permissions) {
      searchParams.permissions = permissions;
    }
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  getUserOwnOrganizations(page: number = 1, query: any = {}) {
    const usersURL = API_URL_MAP.users;
    const url = `${usersURL.base}${usersURL.me}${usersURL.organizations.base}`;
    const searchParams: any = {page, ...query};
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  updateUserInfo(userInfo: UserInfo) {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}`;
    return this.http.put(API_URL_KEY.user, url, userInfo);
  }

  createUser(userData) {
    const url = `${API_URL_MAP.users.base}`;
    return this.http.post(API_URL_KEY.event, url, userData);
  }

  getUserById(id) {
    const url = `${API_URL_MAP.users.base}/${id}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  updateUserById(id, userData) {
    const url = `${API_URL_MAP.users.base}/${id}`;
    return this.http.put(API_URL_KEY.event, url, userData);
  }

  deleteUserById(id) {
    const url = `${API_URL_MAP.users.base}/${id}`;
    return this.http.del(API_URL_KEY.event, url);
  }

  getUserCV() {
    const url = `${API_URL_MAP.users.me}${API_URL_MAP.users.cv.base}${API_URL_MAP.users.cv.download}`;
    return this.http.getFile(API_URL_KEY.user, url);
  }

  getUserJoinedCount() {
    const usersURL = API_URL_MAP.users;
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}${usersURL.events.base}/joined_count`;
    return this.http.get(API_URL_KEY.group, url);
  }

  getUserJoinedCountById(userId: string) {
    const usersURL = API_URL_MAP.users;
    const url = `${API_URL_MAP.users.base}/${userId}${usersURL.events.base}/joined_count`;
    return this.http.get(API_URL_KEY.group, url);
  }

  updateUserNotifications(notifications) {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}${API_URL_MAP.notifications.base}`;
    return this.http.post(API_URL_KEY.user, url, { notifications });
  }

  searchUserByName(query: string) {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.search}`;
    const searchParams: any = {};
    searchParams.q = query;
    return this.http.get(API_URL_KEY.user, url, searchParams);
  }

  deleteAccount() {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}`;
    return this.http.del(API_URL_KEY.user, url);
  }

  // Notifications

  getGeneralUserNotificationSettings() {
    const url = `${API_URL_MAP.notifications.base}${API_URL_MAP.notifications.settings}`;
    return this.http.get(API_URL_KEY.user, url);
  }

  updateGeneralUserNotificationSettings(notificationData: any) {
    const url = `${API_URL_MAP.notifications.base}${API_URL_MAP.notifications.settings}`;
    return this.http.put(API_URL_KEY.user, url, notificationData);
  }

  getUserNotificationSettingsByOrganization(organizationId: string) {
    const url = `${API_URL_MAP.notifications.base}/${organizationId}${API_URL_MAP.notifications.settings}`;
    return this.http.get(API_URL_KEY.user, url);
  }

  updateUserNotificationSettingsByOrganization(organizationId: string, notificationData: any) {
    const url = `${API_URL_MAP.notifications.base}/${organizationId}${API_URL_MAP.notifications.settings}`;
    return this.http.put(API_URL_KEY.user, url, notificationData);
  }

  getAcceptedTerms(type: string, municipality?: string, version?: number ) {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}/terms/${type}`;
    const queryParams: any = {};
    if (municipality) {
      queryParams.municipality = municipality;
    }

    if (version) {
      queryParams.version = version;
    }

    return this.http.get(API_URL_KEY.user, url);
  }

  acceptTerms(termsData) {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}/terms`;
    return this.http.post(API_URL_KEY.user, url, termsData);
  }
}
