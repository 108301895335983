<ng-container *ngIf="!(isMobile | async)?.matches">
  <div class="calendar-custom-header d-flex justify-content-between pb-4">
    <div class="left" *ngIf="!isTimePlan">
      <mat-button-toggle-group (change)="eventsChange($event)"
        class="d-flex"
        *ngIf="user && !organizationId">
        <mat-button-toggle [value]="true"
          [checked]="allEventsMode"
          class="flex-grow-1"
          i18n="All events buttons label@@button.allEvents">All events</mat-button-toggle>
        <mat-button-toggle [value]="false"
          [checked]="!allEventsMode"
          class="flex-grow-1"
          i18n="Time plan buttons label@@button.timePlan">Time plan</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="center d-flex align-items-center">
      <button mat-button (click)="calendarPrev()" class="calendar-button p-0" [attr.aria-label]="nextPreviousButtons.previous"><mat-icon>chevron_left</mat-icon></button>
      <h2 class="calendar-title m-0 px-4">{{calendarTitle}}</h2>
      <button mat-button (click)="calendarNext()" class="calendar-button p-0" [attr.aria-label]="nextPreviousButtons.next"><mat-icon>chevron_right</mat-icon></button>
    </div>

    <div class="right">
      <mat-button-toggle-group (change)="viewChange($event)" class="d-flex">
        <mat-button-toggle value="dayGridMonth"
          [checked]="true"
          class="flex-grow-1"
          i18n="Month buttons label@@button.month">Month
        </mat-button-toggle>
        <mat-button-toggle value="dayGridWeek"
          class="flex-grow-1"
          i18n="Week buttons label@@button.week">Week
        </mat-button-toggle>
        <mat-button-toggle value="timeGridDay"
          class="flex-grow-1"
          i18n="Day buttons label@@button.day">Day</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <full-calendar
    #calendar
    [options]="calendarOptions">
  </full-calendar>
</ng-container>

<ng-container *ngIf="(isMobile | async)?.matches">
  <friskus-mobile-calendar [filterParams]="filterParams"
    [organizationId]="organizationId"
    [isTimePlan]="isTimePlan"
    (loading)="loading.emit($event)"></friskus-mobile-calendar>
</ng-container>

<i [matMenuTriggerFor]="calendarPopup" #popupBtn style="display: none;"></i>

<mat-menu #calendarPopup="matMenu" (close)="onPopupClosed()" yPosition="above" xPosition="before">
  <friskus-event-card *ngIf="clickedEvent" [isOnWaitingList]="isOnWaitingList" [event]="clickedEvent" [routerLink]="['/events', clickedEvent.id]"></friskus-event-card>
</mat-menu>
