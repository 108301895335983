<mat-chip
  (click)="onClick()"
  (keypress)="$event.key === 'Enter' && onClick()"
  [ngClass]="{'selected': selected}"
  [selectable]="false"
  tabIndex="0"
  #chip>
  <span>{{ label }}</span>
  <mat-icon *ngIf="selected && showClearIcon"
    tabindex="0"
    aria-label="Remove filter"
    (click)="onClear($event)"
    (keypress)="onClear($event)"
    matChipRemove>clear</mat-icon>
  <mat-icon *ngIf="(!selected || !showClearIcon) && showDropdownIcon"
    aria-hidden="true"
    >arrow_drop_down</mat-icon>
</mat-chip>
