import { UserInfo } from '@shared/models/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { INPUT_MASK } from '@config/validation-patterns.config';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';
import { SnackbarService } from '@services/snackbar.service';
import { SNACKBAR_TYPE } from '@shared/components/ui-components/snackbar/snackbar.model';
import { Subscription } from 'rxjs';
import { Store } from '@store';
import { Utils } from '@shared/utils/index';

@Component({
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public form = this.fb.group({
    code: ['', Validators.required],
  });
  public user: any;
  public verificationCodeMask = INPUT_MASK.verificationCodeMask;
  public verificationCodeResended: boolean;
  public codeRequested: boolean;
  private verificationId: string;
  private usernameSub: Subscription;
  public usernameObj: any;
  public userInfo: UserInfo;
  public validationError: string;
  public tooManyAttempts: boolean;
  public isRegistration: boolean;
  public isSecondNameVerify: boolean;
  private returnUrl: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackbarService,
    private store: Store
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.verificationId = params.verificationId;
      this.isRegistration = params.isRegistration;
      this.tooManyAttempts = params.tooManyAttempts;
      this.isSecondNameVerify = params.isSecondNameVerify;
      this.returnUrl = params.returnUrl || '/';
    });

    this.usernameSub = this.store.select('username').subscribe(res => {
      const usernameObj: any = res;
      if (usernameObj) {
        this.usernameObj = usernameObj;
      } else {
        this.router.navigate(['./auth' ,'verify', 'verify-username']);
      }
    });
  }

  ngOnDestroy() {
    if (this.usernameSub) {
      this.usernameSub.unsubscribe();
    }
  }

  onSubmit() {
    this.isLoading = true;
    if (this.form.invalid) {
      this.isLoading = false;
      return;
    }
    this.validationError = null;
    const verificationCode = this.code.value.replace('-', '');

    this.authService.verifyCode(this.verificationId, verificationCode)
      .subscribe(_ => {
        this.isLoading = false;
        if (this.isRegistration) {
          this.router.navigate(['/auth/register'], {queryParams: {
            verificationId: this.verificationId,
            returnUrl: this.returnUrl
          }});
        } else {
          if (this.isSecondNameVerify) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate(['./profile', 'edit']);
            this.snackBarService.notificationSuccess({ type: SNACKBAR_TYPE.DATA_UPDATED });
          }
        }
      },
      errRes => {
        this.isLoading = false;
        if (errRes.errors.phone) {
          this.validationError = errRes.errors.phone;
        }

        if (errRes.errors.email) {
          this.validationError = errRes.errors.email;
        }

        this.snackBarService.commonNotificationFail(this.validationError);
        Utils.setValidationErrors(this.form, errRes.errors);
      });
  }

  public navigateToUsernameChange() {
    this.router.navigate(['/auth', 'verify', 'verify-username'], {
      queryParams: {verificationId: this.verificationId},
      queryParamsHandling: 'merge'
    });
  }


  get code() {
    return this.form.get('code');
  }

}
