import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'friskus-image-edit-controls',
  templateUrl: './image-edit-controls.component.html',
  styleUrls: ['./image-edit-controls.component.scss']
})
export class ImageEditControlsComponent implements OnInit {
  @Input() url: string;
  @Output() updated: EventEmitter<string> = new EventEmitter();
  public controlsForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.controlsForm = this.initControlsForm(this.url);
    this.controlsForm.valueChanges.subscribe(value => {
      if (!value.resize && !value.crop && this.controlsForm.controls.setColor.enabled) {
        this.controlsForm.controls.setColor.disable({ emitEvent: false});
        this.controlsForm.controls.color.disable({ emitEvent: false});
      } else {
        if (this.controlsForm.controls.setColor.disabled) {
          this.controlsForm.controls.setColor.enable({ emitEvent: false});
          this.controlsForm.controls.color.enable({ emitEvent: false});
        }
      }
    });
  }

  public controlChange(event: MatCheckboxChange, type: string) {
    const cropConfig = type === 'crop' ? '-/crop/1100x300/center/' : '-/scale_crop/1100x300/smart/';
    if (event.checked) {
      this.updated.emit(`${this.url}${cropConfig}`);
    } else {
      this.updated.emit(this.url.replace(cropConfig, ''));
    }
  }

  public colorChanged(event) {
    if (this.controlsForm.controls.setColor.value) {
      this.setFillChange({checked: true, source: null}, event.target.value);
    }
  }

  public resizeChange(event: MatCheckboxChange) {
    const cropConfig = this.url.includes('crop/1100x300') ? '-/crop/1100x300/center/' : '';
    const defaultResizeConfig = '-/resize/x300/';

    // Resize should be before crop in uploadcare url
    if (event.checked) {
      if (cropConfig) {
        this.updated.emit(this.url.replace(cropConfig, `${defaultResizeConfig}${cropConfig}`));
      } else {
        this.updated.emit(`${this.url}${defaultResizeConfig}`);
      }
    } else {
      this.updated.emit(this.url.replace(defaultResizeConfig, ''));
    }
  }

  public setFillChange(event: MatCheckboxChange, colorValue: string) {
    const cropConfig = this.url.includes('crop/1100x300') ? '-/crop/1100x300/center/' : '';
    const colorConfig = this.url.includes('/setfill/') ? /(-\/setfill\/)(.{6})\// : '';
    const fillConfig = `-/setfill/${colorValue.replace('#', '')}/`;

    // Setfill should be before crop in uploadcare url
    if (event.checked) {
      if (cropConfig && !colorConfig) {
        this.updated.emit(this.url.replace(cropConfig, `${fillConfig}${cropConfig}`));
      }
      if (colorConfig && !cropConfig) {
        this.updated.emit(`${this.url}${fillConfig}`);
      }

      if (colorConfig && cropConfig) {
        this.url = this.url.replace(/(-\/setfill\/)(.{6})\//, '');
        this.updated.emit(this.url.replace(cropConfig, `${fillConfig}${cropConfig}`));
      }
    } else {
      this.updated.emit(this.url.replace(/(-\/setfill\/)(.{6})\//, ''));
    }
  }

  private initControlsForm(imageUrl) {
    const fillUrll = imageUrl.match(/-\/setfill\/(.{6})/);
    const color = fillUrll ? `#${fillUrll[1]}` : '#6E37CB';

    return this.fb.group({
      smartCrop: [imageUrl.includes('scale_crop')],
      resize: [imageUrl.includes('resize')],
      setColor: [imageUrl.includes('setfill')],
      color: [color],
      crop: [imageUrl.includes('crop/1100x300')]
    });
  }

}
