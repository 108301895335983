import { User } from './user.model';

export interface Certificate {
  id: string;
  user: User;
  category: CertificateCategory;
  name: string;
  description: string;
  thumbnail_url: string;
  documents: CertificateDocument[];
  confirmable: boolean;
  organizations?: any[];
  type: CERTIFICATE_TYPES;
  created_at: string;
  updated_at: string;
}

export interface CertificateCategory {
  id: string;
  name: string;
  slug: CertificateCategorySlug;
}
export interface CertificateDocument {
  id: string;
  name: string;
  url: string;
}

export enum CertificateCategorySlug {
  team = 'team',
  certificate = 'certificate',
  membership = 'membership'
}
export enum CERTIFICATE_TYPES {
  FRISKUS = 'FRISKUS',
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER'
}

export interface Stamp {
  id: string;
  certificate: Certificate;
  status: StampStatus;
  is_accepted: boolean;
  start_at: string;
  end_at: string;
}

export enum StampStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  REQUESTED = 'requested',
  NOT_ACCEPTED = 'not_accepted',
  NOT_ACTIVE_YET = 'not_active_yet',
  EXPIRED = 'expired'
}

