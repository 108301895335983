import { Injectable } from '@angular/core';
import { GenericHttpService } from '../generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '../../../config/api-url.config';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  public updateNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private http: GenericHttpService,
  ) { }

  public getNotifications(page?) {
    const url = `${API_URL_MAP.notifications.base}`;
    return this.http.get(API_URL_KEY.notifications, url, { page });
  }

  public markAllAsRead() {
    const url = `${API_URL_MAP.notifications.base}${API_URL_MAP.notifications.mark}${API_URL_MAP.notifications.read}`;
    return this.http.post(API_URL_KEY.notifications, url);
  }

  public markAsRead(notificationId: string) {
    const url = `${API_URL_MAP.notifications.base}/${notificationId}${API_URL_MAP.notifications.mark}${API_URL_MAP.notifications.read}`;
    return this.http.post(API_URL_KEY.notifications, url);
  }
}
