import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SolaMunicipality: UserMunicipality = {
  name: 'sola',
  slogan: {value: '', id: 'banner.sola', meaning: 'bannerSlogan', description: 'slogan for sola'},
  data: {},
  bannerURL: 'https://ucarecdn.com/a1f5aa5d-5afb-44bf-8ea5-2fe9d76ff944/pexelskampusproductionsola.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Fritidskort',
  infoSlots: [
   {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskort',
      minAge: 6,
      maxAge: 16,
      amount: 2000,
      url: 'https://www.sola.kommune.no/solafritid/fritidskort-og-fritidskasse/',
    },
]
}
