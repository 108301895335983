<div class="mt-2">
  <friskus-dropdown-chip
    (clickChip)="toggleOpen()"
    (clear)="onClear()"
    [label]="label"
    [selected]="sortDirection !== 'none'"
    [showDropdownIcon]="true"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  ></friskus-dropdown-chip>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayMinWidth="150px"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayLockPosition]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (backdropClick)="toggleOpen()"
  (overlayKeydown)="$event.key === 'Escape' && toggleOpen()"
>
  <div
    class="position-lg-relative bg-white pt-4 border-rounded w-100 d-flex flex-column"
    cdkTrapFocus
    cdkTrapFocusAutoCapture
  >
    <div class="px-4 mb-3">
      <h3 class="font-size-lg m-0 pr-5" i18n="Sort@@label.sort">Sort</h3>
      <button
        class="position-absolute right-2 top-2 w-6 h-6 mt-1"
        mat-icon-button
        (click)="toggleOpen()"
      >
        <mat-icon class="color-gray-700">close</mat-icon>
      </button>
    </div>
    <menu class="p-0 pt-2 pb-3 m-0 border-top">
      <li
        class="pl-3 pr-4 d-flex align-items-center"
        tabindex="0"
        (click)="onClick('asc')"
        (keypress)="$event.key === 'Enter' && onClick('asc')"
        [ngClass]="{
          'color-primary': sortDirection === 'asc',
          'bg-primary-30': sortDirection === 'asc'
        }"
      >
        <span
          class="sort-icon"
          aria-hidden
        >
          <mat-icon class="rotate-180">sort</mat-icon>
        </span>
        &nbsp;
        <span class="pl-1" i18n="A-Z@@option.alphabeticAsc">A-Z</span>
      </li>

      <li
        class="pl-3 pr-4 d-flex align-items-center"
        tabindex="0"
        (click)="onClick('desc')"
        (keypress)="$event.key === 'Enter' && onClick('desc')"
        [ngClass]="{
          'color-primary': sortDirection === 'desc',
          'bg-primary-30': sortDirection === 'desc'
        }"
      >
        <span
          class="sort-icon"
          aria-hidden
        >
          <mat-icon>sort</mat-icon>
        </span>
        &nbsp;
        <span class="pl-1" i18n="Z-A@@option.alphabeticDesc">Z-A</span>
      </li>
    </menu>
  </div>
</ng-template>
