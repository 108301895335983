
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '@services/remote-api/users.service';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData, DIALOG_TYPE } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.model';
import { MODAL_SIZE } from '@shared/constants/modals.const';
import { UserInfo } from '@shared/models/user.model';
import { Store } from '@store';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesTermsService {
  private subject: BehaviorSubject<boolean>;

  constructor(
    private store: Store,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {
    this.subject = new BehaviorSubject(false);
  }

  public get uploadTermsAccepted() {
    return this.subject;
  }

  public isTermsAccepted() {
    this.store.select('userInfo').pipe(take(1)).subscribe((userInfo?: UserInfo) => {
      if (!userInfo) return;

      if (userInfo.terms_accepted) {
        this.subject.next(true);
        return true;
      } else {
        const data: ConfirmationDialogData = {
          type: DIALOG_TYPE.CONDITIONS_ACCEPT
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data,
          maxWidth: MODAL_SIZE.small,
          width: MODAL_SIZE.small,
          panelClass: 'full-width-dialog',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
          this.subject.next(isConfirmed);
          if (isConfirmed) {
            userInfo.terms_accepted = true;
            this.usersService.updateUserInfo(userInfo).subscribe(_ => {
              this.store.set('userInfo', userInfo);
            });
          }
          return isConfirmed;
        });
      }
    });
  }
}
