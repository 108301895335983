import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_TYPE, SnackBarData } from './snackbar.model';

@Component({
  selector: 'friskus-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  public isOpen: boolean;
  public snackBarType = SNACKBAR_TYPE;
  public snackBarData: SnackBarData;
  @Output() public dismissClick = new EventEmitter<any>();
  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {
    if (data) {
      this.snackBarData = Object.assign({}, this.snackBarData, data);
    }
  }

  ngOnInit() {
  }

  dismiss() {
    this.snackBar.dismiss();
  }

}
