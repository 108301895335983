import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import * as common from '@bugsnag/js';

import { AuthService } from '../auth/auth.service';
import { SnackbarService } from '../../../../../shared/services/snackbar.service';
import { Router } from '@angular/router';
import { SNACKBAR_TYPE } from '@shared/components/ui-components/snackbar/snackbar.model';
import { isPlatformBrowser } from '@angular/common';
import { bugsnagClient } from '@config/bugsnag.config';
import { Store } from '@store';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private announcement: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbarService: SnackbarService,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: object) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        const announcement = event.headers.get('announcement');
        if (announcement) {
          const announcementHeader = JSON.parse(announcement);
          if (this.announcement !== announcementHeader) {
            this.announcement = announcementHeader;
            this.store.set('announcement', announcementHeader);
          }
        }
      }),
      catchError(err => {
        if (err.status === 401) {
          if (isPlatformBrowser(this.platformId)) {
            this.authService.logout();
          }
          err.error.isUnauthorized = true;
          return throwError(err.error);
        } else if (err.status === 403) {
          err.error.isForbidden = true;
          this.snackbarService.notificationFail(
            {
              type: SNACKBAR_TYPE.CUSTOM,
              message: err.error.message,
              header: $localize `:Attention error header|@@error.attentionHeader:Attention`
            }
          );
          return throwError(err.error);
        } else if (err.status === 404) {
          this.router.navigate(['/404'], {
            state: { message: err.error.message}
          });
        } else if (err.status === 422) {
          return throwError(this.mapErrors(err.error));
        } else if (err.status === 500) {
          const metaData: common.NotifiableError = {
            errorClass: '500 error',
            errorMessage: err
          };
          bugsnagClient.notify(new Error('500'), (event) => {
            event.addMetadata('Error message', {
              request,
              ...metaData
            });
          });
          this.router.navigate(['/500'], {
            state: { message: err.error.message}
          });
        }
        return throwError(err);
      })
    );
  }

  private mapErrors(error) {
    const serverErrors = error.errors || {};
    const errors = {};

    Object.keys(serverErrors).forEach(prop => {
      if (serverErrors[prop]) {
        if (Array.isArray(serverErrors[prop])) {
          errors[prop] = serverErrors[prop].shift();
        } else if (typeof serverErrors[prop] === 'object') {
          // this.mapErrors(serverErrors[prop]);
          errors[prop] = {};
          Object.keys(serverErrors[prop]).forEach(insideProp => {
            errors[prop][insideProp] = serverErrors[prop][insideProp].shift();
          });
        }
      }
    });

    return {
      message: error.message,
      errors
    };
  }
}
