import { Injectable } from "@angular/core";
import { GLOBAL_FILTER_GROUP } from "@features/filters/services/filter.service";
import { MunicipalitiesService } from "@services/remote-api/municipalities.service";
import {
  FilterConfig,
  FilterType,
  MultiSelectFilterConfig,
  MultiSelectFilterOption,
} from "@features/filters/services/filters.types";
import { take } from "rxjs/operators";

export interface IGlobalFilterQuery {
  municipalities: string[];
}

@Injectable({
  providedIn: "root",
})
export class GlobalFilterConfigs {
  public filterGroupId = GLOBAL_FILTER_GROUP;
  constructor(
    private municipalitiesService: MunicipalitiesService
  ) {}

  private getMunicipalityOptions = async () => {
    const municipalities = await this.municipalitiesService.municipalities
      .pipe(take(1))
      .toPromise();

    const subdomainMunicipalityName =
      this.municipalitiesService.getUserMunicipality().name;

    const activeMunicipalityIdx = municipalities.data.findIndex(
      (x) => x.key === subdomainMunicipalityName
    );

    const municipality = municipalities.data[activeMunicipalityIdx];

    return municipalities.data.map(
      (x) =>
        ({
          label: x.name,
          value: x.id,
          lock: x.id === municipality?.id,
          subOptions: x.districts?.map((y) => ({
            value: y.id,
            label: y.name,
          })),
        } as MultiSelectFilterOption<string>)
    );
  };

  public getMunicipalityFilterConfig(): MultiSelectFilterConfig<string> {
    return {
      filterGroupId: GLOBAL_FILTER_GROUP,
      filterId: "municipalities",
      type: FilterType.MultiSelect,
      queryParamName: "municipalities",
      subOptionsQueryParamName: "districts",
      label: $localize `:@@label.municipality:Municipality`,
      optionFactory: this.getMunicipalityOptions,
    };
  }

  public getGlobalFilterConfigs(): FilterConfig[] {
    return [this.getMunicipalityFilterConfig()];
  }
}
