import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const KvinesdalMunicipality: UserMunicipality = {
  name: 'kvinesdal',
  slogan: {value: '', id: 'banner.kvinesdal', meaning: 'bannerSlogan', description: 'slogan for kvinesdal'},
  data: {
    headerDescription: 'Dette er et tilbud til deg som er mellom 6 til 18 år. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering. <br><br>' +
      ' Følg veiledningen under. For mer informasjon se: <a  class="link text-white text-decoration-underline"' +
      'href="https://www.kvinesdal.kommune.no/fritidskort.545173.no.html"' +
      '>egen informasjonsside</a>.<br><br>' +
      ' Ved spørsmål ta kontakt på <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:leah.henriette.rafoss@kvinesdal.kommune.no">leah.henriette.rafoss@kvinesdal.kommune.no</a>',
    amount: 1500,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/fcc358b8-452f-4af9-80e7-44ef16f99fee/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskortet',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 18,
      amount: 3000,
      url: 'https://www.facebook.com/people/Fritidskortet-for-barn-og-unge-i-Kvinesdal/100067604296821/',
    }
  ]
}
