import { Injectable } from '@angular/core';
import { TreeItemNode } from '@shared/components/ui-components/tree-checklist/tree-item.model';
import { UntypedFormControl } from '@angular/forms';
import { TreeChecklistComponent } from '@shared/components/ui-components/tree-checklist/tree-checklist.component';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EventsFilterBarService {
  public ageGropusTypes = [
    { name: $localize `:Children|@@option.children:Children`, id: 'children' },
    { name: $localize `:Teenager|@@option.teenager:Teenager`, id: 'teenager' },
    { name: $localize `:Family|@@option.family:Family`, id: 'family' },
    { name: $localize `:Adult|@@option.adult:Adult`, id: 'senior' },
    { name: $localize `:Pensioner|@@option.pensioner:Pensioner`, id: 'pensioner' }
  ];

  public viewTypes = [
    { name: $localize `:List|@@option.list:List`, id: 'list' },
    { name: $localize `:Calendar|@@option.calendar:Calendar`, id: 'calendar' },
    { name: $localize `:Map|@@option.map:Map`, id: 'map' },
  ];

  constructor( ) { }

  public buildFileTree(dataList: any[], currentMunicipalityId?: string): TreeItemNode[] {
    return dataList.map(item => {
      const value = {
        id: item.id,
        name: item.name,
        disabled: (item.id === currentMunicipalityId)
      };
      const node = new TreeItemNode();
      node.item = value;
      if (item.districts && item.districts.length) {
        node.children = this.buildFileTree(item.districts);
      }
      return node;
    });
  }

  public setMunicipalityInputValue(
    selectedFilters,
    municipalities,
    treeChecklistData: any,
    tree: TreeChecklistComponent,
    municipalityMobileControl: UntypedFormControl,
    municipalityControl: UntypedFormControl
  ) {
    if (Array.isArray(selectedFilters.municipalities)) {
      const selectedMunicipalities = [];
      selectedFilters.municipalities.forEach(element => {
        selectedMunicipalities.push(municipalities.find(municipality => municipality.id === element));
        if (tree) {
          tree.selectItem(treeChecklistData.filter(node => node.item.id === element));
        }
      });
      if (municipalityMobileControl && selectedMunicipalities.length) {
        this.setInputValue({ level0: selectedMunicipalities }, municipalityMobileControl);
      }
    } else {
      const selectedMunicipalities = [];
      if (tree) {
        tree.selectItem(treeChecklistData.filter(node => node.item.id === selectedFilters.municipalities));
      } else if (municipalityMobileControl) {
        selectedMunicipalities.push(municipalities.find(municipality => municipality.id === selectedFilters.municipalities));
        this.setInputValue({ level0: selectedMunicipalities }, municipalityMobileControl);
      }
    }
  }

  public setDateInputValue(selectedFilters, rangePickerControl: UntypedFormControl, rangepickerMobileControl: UntypedFormControl, isCalendarPage) {
    if (selectedFilters.start_at || selectedFilters.end_at && !isCalendarPage) {
      const startDate = selectedFilters.start_at ? moment(selectedFilters.start_at).format('DD/MM/YYYY') : '...';
      const endDate = moment(selectedFilters.end_at).format('DD/MM/YYYY');
      const endDateString = selectedFilters.end_at && (startDate !== endDate) ? ` - ${endDate}` : '';
      rangePickerControl.patchValue(`${startDate}${endDateString}`);
      rangepickerMobileControl.patchValue({
        begin: moment(selectedFilters.start_at).toISOString(),
        end: moment(selectedFilters.end_at).toISOString()
      });
    }
  }

  public setTypeInputValue(selectedFilters, types, typeControl: UntypedFormControl, typeMobileControl: UntypedFormControl) {
    if (Array.isArray(selectedFilters.type)) {
      typeControl.patchValue(selectedFilters.type);
      if (typeMobileControl) {
        this.setInputValue({ level0: [types.find(item => item.id === selectedFilters.type)] }, typeMobileControl);
      }
    } else {
      const value = [];
      value.push(types.find(item => item.id === selectedFilters.type));
      if (typeMobileControl) {
        this.setInputValue(
          { level0: value },
          typeMobileControl
        );
      }
      typeControl.patchValue([selectedFilters.type]);
    }
  }

  public setCategoryInputValue(selectedFilters, categoriesData, categoryControl: UntypedFormControl, categoryControlMobile: UntypedFormControl) {
    if (Array.isArray(selectedFilters.categories)) {
      const categories = [];
      selectedFilters.categories.forEach(element => {
        categories.push(categoriesData.find(item => item.id === element));
      });
      if (categoryControlMobile && categories.length) {
        this.setInputValue({ level0: categories }, categoryControlMobile);
      }
      categoryControl.patchValue(selectedFilters.categories);
    } else {
      if (categoryControlMobile) {
        this.setInputValue(
          { level0: [categoriesData.find(item => item.id === selectedFilters.categories)] },
          categoryControlMobile
        );
      }
      const newValue = categoriesData.find(item => item.id === selectedFilters.categories);
      if (newValue) {
        categoryControl.patchValue([newValue]);
      }
    }
  }

  public setAgeGroupInputValue(selectedFilters, ageGroupData, ageGroupControl: UntypedFormControl, ageGroupControlMobile: UntypedFormControl) {
    if (Array.isArray(selectedFilters.age_group)) {
      const ageGroups = [];
      selectedFilters.age_group.forEach(element => {
        ageGroups.push(ageGroupData.find(item => item.id === element));
      });
      if (ageGroupControlMobile && ageGroups.length) {
        this.setInputValue({ level0: ageGroups }, ageGroupControlMobile);
      }
      ageGroupControl.patchValue(selectedFilters.age_group);
    } else {
      if (ageGroupControlMobile) {
        this.setInputValue(
          { level0: [ageGroupData.find(item => item.id === selectedFilters.age_group)] },
          ageGroupControlMobile
        );
      }
      ageGroupControl.patchValue([ageGroupData.find(item => item.id === selectedFilters.age_group)]);
    }
  }

  public setRadiusInputValue(selectedFilters, radiusControl: UntypedFormControl, radiusControlMobile: UntypedFormControl) {
    const radius = selectedFilters['distance[radius]'] || 50;
    radiusControl.patchValue(radius);
    if (radiusControlMobile) {
      this.setInputValue(
        { level0: radius },
        radiusControlMobile
      );
    }
  }

  public setInputValue(selectedItems, control: UntypedFormControl) {
    if (selectedItems && selectedItems.level0[0]) {
      let inputValue = `${selectedItems.level0[0].name}`;
      if (selectedItems.level0.length >= 2) {
        inputValue += ` + ${selectedItems.level0.length - 1} ${
          selectedItems.level0.length === 2 ?
            $localize `:inputValue|Label for multiple input items == 1|@@input.other:other`
            :
            $localize `:inputValue|Label for multiple input items > 1|@@input.others:others`
          }`;
      }
      control.setValue(inputValue);
    } else {
      control.setValue('');
    }
  }
}
