import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';


@Component({
  selector: 'friskus-blank-avatar',
  templateUrl: './blank-avatar.component.html',
  styleUrls: ['./blank-avatar.component.scss']
})
export class BlankAvatarComponent implements OnInit, OnChanges {
  @Input() data: string;
  @Input() isHeader?: string;
  @Input() size = 'large';
  public words: string[];
  public lettersToDisplay: string;
  constructor() { }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.data) {
      this.prepareLetters(simpleChanges.data.currentValue);
    }
  }

  ngOnInit() {
    this.prepareLetters(this.data);
  }

  public prepareLetters(data) {
    this.words = data.split(' ');
    this.lettersToDisplay = `${this.words.map(word => word[0] ? word[0].toUpperCase() : '')}`.replace(/,/g, '').slice(0, 2);
  }

}
