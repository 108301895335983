export interface UserMunicipality {
  name: string;
  slogan: any;
  data: any;
  bannerURL: string;
  bannerType: MunicipalityBannerType;
  hasLeisureCard: boolean;
  userCanClaimLeisureCard: boolean;
  canClaimInOrganization: boolean;
  hasSubsidiaryOrg: boolean;
  sunset: boolean;
  hasPayment:boolean;
  hasTickets:boolean;
  hasEquipment?:boolean;
  infoPageTitle?: string;
  infoSlots?: InfoSlots;
  customEventsBanner?: any;
  customOrganizationsBanner?: any;
}

export type InfoSlots = (
  InfoSlot
  | LinksInfoSlot | NamedInfoslot

  | TicketsInfoSlot

  | LeisureCardInfoSlot | LeisureCardAllInfoSlot
)[];

export interface InfoSlot {
  type: InfoType;
  muni?: string;
  title?: string;
  url?: string|string[];
}

export interface LinksInfoSlot extends InfoSlot {
  type: InfoType.VOLUNTEER_CENTERS
        | InfoType.VENUE
        | InfoType.FOR_YOUTHS
        | InfoType.FOR_SENIORS
        | InfoType.VOLUNTEER_WORK
        | InfoType.SUPPORT_CONTACT
        | InfoType.OTHER
  ;
  links?: InfoLink[];
}

export interface NamedInfoslot extends InfoSlot {
  type: InfoType.EQUIPMENT_RENTAL
  ;
  name?: string;
  links?: InfoLink[];
}

export interface TicketsInfoSlot extends InfoSlot {
  type: InfoType.FRISKUS_TICKETS | InfoType.TICKETS_CUSTOM;
  name?: string;
  url: string;
  minAge: number;
  maxAge: number;
}

export interface LeisureCardInfoSlot extends InfoSlot {
  type: InfoType.FRISKUS_LEISURE_CARD |  InfoType.LEISURECARD_CUSTOM,
  minAge: number;
  maxAge: number;
  url: string;
}

export interface LeisureCardAllInfoSlot extends InfoSlot {
  type: InfoType.FRISKUS_LEISURE_ALL,
  minAge: number;
  maxAge: number;
  amount: number;
  url: string;
}
export interface InfoLink {
  url: string|string[];
  title?: string;
}

export enum InfoType {
  FRISKUS_GET_STARTED = 'friskus-get-started',
  FRISKUS_LEISURE_CARD = 'friskus-leisure-card',
  FRISKUS_LEISURE_ALL = 'friskus-leisure-all',
  FRISKUS_TICKETS = 'friskus-tickets',
  FRISKUS_FRITIDSKONTO = 'friskus-fritidskonto',
  FRISKUS_NO_FRITIDSKONTO = 'has-no-leisure-card',
  HELP = 'help',
  LEISURECARD_CUSTOM = 'leisurecard-custom',
  VENUE = "venue",
  SUPPORT_CONTACT = 'support-contact',
  TICKETS_CUSTOM = "tickets-custom",
  ACTIVITY_GUIDE = 'aktivity-guide',
  EQUIPMENT_RENTAL = 'equipment-rental',
  VOLUNTEER_CENTRE = 'volunteer-centre',
  VOLUNTEER_CENTERS = 'volunteer-centers',
  FOR_YOUTHS = 'for-youths',
  FOR_SENIORS = 'for-seniors',
  VOLUNTEER_WORK = 'volunteer-work',
  FUNDING = 'funding',
  OTHER = "other"
}

export type MunicipalityBannerType = 'image' | 'video';
