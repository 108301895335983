import {UntypedFormGroup} from '@angular/forms';

export class Utils {
  public static setValidationErrors(form: UntypedFormGroup, validationErrors) {
    if (validationErrors) {
      Object.keys(validationErrors).forEach(prop => {
        const formControl = form.controls[prop] as any;  // form.get(prop) as any;
        if (formControl) {
          if (formControl.controls) {
            this.setValidationErrors(formControl, validationErrors[prop]);
          }

          formControl.markAsTouched();
          formControl.setErrors({
            serverError: validationErrors[prop]
          });
        }
      });
    }
  }

  public static GUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  public static imagesOnlyValidator(fileInfo: {isImage: boolean}) {
    if (fileInfo.isImage === false) {
      throw new Error('image'); // Tells uploadcare to show its "file must be an image"-error
    }
  }

  public static minImageDimensionsValidator(width: number, height: number) {
    return (fileInfo) => {
      let imageInfo = fileInfo.originalImageInfo;
      if (imageInfo !== null && (imageInfo.width < width || imageInfo.height < height)) {
          throw new Error("dimensions"); // Tells uploadcare to show its "file dimensions must be greater than"-error
      }
    };
  }
}
