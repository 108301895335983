import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'friskus-text-helper',
  templateUrl: './text-helper.component.html',
  styleUrls: ['./text-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextHelperComponent implements OnInit {
  @Input() text: string;
  public isCopied: boolean;
  constructor() { }

  ngOnInit() {
  }

  public copyToClipboard(): any {
    window.getSelection().removeAllRanges();
    const mark = document.createElement('mark');
    mark.textContent = this.text;
    document.body.appendChild(mark);
    const range = document.createRange();

    range.selectNode(mark);
    window.getSelection().addRange(range);

    try {
      const successful = document.execCommand('copy');
      this.isCopied = successful;
    } catch (err) {
      this.isCopied = false;
      console.log('Oops, unable to copy');
    }
    if (mark) {
      document.body.removeChild(mark);
    }

    window.getSelection().removeAllRanges();
  }

}
