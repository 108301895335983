import { Injectable } from "@angular/core";
import {
  Filter,
  FilterConfig,
  isMultiSelectFilter,
  isQuickFilter,
  isQuickFilterConfig,
  MultiSelectFilter,
  MultiSelectFilterValue,
} from "@features/filters/services/filters.types";
import { BehaviorSubject } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { calcFilterUniqueId, isFiltersEqual } from "./filter-compare";

export const DEFAULT_FILTER_ID = "default_filters_";
export const GLOBAL_FILTER_GROUP = "global_filters_";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  public Filter$: BehaviorSubject<Filter<any>[]> = new BehaviorSubject([]);

  public FiltersMatchingGroupId$(filterGroupId: string) {
    return this.Filter$.pipe(
      map((filters) =>
        filters.filter(
          (f) =>
            f.filterGroupId === filterGroupId ||
            f.filterGroupId === GLOBAL_FILTER_GROUP
        )
      ),
      debounceTime(150)
    );
  }

  private isUnchangedFilter(filter: Filter<any>) {
    return !!this.Filter$.value.find((x) => isFiltersEqual(x, filter));
  }

  public addFilter<T>(filter: Filter<T>) {
    let currentFilters = this.Filter$.value;
    if (this.isUnchangedFilter(filter)) return;

    // Remove existing version of the filter
    currentFilters = this.removeMultipleFilters(currentFilters, [filter]);
    // Remove conflicting filters
    currentFilters = this.removeConflictingFilters(currentFilters, filter);

    if (isQuickFilter(filter)) {
      this.Filter$.next([...currentFilters, ...filter.filterValue, filter]);
    } else {
      this.Filter$.next([...currentFilters, filter]);
    }
  }

  public removeFilter(filter: FilterConfig) {
    const newFilters = this.removeMultipleFilters(this.Filter$.value, [filter]);

    if (newFilters !== this.Filter$.value) {
      this.Filter$.next(newFilters);
    }
  }

  public clearFilters(filterGroupId?: string) {
    let targetedFilters = this.Filter$.value;
    let filtersToKeep = [];

    if (filterGroupId) {
      targetedFilters = targetedFilters.filter(
        (x) => x.filterGroupId === filterGroupId
      );
      filtersToKeep = this.Filter$.value.filter(
        (x) => x.filterGroupId !== filterGroupId
      );
    }

    const lockedFilters = this.Filter$.value.filter(
      (filter) =>
        isMultiSelectFilter(filter) &&
        filter.filterValue.value.find((x) => x.lock)
    ) as MultiSelectFilter<MultiSelectFilterValue<string>>[];

    const resetLockedFilters = lockedFilters.map((filter) => ({
      ...filter,
      filterValue: filter.filterValue.value.reduce(
        (acc, x, i) => {
          if (x.lock) {
            acc.value.push({ ...x, subOptions: undefined });
            acc.indices.push({ index: i });
          }
          return acc;
        },
        { value: [], indices: [] } as MultiSelectFilterValue<string>
      ),
    }));

    this.Filter$.next(filtersToKeep.concat(resetLockedFilters));
  }

  /**
   * Removes multiple filters from the list of current filters.
   * If the filters to be removed includes a quick filter, all related
   * quick filter items will also be removed.
   */
  private removeMultipleFilters(
    currentFilters: Filter<any>[],
    filters: FilterConfig[]
  ) {
    const filtersInclQuickFilterItems = filters.reduce((acc, cur) => {
      if (isQuickFilterConfig(cur)) acc.push(...cur.filters);
      acc.push(cur);
      return acc;
    }, [] as FilterConfig[]);

    const ids = filtersInclQuickFilterItems.map((f) => calcFilterUniqueId(f));
    return currentFilters.filter((f) => !ids.includes(calcFilterUniqueId(f)));
  }

  /**
   * Removes filters registered as conflicting from the list of currentFilters
   */
  private removeConflictingFilters(
    currentFilters: Filter<any>[],
    filter: Filter<any>
  ) {
    if (filter.conflictingFilterIds) {
      const conflictingFilters = currentFilters.filter(
        (f) =>
          filter.filterGroupId === f.filterGroupId &&
          filter.conflictingFilterIds.includes(f.filterId)
      );
      currentFilters = this.removeMultipleFilters(
        currentFilters,
        conflictingFilters
      );
    }

    return currentFilters;
  }
}
