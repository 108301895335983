import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Store } from '@store';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { UsersService } from '@services/remote-api/users.service';
import { UserInfo } from './../../../models/user.model';
import { InfiniteScrollPaginator } from '@shared/services/infinite-scroll-paginator.service';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  templateUrl: './switch-user-dialog.component.html',
  styleUrls: ['./switch-user-dialog.component.scss']
})
export class SwitchUserDialogComponent implements OnInit, OnDestroy {
  public users: any[] = [];
  @ViewChildren('usersSelect') usersSelect: MatSelect;
  public paginator: InfiniteScrollPaginator;
  public threadId: string;
  public form: UntypedFormGroup;
  public userSearch: UntypedFormControl = new UntypedFormControl();
  public selectedUsers: any[] = [];
  public usersList: any[] = [];
  public isLoading: boolean;
  public lastSearchResults: any[] = [];
  public isSearching: boolean;
  public currentUser: UserInfo;
  public selectedIndex: number;
  private selectedUser: any;
  private sub: Subscription;
  protected onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SwitchUserDialogComponent>,
    private usersService: UsersService,
    private fb: UntypedFormBuilder,
    private store: Store,
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackbarService
  ) {
    this.paginator = new InfiniteScrollPaginator(page =>
      this.usersService.getUsers(page, this.userSearch.value)
    );
  }

  ngOnInit() {
    this.sub = this.store.select('userInfo').subscribe((info: UserInfo) => {
      this.currentUser = info;
    });
    this.paginator.getItems();
    this.initSearch();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public selectUser(user, index: number) {
    this.selectedUser = user;
    this.selectedIndex = index;
  }

  public loginAsUser() {
    this.usersService.loginAsUser(this.selectedUser.id).subscribe(ticket => {
      this.paginator.destroy();
      this.authService.logout();
      this.authService.saveTicketToStore(ticket);
      this.dialogRef.close();
      this.store.set('dashboardOrganization', null);
      this.router.navigate(['./profile']);
    }, err => {
      this.snackBarService.commonNotificationFail(err.message);
    });
  }

  public deleteUser(userId) {

  }

  public userImgErrorHandler(currentUser, index?) {
    if (currentUser) {
      this.currentUser.thumbnail_url = null;
    } else {
      this.paginator.items[index].thumbnail_url = null;
    }
  }

  public getOrganizationTooltip(organizations) {
    let tooltipText = '';
    organizations.forEach(organization => {
      tooltipText += `${organization.name}\n`;
    });
    return tooltipText;
  }

  private filterUsersList() {

    this.paginator.refresh();

  }

  private initSearch() {
    this.userSearch.valueChanges
      .pipe(takeUntil(this.onDestroy),
        distinctUntilChanged(),
        debounceTime(1000))
      .subscribe(() => {
        this.filterUsersList();
      }
    );
  }

}
