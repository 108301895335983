import { Component, OnInit, ViewChildren, Input, AfterViewInit } from '@angular/core';
import { GroupsService } from '../../services/remote-api/groups.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { User } from '@shared/models/user.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Organization } from '@shared/models/organization.model';
import { take, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'friskus-organization-search',
  templateUrl: './organization-search.component.html',
  styleUrls: ['./organization-search.component.scss']
})
export class OrganizationSearchComponent implements OnInit, AfterViewInit {
  @Input() parentForm: UntypedFormGroup;
  @ViewChildren('orgSelectionList') orgSelectionList: MatSelect;
  public filteredOrganizationsList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public organizationsList: any[] = [];
  public organizationSearch: UntypedFormControl = new UntypedFormControl();
  public organizations: UntypedFormControl = new UntypedFormControl();
  protected onDestroy = new Subject<void>();
  public organization: Organization;
  public isLoading = false;
  public isSearching: boolean;
  public selectedOrganizations: any[] = [];
  public lastSearchResults: any[] = [];
  constructor(
    private groupsService: GroupsService
  ) { }

  ngOnInit() {
    this.initSearch();
  }

  ngAfterViewInit() {
    this.setUsersInitialValue();
  }

  isOrganizationSelected(organization: any): boolean {
    return this.selectedOrganizations.findIndex(selected => selected.id === organization.id) !== -1;
  }

  public organizationSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.selected) {
      this.selectedOrganizations.push(event.option.value);
      this.selectedOrganizations.forEach(selectedOrg => {
        this.organizationsList = this.organizationsList.filter(org => org.id !== selectedOrg.id);
        this.lastSearchResults = this.lastSearchResults.filter(org => org.id !== selectedOrg.id);
      });
      // this.filteredOrganizationsList.next(this.lastSearchResults);
    } else {
      const removedMemberIndex = this.selectedOrganizations.findIndex(selected => selected.id === event.option.value.id);
      this.selectedOrganizations.splice(removedMemberIndex, 1);
    }

    this.parentForm.patchValue({
      organizations: this.selectedOrganizations
    });
  }

  public removeSelectedOrganization(index: number) {
    this.selectedOrganizations.splice(index, 1);
    this.parentForm.patchValue({
      organizations: this.selectedOrganizations
    });
  }

  protected setUsersInitialValue() {
    this.filteredOrganizationsList
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        this.orgSelectionList.compareWith = (a: User, b: User) => a && b && a.id === b.id;
      });
  }

  private filterorganizationsList() {
    this.isSearching = true;
    this.groupsService.groupSearch(this.organizationSearch.value).subscribe(usersRes => {
      let results = usersRes.data;
      let search = this.organizationSearch.value;
      if (!search) {
        this.filteredOrganizationsList.next(this.organizationsList);
        return;
      } else {
        search = search.toLowerCase();
      }
      this.selectedOrganizations.forEach(selectedUser => {
        results = results.filter(user => user.id !== selectedUser.id);
      });
      this.lastSearchResults = results;
      this.isSearching = false;
      this.filteredOrganizationsList.next(results);
    });

  }

  private initSearch() {
    this.organizationSearch.valueChanges
      .pipe(takeUntil(this.onDestroy),
        distinctUntilChanged(),
        debounceTime(1000))
      .subscribe(() => {
        this.filterorganizationsList();
      }
      );
  }


}
