import { Component, Inject, OnInit }  from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'friskus-share-card',
  templateUrl: './share-card.component.html',
  styleUrls: ['./share-card.component.scss'],
})
export class ShareCardComponent implements OnInit {
  public ssn: string;
  public pin: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {ssn:string, pin:string},
    private dialogRef: MatDialogRef<ShareCardComponent>) {
    if (data) {
      this.ssn = data.ssn;
      this.pin = data.pin;
    }
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close(false);
  }

}
