import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const RadeMunicipality: UserMunicipality = {
  name: 'rade',
  slogan: {value: '', id: 'banner.rade', meaning: 'bannerSlogan', description: 'slogan for Råde'},
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.'
  },
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', 'e72047d2-4dd3-4419-bfda-9d9125ab184f']
    },
    {type: InfoType.FUNDING, url: 'https://radekommune.fri-go.se/tilskudd/'},
  ]
}
