import {UserMunicipality} from "@shared/models/user-municipality.model";

export const MidtTelemarkMunicipality: UserMunicipality = {
  name: 'midt-telemark',
  slogan: {
    value: 'Aktiviteter og frivillighet', id: 'banner.midtTelemark', meaning: 'bannerSlogan',
    description: 'slogan for midt-telemark'
  },
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
