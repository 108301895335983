import { InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const NoreoguvdalVisMunicipality: UserMunicipality = {
  name: 'noreoguvdal-vis',
  slogan: {},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: true,
  infoPageTitle: 'Leisure account',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: '',
    },
  ]
}
