<div class="file-wrap px-2 py-2 rounded pointer"
  (click)="downloadDocument()">
  <div class="d-flex">
    <div class="logo-container d-inline-block mr-2 align-self-center">
      <!-- <img [src]="'../../../../assets/icons/ic_org_logo.svg'"
        alt="org_logo"
        class="logo-container rounded"> -->
      <!-- <mat-icon>description</mat-icon> -->
      <mat-icon fontSet="material-icons-outlined"
        class="material-icons-round mr-2 text-primary-80">description</mat-icon>
    </div>
    <div class="d-inline-block align-self-center">
      <div class="label-container d-flex">
        <span class="label-name d-inline-block">
          <label class="text-label text-capitalize"
            i18n="Document label@@label.document">
            Document
          </label>
        </span>
      </div>
      <!-- [ngClass]="{'info-text-thin': inMemberList,'info-text': !inMemberList}" -->
      <div *ngIf="!control; else editMode">
        {{document.name}}
      </div>

      <ng-template #editMode>
        <mat-form-field appearance="outline"
        class="w-100">
          <mat-label i18n="Name label@@label.name">Name</mat-label>
          <input matInput
            [formControl]="control"
            required>
          <mat-error *ngIf="control.hasError('required')"
            i18n="Name required error@@error.name.required">
            Name is required
          </mat-error>
          <mat-error *ngIf="control.errors?.serverError">
            {{ control.errors?.serverError }}
          </mat-error>
        </mat-form-field>
      </ng-template>
    </div>
  </div>
</div>