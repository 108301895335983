export enum SNACKBAR_TYPE {
    CUSTOM = 'CUSTOM',
    DATA_UPDATED = 'DATA_UPDATED',
    CHANGES_SAVED = 'CHANGES_SAVED',
    EVENT_CANCELED = 'EVENT_CANCELED',
    SAVE_EVENT_DRAFT = 'SAVE_EVENT_DRAFT',
    PASSWORD_CHANGED = 'PASSWORD_CHANGED',
    GROUP_CREATED = 'GROUP_CREATED',
    ORGANIZATION_DELETED = 'ORGANIZATION_DELETED',
    IMAGE_DELETED = 'IMAGE_DELETED',
    DOCUMENT_DELETED = 'DOCUMENT_DELETED',
    EVENT_UPDATED = 'EVENT_UPDATED',
    EVENT_CREATED = 'EVENT_CREATED',
    EVENT_DELETED = 'EVENT_DELETED',
    EVENT_DRAFT_CREATED = 'EVENT_DRAFT_CREATED',
    EVENT_DRAFT_UPDATED = 'EVENT_DRAFT_UPDATED',
    COMMENT_ADDED = 'COMMENT_ADDED',
    COMMENT_UPDATED = 'COMMENT_UPDATED',
    COMMENT_DELETED = 'COMMENT_DELETED',
    INVITE_SENT = 'INVITE_SENT',
    REQUEST_SENT = 'REQUEST_SENT',
    ATTENDED = 'ATTENDED',
    UNATTENDED = 'UNATTENDED',
    ACTIVITY_LEFT = 'ACTIVITY_LEFT',
    OCCURENCY_PUBLISHED = 'OCCURENCY_PUBLISHED',
    OCCURENCY_UNPUBLISHED = 'OCCURENCY_UNPUBLISHED',
    CHECK_EMAIL = 'CHECK_EMAIL',
    ROLE_CHANGED = 'ROLE_CHANGED',
    ROLE_CHANGE_FAIL = 'ROLE_CHANGE_FAIL',
    NO_SELECTED_MEMBERS = 'NO_SELECTED_MEMBERS',
    MEMBER_REMOVED = 'MEMBER_REMOVED',
    MEMBER_REQUEST_APPROVED = 'MEMBER_REQUEST_APPROVED',
    MEMBER_REQUEST_CANCELED = 'MEMBER_REQUEST_CANCELED',
    COHOST_REQUEST_DECLINED = 'COHOST_REQUEST_DECLINED',
    COHOST_REQUEST_ACCEPTED = 'COHOST_REQUEST_ACCEPTED',
    SUBSIDIARY_REQUEST_ACCEPTED = 'SUBSIDIARY_REQUEST_ACCEPTED',
    SUBSIDIARY_REQUEST_DECLINED = 'SUBSIDIARY_REQUEST_DECLINED',
    MEMBER_ASSIGNED = 'MEMBER_ASSIGNED',
    MEMBER_ADDED = 'MEMBER_ADDED',
    ORG_REQUEST_DECLINED = 'ORG_REQUEST_DECLINED',
    ORG_REQUEST_ACCEPT = 'ORG_REQUEST_ACCEPT',
    PRIVATE_ORGANIZATION = 'PRIVATE_ORGANIZATION',
    LEISURE_CARD_CREATED = 'LEISURE_CARD_CREATED',
    LEISURE_CARD_FAIL = 'LEISURE_CARD_FAIL',
    LEISURE_CARD_ENABLED = 'LEISURE_CARD_ENABLED',
    CARD_REFILL = 'CARD_REFILL',
    CHAT_MEMBER_REMOVED = 'CHAT_MEMBER_REMOVED',
    CHAT_REMOVED = 'CHAT_REMOVED',
    CHAT_LEAVED = 'CHAT_LEAVED',
    CERTIFICATE_REQUESTED = 'CERTIFICATE_REQUESTED',
    MESSAGE_SENT = 'MESSAGE_SENT',
    CULTURE_CARD_ASSIGNED = 'CULTURE_CARD_ASSIGNED',
    CULTURE_CARD_MARKED = 'CULTURE_CARD_MARKED',
    CULTURE_CARD_USER_ADDED = 'CULTURE_CARD_USER_ADDED',
    PARTICIPANT_MOVED = 'PARTICIPANT_MOVED',
    NO_SELECTED_USERS = 'NO_SELECTED_USERS',
    NOTIFICATION_SETTINGS_UPDATED = 'NOTIFICATION_SETTINGS_UPDATED',
    TASK_COMPLETED = 'TASK_COMPLETED',
    VERIFICATION_SEND = 'VERIFICATION_SEND',
    VERIFICATION_EMAIL_REUQUIRED = 'VERIFICATION_EMAIL_REUQUIRED',
    VERIFICATION_DISABLED = 'VERIFICATION_DISABLED',
    VERIFICATION_ENABLED = 'VERIFICATION_ENABLED',
    OWNER_CHANGED = 'OWNER_CHANGED'
}

export interface SnackBarData {
    type: SNACKBAR_TYPE;
    message?: string;
    header?: string;
}
