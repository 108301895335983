import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";

export interface BeforeUnload {
  beforeUnload(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean;
}

@Injectable()
export class BeforeUnloadGuard<T extends BeforeUnload>
  implements CanDeactivate<T>
{
  canDeactivate(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (component.beforeUnload) {
      return component.beforeUnload(currentRoute, currentState, nextState);
    }

    return true;
  }
}
