<div class="drop-down mat-elevation-z8 jump check-municipality" *ngIf="state">
  <div
    class="text-left my-0 bg-primary-80 rounded-top px-4 py-3 d-flex items align-items-center justify-content-between">
    <h2 class="text-white m-0 font-weight-bold mr-6"
        i18n="Are you on correct municipality@@dialog.checkMunicipality">Are you on correct municipality?</h2>
    <mat-icon matTooltip="Close"
              class="close-icon pointer text-white"
              i18n-matTooltip="Close tooltip@@tooltip.close"
              (click)="close()">Close</mat-icon>
  </div>
  <div class="p-4 pt-2">
    <button mat-button
            type="button"
            color="primary"
            (click)="chooseMunicipality()"
            i18n="Change municipality@@btn.changeMunicipality"
            class="d-block mb-3 w-100 text-uppercase">
      Change municipality
    </button>

    <div class="w-100 d-flex justify-content-end">
      <button (click)="useCurrentMunicipality()"
              mat-raised-button
              color="primary"
              i18n="Yes, use this municipality@@btn.useMunicipality"
              class="w-100">Yes, use this municipality
      </button>
    </div>
  </div>
</div>
