<ng-container *ngIf="visible">
  <div [classList]="classFromType" #container>
    <div class="container d-flex align-items-center py-2">
      <mat-icon
        fontSet="material-icons-outlined"
        class="material-icons-round">{{iconFromType}}</mat-icon>
      <h4 class="m-0 pl-1 pl-lg-2"
        matTooltip="{{message}}"
        matTooltipClass="tooltip-secondary tooltip-secondary-wide"
        (click)="expand($event)">

          <ng-container *ngIf="isCritical; else News">
            <span i18n="Announcement label@@label.announcement">Announcement</span>: {{message}}
          </ng-container>
          <ng-template #News>
            <span i18n="Announcement news label@@label.news">News</span>:
            <span i18n="News header tickets feature@@news.newFeatureTickets">We released Fritidskort with free tickets, new info pages, new look, filter for free events
              <a href="https://friskusas.no/utviklingsnytt">and more</a>!
            </span>
          </ng-template>
          </h4>
    </div>
  </div>
</ng-container>
