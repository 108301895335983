import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const TrondheimVisMunicipality: UserMunicipality = {
  name: 'trondheim-vis',
  slogan: {
    value: 'Aktivitetsportalen - Trondheim',
    id: 'banner.trondheim',
    meaning: 'bannerSlogan',
    description: 'slogan for Trondheim'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/aa59723c-aa38-4803-b952-428750e5932c/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'leisure account',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 10,
      maxAge: 13,
      amount: 1600,
      url: 'https://bodo.kommune.no/fritidskortet/',
    },
  ]
}
