import { API_URL_KEY } from './../../../config/api-url.config';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { Ticket, TicketGroup } from '@shared/models/ticket.model';
import { GenericHttpService } from '@services/generic-http/generic-http.service';


export type TicketGroupListResponse = {
  data ?: TicketGroup[], error ?: string, message ?: string
}

export type TicketListResponse = {
  data ?: Ticket[], error ?: string, message ?: string
}

export type TicketResponse = {
  data ?: Ticket, error ?: string, message ?: string
}

export type TicketListFilters = {
  page: number,
  perPage ?: number,
  age ?: number,
  plusOne ?: boolean,
  cardId ?: string,
  reserveable ?: boolean, // probably wont make this
}

export enum TicketStateFilter {
  all     = 'all',    // DEFAULT value
  used    = 'used',   // Tickets that are used (can include currently active tickets)
  unused  = 'unused', // only unused
  valid   = 'valid',  // unused and used, but still valid for entry
  active  = 'active', // Currently active ticket
}

export type TicketFilters = {
  page: number,
  perPage ?: number,
  state ?: TicketStateFilter,
}

export const TICKET_RESERVE_UNAVAILABLE_HTTP_ERROR_CODES : number[] = [
  406, // Group inactive
  409, // Inconsitent municipality between card and ticket group
  410, // No tickets available in group
];
export const TICKET_RESERVE_BAD_PRECONDITION_HTTP_ERROR_CODES = [
  402, // Card is not given access to tickets
  412, // Age requirement not met
  429, // Card already has ticket
  431, // Card has used max of group
];


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: GenericHttpService
  ) { }

  public getTicketGroups(params: TicketListFilters = {page: 1}): Observable<TicketGroupListResponse> {
    return this.http.get(API_URL_KEY.tickets, '/tickets', params);
  }

  public getTicketGroup(ticketGroupId: string): Observable<{ data: TicketGroup }> {
    const path = `/tickets/${ticketGroupId}`;
    return this.http.get(API_URL_KEY.tickets, path);
  }

  public getTicketsOnCard(cardId: string, params: TicketFilters = {page:1}): Observable<TicketListResponse> {
    const path = `/cards/${cardId}/tickets`;
    return this.http.get(API_URL_KEY.tickets, path, params);
  }

  public GetActiveTicketsOnCard(userId: string): Observable<Ticket[]> {
    const path = `/tickets/${userId}/active`;
    return this.http.get(API_URL_KEY.tickets, path, {})
  }

  public getTicketsFromAuthenticatedUser(params: {}): Observable<TicketListResponse> {
    const path = `/tickets/mine`;
    return this.http.get(API_URL_KEY.tickets, path, params);
  }

  public get(cardId: string, ticketId: string): Observable<{data: Ticket}> {
    const path = `/cards/${cardId}/tickets/${ticketId}`;
    return this.http.get(API_URL_KEY.tickets, path);
  }

  public getReservedTicket(ticketId: string): Observable<{data: Ticket}> {
    const path = `/tickets/reserved/${ticketId}`;
    return this.http.get(API_URL_KEY.tickets, path);
  }

  public reserve(ticketGroupId: string, cardId: string): Observable<TicketResponse> {
    const path = `/tickets/${ticketGroupId}/reserve`;
    return this.http.post(API_URL_KEY.tickets, path, {card_id: cardId});
  }

  public use(ticketId: string): Observable<TicketResponse> {
    const path = `/tickets/${ticketId}/use`;
    return this.http.post(API_URL_KEY.tickets, path);
  }

}
