<div mat-dialog-container>
  <div mat-dialog-content>

    <h2 class="m-0 font-weight-bold mr-6"
        i18n="Sharing Fritidskort dialog@@header.shareFritidskort">Sharing Fritidskort</h2>
    <p i18n="Share Fritidskort helptext dialog@@hint.shareFritidskortHelpText"
      >You may share Fritidskortet with others if they have created their own Friskus profile.</p>
    <p i18n="Share Fritidskort instructions@@hint.shareFritidskortInstructions"
      >To get access they have to log in to Friskus and go to "Get Fritidskort" from the menu. After
      provide the SSN they will be asked for the PIN below that you must share with them.</p>

    <div class="d-inline-flex">
      <div class="mr-2 border border-accent rounded p-2">
        <p class="font-size-xs font-weight-bold pr-2 m-0" i18n="SSN label@@label.ssn">SSN</p>
        <p class="font-size-lg m-0">{{ssn}}</p>
      </div>
      <div class="border border-accent rounded p-2 ">
        <p class="font-size-xs font-weight-bold pr-2 m-0" i18n="Pin label@@label.pin">PIN</p>
        <p class="font-size-lg m-0">{{pin}}</p>
      </div>
    </div>

    <p class="font-italic" i18n="PIN hint@@hint.pinIsGenerated">PIN is generated and can't be changed.</p>

  </div>

  <div mat-dialog-actions align="end" class="pb-4 mat-dialog-actiond pt-0">
      <button
        mat-raised-button
        color="primary"
        mat-dialog-close
        type="button"
        class="mr-2"
        (click)="close()">
        <span i18n="Close button@@button.close">Close</span>
      </button>
  </div>
</div>
