import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";
import {VossBannerComponent} from "@shared/components/municipality-banner/custom/voss/voss-banner.component";

export const VossMunicipality: UserMunicipality = {
  name: 'voss',
  slogan: {
    value: 'Opplev <a class="slogan" href="https://voss.friskus.com/events?filters=global_filters_municipalities(EQ)e172af93-bfa0-48b6-8505-823988b61c06$$true(AND)events-filterscategories(EQ)4b3931e8-fb87-43ed-864d-0c75f3617973$$false">kulturarrangement</a> i voss herad',
    id: 'banner.voss',
    meaning: 'bannerSlogan',
    description: 'slogan for voss'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/c86d7c81-3308-4517-9abe-fba57a8b626c/-/preview/-/quality/smart/',
  bannerType: 'image',
  customEventsBanner: VossBannerComponent,
  customOrganizationsBanner: VossBannerComponent,
  hasLeisureCard: false,
  sunset: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Støtteordningar',
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'Skattkammeret Voss',
      url: 'https://kirkensbymisjon.no/skattkammeret/voss/'
    },
    {
      type: InfoType.OTHER,
      links: [
        {
          title: 'Barn og unge',
          url: 'https://voss.herad.no/tenester/kultur-idrett-og-fritid/stotteordningar-barn-og-unge/',
        },
      ]
    },
    {
      type: InfoType.SUPPORT_CONTACT,
      url: 'https://voss.herad.no/tenester/helse-sosial-og-omsorg/helse-og-omsorgstenester/avlastning-og-stotte/stottekontakt/'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://voss.herad.no/tenester/kultur-idrett-og-fritid/tilskot-lag-og-organisasjonar/'
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {
          url: 'https://voss.herad.no/tenester/kultur-idrett-og-fritid/ung-pa-voss/',
          title: "Ung på Voss"
        },
        {
          url: 'https://voss.herad.no/tenester/kultur-idrett-og-fritid/ung-pa-voss/ungdomsklubbar-og-opne-moteplassar/',
          title: "Ungdomsklubbar"
        }
      ]
    }
  ]
}
