<div class="banner-container">
  <img class="image" src="https://ucarecdn.com/66679c94-e9d1-4726-809b-646b49ad7897/vossbannerimage.jpg" alt="Banner"
       title="Banner"/>

  <div class="container overlay">

    <h2>HER FINN DU OVERSIKT OVER KVA SOM SKJER PÅ VOSS</h2>
    <div class="text-descriptions">
      <h5>AKTIVITETAR</h5>
      <p>Scroll ned og bruk filter slik at du får opp det som interesserer deg.</p>

      <h5>ARRANGØRAR</h5>
      <p>Under denne fana finn du oversikt over lagslivet og aktivitetstilbydarar.</p>

      <h5>STØTTEORDNINGAR</h5>
      <p>Her finn du informasjon om tiltak og ordningar for barn og unge.</p>
    </div>
    <button class="right-button" (click)="filterEvents()">
      Trykk HER for<br/>
      KULTURKALENDER
    </button>
    <div class="main-search-container">
      <friskus-main-search (chosen)="handleSearchItemChosen($event)" [insideSidenav]="false"></friskus-main-search>
    </div>

    <button class="mobile-button" (click)="filterEvents()">
      Trykk HER for KULTURKALENDER
    </button>
  </div>
</div>
