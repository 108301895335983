import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MUNICIPALITY_CONFIG } from '@config/municipality.config';

import { ROUTES_MAP } from '@config/routes.config';
import { UserMunicipality } from '@shared/models/user-municipality.model';
import {
  PrivateOrganizationComponent
} from './features/organisations/organization-private/private-organization/private-organization.component';

function getUserMunicipality(): UserMunicipality {
  const hostname = window.location.hostname;
  const municipalityFromHostName = hostname.split('.')[0];
  const contractedMunicipalityName: string | undefined = Object.keys(MUNICIPALITY_CONFIG).find(municipalityName => {
    return municipalityFromHostName === municipalityName;
  });
  return contractedMunicipalityName ? MUNICIPALITY_CONFIG[contractedMunicipalityName] : MUNICIPALITY_CONFIG.default;
}

const sunset = getUserMunicipality().sunset;

const routes: Routes = [
  {
    path: '', redirectTo: sunset ? ROUTES_MAP.sunset : ROUTES_MAP.events, pathMatch: 'full'
  },
  {
    path: ROUTES_MAP.info,
    loadChildren: () => import('./features/info-page/info-page.module').then(m => m.InfoPageModule)
  },
  {
    path: ROUTES_MAP.events,
    loadChildren: () => import('./features/events/events.module').then(m => m.EventsModule)
  },
  {
    path: ROUTES_MAP.groups,
    loadChildren: () => import('./features/organisations/organisations.module').then(m => m.OrganisationsModule)
  },
  {
    path: ROUTES_MAP.tickets,
    loadChildren: () => import('./features/tickets/tickets.module').then(m => m.TicketsModule)
  },
  {
    path: ROUTES_MAP.profile,
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: ROUTES_MAP.search,
    loadChildren: () => import('./features/search/search.module').then(m => m.SearchModule)
  },
  {
    path: ROUTES_MAP.dashboard,
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: ROUTES_MAP.chat,
    loadChildren: () => import('./features/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: ROUTES_MAP.minIdLogin,
    loadChildren: () => import('./features/min-id-login/min-id-login.module').then(m => m.MinIdLoginModule),
    canActivate: []
  },
  {
    path: ROUTES_MAP.sunset,
    loadChildren: () => import('./features/sunset/sunset.module').then(m => m.SunsetModule)
  },
  {
    path: `${ROUTES_MAP.leisureCard}/amount`,
    loadChildren: () => import('./features/leisure-card-amount/leisure-card-amount.module').then(m => m.LeisureCardAmountModule)
  },
  {
    path: ROUTES_MAP.leisureCard, redirectTo: '/info'
  },
  {
    path: '404',
    loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '500',
    loadChildren: () => import('./features/server-error/server-error.module').then(m => m.ServerErrorModule)
  },
  {
    path: ROUTES_MAP.privateOrg,
    component: PrivateOrganizationComponent
  },
  { path: 'no', redirectTo: '' },  // because norwegian language is the default one
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
