import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'friskus-browser-platform',
  templateUrl: './browser-platform.component.html',
  styleUrls: ['./browser-platform.component.scss']
})
export class BrowserPlatformComponent implements OnInit {
  public isBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

}
