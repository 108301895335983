import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const TrondheimMunicipality: UserMunicipality = {
  name: 'trondheim',
  slogan: {value: 'AKTIVITETSPORTALEN - TRONDHEIM', id: 'banner.trondheim', meaning: 'bannerSlogan', description: 'slogan for trondheim'},
  data: {},
  bannerURL: 'https://ucarecdn.com/aa59723c-aa38-4803-b952-428750e5932c/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: ''
    }
  ]
}
