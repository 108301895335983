import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@store';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';
import { Utils } from '@shared/utils/index';
import { VALIDATION_PATTERN } from '@config/validation-patterns.config';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  templateUrl: './edit-verify-username.component.html',
  styleUrls: ['./edit-verify-username.component.scss']
})
export class EditVerifyUsernameComponent implements OnInit, OnDestroy {
  public form = this.fb.group({
    username: ['', Validators.required]
  });
  public isPhone: boolean;
  public usernameObj: any;
  public isLoading: boolean;
  public isSecondNameVerify: boolean;
  private sub: Subscription;
  private verificationId: string;
  private returnUrl: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackbarService
  ) { }

  get username() {
    return this.form.get('username');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.verificationId = params.verificationId;
      this.isSecondNameVerify = params?.isSecondNameVerify === 'true';
      this.returnUrl = params?.returnUrl || '/';
    });

    this.sub = this.store.select('username').subscribe(res => {
      const usernameObj: any = res;
      this.usernameObj = usernameObj;
      if (usernameObj) {
        if (usernameObj.hasOwnProperty('phone')) {
          this.isPhone = true;
        }

        if (this.isPhone) {
          const phone = usernameObj.phone && usernameObj.phone.includes('47') ?
            usernameObj.phone.substring(2) :
            usernameObj.phone;
          this.form.patchValue({username: phone});
          this.form.controls.username.setValidators(Validators.pattern(VALIDATION_PATTERN.phoneLong));
        } else {
          this.form.patchValue({username: usernameObj.email});
          this.form.controls.username.setValidators(Validators.pattern(VALIDATION_PATTERN.email));
        }

        this.form.controls.username.setValidators(Validators.required);
      } else {
        this.router.navigate(['./profile', 'edit']);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public onSubmit() {
    this.isLoading = true;
    const username = this.username.value;
    const usernameObj = this.isPhone ? {phone: username} : {email: username};
    if (this.verificationId && (this.usernameObj.email === username || this.usernameObj.phone === username)) {
      // Proceed with current verification id and resend code
      this.authService.resendVerificationCode(this.verificationId).subscribe(
        (_: any) => {
          this.isLoading = false;
          this.router.navigate(['./auth', 'verify', 'code'], {
            queryParams: {verificationId: this.verificationId},
            queryParamsHandling: 'merge'
          });
        },
        errRes => {
          if (errRes.status === 429) {
            this.router.navigate(['./auth', 'verify', 'code'], {queryParams: {
              verificationId: this.verificationId,
              tooManyAttempts: true
            },
            queryParamsHandling: 'merge'});
          }
          this.snackBarService.commonNotificationFail(errRes.message);
          this.isLoading = false;
        });
    } else {
      this.store.set('username', usernameObj);
      const req = this.isPhone ?
        this.authService.createPhoneVerificationCode(username) :
        this.authService.createEmailVerificationCode(username);
      req.subscribe(
        (res: any) => {
          const verificationId = res.data.id;
          this.isLoading = false;
          this.router.navigate(['./auth', 'verify', 'code'], {
            queryParams: {verificationId},
            queryParamsHandling: 'merge'
          });
        },
        errRes => {
          Utils.setValidationErrors(this.form, {username: [errRes.errors.email || errRes.errors.phone]});
          this.snackBarService.commonNotificationFail(errRes.message);
          this.isLoading = false;
        });
    }
  }

  public onCancelClick() {
    if (this.isSecondNameVerify) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(['./profile', 'edit']);
    }
  }


}
