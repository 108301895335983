import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const HortenMunicipality: UserMunicipality = {
  name: 'horten',
  slogan: {value: '', id: 'banner.horten', meaning: 'bannerSlogan', description: 'slogan for horten'},
  data: {
    headerDescription: '',
    amount: 0,
    minAmountOfActivities: 10
  },
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-horten',
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '58435761-d6d8-4b3d-936a-7c57002bf48d']
    },
  ]

}
