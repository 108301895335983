import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

import { distinctUntilChanged, distinctUntilKeyChanged, pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface State {
  user: any;
  [key: string]: any;
}

const defaultState: State = {
  user: undefined
};

@Injectable()
export class Store {
  private subject = new BehaviorSubject<State>(defaultState);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(
      distinctUntilKeyChanged(name),
      pluck(name)
    );
  }

  set(name: string, state: any) {
    this.subject.next({ ...this.value, [name]: state });
  }
}
