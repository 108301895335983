import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'friskus-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input()
  loading = false;

  @Input()
  diameter = 60;
}
