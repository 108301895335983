import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const MossMunicipality: UserMunicipality = {
  name: 'moss',
  slogan: {value: '', id: 'banner.moss', meaning: 'bannerSlogan', description: 'slogan for moss'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: "Fritidskonto",
  infoSlots: [
      {
        type: InfoType.FRISKUS_LEISURE_CARD,
        title: 'Fritidskonto',
        minAge: 6,
        maxAge: 18,
      },
  ]
}
