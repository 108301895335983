export interface EventFilterQuery {
  age_group?: string[];
  start_at?: string;
  end_at?: string;
  categories?: string[];
  municipalities?: string[];
  is_free: boolean;
  districts?: string[];
  type?: string[];
  'distance[radius]'?: number;
  'distance[lat]'?: number;
  'distance[lng]'?: number;
  'bounds[ne_lng]'?: number;
  'bounds[ne_lat]'?: number;
  'bounds[sw_lng]'?: number;
  'bounds[sw_lat]'?: number;
}

export class EventFilterQuery {
  constructor(parameters: EventFilterQuery | {} = {}) {
    Object.keys(parameters).forEach(key => {
      if (key !== 'start_at' && key !== 'end_at' && key !== 'distance[radius]'
        && key !== 'distance[lat]' && key !== 'distance[lng]'
        && key !== 'is_free') { // @TODO there has to be a better way, took a long time to find
        this.setArrayValue(parameters[key], key);
      } else {
        (this[key] as any) = parameters[key];
      }
    });
  }

  private setArrayValue(param: any, paramKey: string) {
    if (param && param instanceof Array) {
      this[paramKey] = param;
    } else {
      this[paramKey] = [];
      this[paramKey].push(param);
    }
  }
}
