<div mat-dialog-container class="px-3 pt-2 position-relative">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="font-weight-bold font-size-lg"
         i18n="Select your current municipality@@dialog.chooseMunicipality">Select your current municipality</div>
    <div class="close-icon pointer close-btn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="municipalities bg-wrap px-4 py-2">
    <friskus-spinner class="col-md-12"
                     [loading]="isLoading"></friskus-spinner>
    <div *ngFor="let municipalities of chunks" class="d-flex flex-column mr-6">
      <button mat-button class="municipality-link"
         *ngFor="let municipality of municipalities"
         (click)="onSelectMunicipality(municipality)">{{municipality.name}}</button>
    </div>

  </div>
  <div class="d-flex justify-content-end mt-3">
    <button mat-dialog-close
            mat-stroked-button
            color="primary" i18n="Cancel header@@dialog.cancel">Cancel</button>
  </div>
</div>
