<nav class="navbar navbar-expand-lg  bg-primary py-0 d-none d-lg-flex flex-wrap"
     [ngClass]="{'navbar-light': (isMobile | async)?.matches, 'navbar-dark': !(isMobile |
  async)?.matches,'d-flex': (isMobile | async)?.matches}"
     id="mainNav">
  <div class="container py-2"
       [ngClass]="{'justify-content-between': (isMobile | async)?.matches}">

    <button class="navbar-toggler navbar-toggler-right text-uppercase bg-primary text-white rounded"
            mat-icon-button
            type="button"
            aria-label="Toggle navigation"
            (click)="toggleMenu()">
      <mat-icon>
        <ng-container *ngIf="!isMenuShown">menu</ng-container>
        <ng-container *ngIf="isMenuShown">close</ng-container>
      </mat-icon>
    </button>

    <button (click)="handleNavClick(true); navigateHome();"
       class="btn-transparent header-logo d-flex align-items-center ml-sm-2 ml-lg-0 pointer"
       tabindex="0">
      <img class="img-fluid logo-img"
           src="../../../../assets/img/logo_main.svg"
           alt="Logo">

      <div class="ml-2">
        <h2 class="text-white m-0">
          <span i18n="Discover logo@@header.discover">Discover</span>
          <span *ngIf="currentMunicipality"> {{currentMunicipality.name}}</span>
          <span *ngIf="!currentMunicipality"
                i18n="your neighborhood logo@@header.yourNeighborhood"> your neighborhood</span>
        </h2>
      </div>
    </button>

    <div class="d-flex" *ngIf="(isMobile | async)?.matches">
      <div class="d-flex flex-column justify-content-center">
        <friskus-help-beacon-mobile></friskus-help-beacon-mobile>
      </div>
      <div class="d-flex flex-column justify-content-center"
           *ngIf="user && !userMunicipality.sunset">
        <friskus-push-notifications></friskus-push-notifications>
      </div>
      <div *ngIf="user">
        <friskus-messenger-icon></friskus-messenger-icon>
      </div>
    </div>
    <div class="collapse navbar-collapse"
         id="navbarResponsive"
         [ngClass]="{
        'mobile-controls px-0': (isMobile | async)?.matches
      }"
         *ngIf="isMenuShown || !(isMobile | async)?.matches">
      <ul class="navbar-nav m-0 ml-lg-5"
          *ngIf="!(isMobile | async)?.matches">
        <li class="nav-item mx-0  order-1 order-lg-0" *ngIf="!userMunicipality.sunset">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/events']"
             [queryParams]="eventFilters"
             [queryParamsHandling]="isLinkActive('/events') ? 'merge':''"
             [class.active]="isLinkActive('/events')"
             (click)="handleNavClick()"
             i18n="Events headerItem@@header.events">Events</a>
        </li>

        <li class="nav-item mx-0  order-1 order-lg-0"
            *ngIf="(isMobile | async)?.matches">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/events/calendar']"
             [queryParams]="eventFilters"
             [queryParamsHandling]="isLinkActive('/events/calendar') ? 'merge':''"
             [class.active]="isLinkActive('/events/calendar')"
             (click)="handleNavClick()"
             i18n="Events calendar headerItem@@header.eventsCalendar">Events calendar</a>
        </li>

        <li class="nav-item mx-0  order-2 order-lg-1" *ngIf="!userMunicipality.sunset">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/organisations']"
             [queryParams]="organizationFilters"
             [class.active]="isLinkActive('/organisations')"
             (click)="handleNavClick()"
             i18n="Organisations headerItem@@header.organisations">Organisations</a>
        </li>

        <li class="nav-item mx-0  order-2 order-lg-1" *ngIf="!userMunicipality.sunset">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/info']"
             [class.active]="isLinkActive('/info')"
             (click)="handleNavClick()">
             <span *ngIf="!!userMunicipality?.infoPageTitle">{{userMunicipality.infoPageTitle}}</span>
             <span *ngIf="!userMunicipality?.infoPageTitle" i18n="Info headerItem@@header.info">Fritidskonto</span>
          </a>
        </li>

        <li *ngIf="!(isMobile | async)?.matches && !userMunicipality.sunset"
            class="nav-item mx-0  order-2 order-lg-1">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/organisations', 'new']"
             routerLinkActive="active"
             (click)="handleNavClick()"
             [routerLinkActiveOptions]="{exact: true}"
             i18n="Create organization headerItem@@header.createOrganization">Create organization</a>
        </li>

        <li class="nav-item mx-0  order-4 order-lg-3"
            *ngIf="user && (isMobile | async)?.matches">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/dashboard']"
             [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active"
             (click)="handleNavClick(false, 'dashboard')"
             i18n="Dashboard headerItem@@header.dashboard">Dashboard</a>
        </li>

        <li class="nav-item mx-0  order-4 order-lg-3"
            *ngIf="user && isSuperAdmin && (isMobile | async)?.matches">
          <a class="nav-link pt-2 px-0 mx-lg-2"
             [routerLink]="['/dashboard/admin']"
             [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active"
             (click)="handleNavClick()"
             i18n="Requests tab header@@tab.requests">Requests</a>
        </li>

        <li class="nav-item  mx-0  order-3 order-lg-2"
            *ngIf="(isMobile | async)?.matches">
          <a class="nav-link  align-middle pt-2 px-0 mx-lg-2"
             [routerLink]="['/organisations', 'new']"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}"
             (click)="handleNavClick()"
             i18n="Create organization headerItem@@header.createOrganization">Create organization</a>
        </li>

        <li class="d-flex align-items-center order-0 order-lg-4 ml-auto" *ngIf="!(isMobile | async)?.matches && user">
          <ul class="d-flex">
            <li class="d-flex align-items-center order-lg-4 ml-lg-4" *ngIf="!userMunicipality.sunset">
              <friskus-push-notifications></friskus-push-notifications>
            </li>
            <li class="d-flex align-items-center order-lg-4 ml-lg-4">
              <friskus-messenger-icon></friskus-messenger-icon>
            </li>
          </ul>
        </li>

        <li class="nav-item order-4 ml-lg-auto"
            *ngIf="!user">
          <button class="btn-transparent nav-link pt-2 px-0 mx-lg-3 pointer"
            routerLinkActive="active"
            (click)="loginNavigate()"
            i18n="Log in headerItem@@header.login">Log in</button>
        </li>

        <li class="nav-item order-4"
          *ngIf="!user">
          <button
            class="btn-transparent nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center pointer"
            [matMenuTriggerFor]="langMenu">
            <span>{{choosedLang}} </span>
            <span class="material-icons">keyboard_arrow_down</span>
          </button>

          <mat-menu #langMenu="matMenu">
            <button mat-menu-item
              (click)="navigateToLang('no')">
              <span>Norsk Bokmål</span>
            </button>
            <button mat-menu-item
              (click)="navigateToLang('nn')">
              <span>Nynorsk</span>
            </button>
            <button mat-menu-item
              (click)="navigateToLang('en')">
              <span>English UK</span>
            </button>
          </mat-menu>
        </li>

        <li class="nav-item mx-0 order-4"
            *ngIf="user && (isMobile | async)?.matches">
          <a [routerLink]="['/profile']"
             class="nav-link pt-2 px-0 mx-lg-3"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}"
             (click)="handleNavClick()"
             i18n="Profile headerItem@@header.profile">Profile</a>
        </li>

        <li class="nav-item mx-0  order-5"
            *ngIf="user && (isMobile | async)?.matches">
          <a [routerLink]="['/profile/edit']"
             class="nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center"
             routerLinkActive="active"
             [routerLinkActiveOptions]="{exact: true}"
             (click)="handleNavClick()">
            <span i18n="Settings headerItem@@header.settings">Settings</span>
            <span *ngIf="userInfo && (!userInfo.email || !userInfo.phone)"
                  class="badge-label text-center badge-label-accent round pointer ml-auto">1</span>
          </a>
        </li>

        <li class="nav-item mx-0  order-6"
            *ngIf="user && (isMobile | async)?.matches">
          <a class="nav-link pt-2 px-0 mx-lg-3"
             (click)="onLogout();"
             i18n="Logout headerItem@@header.logout">Logout</a>
        </li>

      </ul>

      <mat-accordion class="w-100"
                     multi
                     [displayMode]="'flat'"
                     *ngIf="(isMobile | async)?.matches">
        <div class="search-wrapper d-flex align-items-center ml-lg-auto order-0 order-lg-3 bg-primary-80"
             [ngClass]="{'w-100': (isMobile | async)?.matches}"
             *ngIf="isHeaderSearchShown">
          <friskus-main-search [insideSidenav]="(isMobile | async)?.matches"
                               [showClose]="!(isMobile | async)?.matches"
                               (closed)="toggleSearch()"
                               (chosen)="handleSearchItemChosen($event)"
                               *ngIf="isSearchShown || (isMobile | async)?.matches"
                               class="search-input"></friskus-main-search>

          <button mat-icon-button
                  *ngIf="!isSearchShown && !(isMobile | async)?.matches"
                  (click)="toggleSearch()">
            <mat-icon class="text-white">search</mat-icon>
          </button>
        </div>

        <ul class="navbar-nav h-auto m-0 ml-3">

          <li class="nav-item mx-0" *ngIf="!userMunicipality.sunset">
            <a class="nav-link pt-2 mx-lg-2"
              [routerLink]="['/events']"
              [queryParams]="eventFilters"
              [queryParamsHandling]="isLinkActive('/events') ? 'merge':''"
              [class.active]="isLinkActive('/events')"
              (click)="handleNavClick()"
              i18n="Events headerItem@@header.events">Events</a>
          </li>

          <li class="nav-item mx-0" *ngIf="!userMunicipality.sunset">
            <a class="nav-link pt-2 px-0 mx-lg-2"
               [routerLink]="['/organisations']"
               [queryParams]="organizationFilters"
               [class.active]="isLinkActive('/organisations')"
               (click)="handleNavClick()"
               i18n="Organisation list headerItem@@header.organisationList">Organisation list</a>
          </li>

          <li class="nav-item mx-0" *ngIf="!userMunicipality.sunset">
            <a class="nav-link pt-2 px-0 mx-lg-2"
               [routerLink]="['/organisations', 'new']"
               [class.active]="isLinkActive('/organisations/new')"
               (click)="handleNavClick()"
               i18n="Create organization headerItem@@header.createOrganization">Create organization</a>
          </li>

          <li class="nav-item mx-0" *ngIf="!userMunicipality.sunset">
            <a class="nav-link pt-2 px-0 mx-lg-2"
                [routerLink]="['/info']"
                [class.active]="isLinkActive('/info')"
                (click)="handleNavClick()">
                <span *ngIf="!!userMunicipality?.infoPageTitle">{{userMunicipality.infoPageTitle}}</span>
                <span *ngIf="!userMunicipality?.infoPageTitle" i18n="Info headerItem@@header.info">Getting started</span>
            </a>
          </li>

          <li class="nav-item mx-0"
              *ngIf="user && !userMunicipality.sunset">
            <a class="nav-link pt-2 px-0 mx-lg-2"
               [routerLink]="['/dashboard']"
               [routerLinkActiveOptions]="{exact: true}"
               routerLinkActive="active"
               (click)="handleNavClick(false, 'dashboard')"
               i18n="Dashboard headerItem@@header.dashboard">Dashboard</a>
          </li>

          <li class="nav-item mx-0"
              *ngIf="user && isSuperAdmin && !userMunicipality.sunset">
            <a class="nav-link pt-2 px-0 mx-lg-2"
               [routerLink]="['/dashboard/admin']"
               [routerLinkActiveOptions]="{exact: true}"
               routerLinkActive="active"
               (click)="handleNavClick()"
               i18n="Requests tab header@@tab.requests">Requests</a>
          </li>

          <li class="nav-item mx-0"
              *ngIf="user && !userMunicipality.sunset">
            <a [routerLink]="['/profile']"
              class="nav-link pt-2 px-0 mx-lg-3"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              (click)="handleNavClick()"
              i18n="Profile headerItem@@header.profile">Profile</a>
          </li>

          <li class="nav-item mx-0"
              *ngIf="user">
            <a [routerLink]="['/profile/edit']"
              class="nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              (click)="handleNavClick()">
              <span i18n="Settings headerItem@@header.settings">Settings</span>
              <span *ngIf="userInfo && (!userInfo.email || !userInfo.phone)"
                    class="badge-label text-center badge-label-accent round pointer ml-auto">1</span>
            </a>
          </li>
          <li class="nav-item mx-0"
              *ngIf="isSuperAdmin && !userMunicipality.sunset">
            <a *ngIf="isSuperAdmin"
               class="nav-link pt-2 px-0 mx-lg-3"
               (click)="openSwitchUserDialog()"
               i18n="Switch user headerItem@@header.switchUser">Switch user</a>
          </li>
          <li class="nav-item order-4"
              *ngIf="!user">
            <a class="nav-link pt-2 px-0 mx-lg-3"
               routerLinkActive="active"
               (click)="loginNavigate()"
               i18n="Log in headerItem@@header.login">Log in</a>
          </li>

          <li class="nav-item mx-0"
              *ngIf="user">
            <a class="nav-link pt-2 px-0 mx-lg-3"
               (click)="onLogout();"
               i18n="Logout headerItem@@header.logout">Logout</a>
          </li>
        </ul>

        <mat-expansion-panel class="mat-elevation-z py-2"
          *ngIf="!user">
          <mat-expansion-panel-header class="px-3">
            <mat-panel-title>
              <span class="nav-link pt-2 px-0 mx-lg-2"
                i18n="Language headerItem@@header.language">Language</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="navbar-nav h-auto m-0">
            <li class="nav-item mx-0 pl-7" (click)="navigateToLang('no')">
              <span class="nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center">Norsk Bokmål</span>
            </li>

            <li class="nav-item mx-0 pl-7" (click)="navigateToLang('nn')">
              <span class="nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center">Nynorsk</span>
            </li>

            <li class="nav-item mx-0 pl-7" (click)="navigateToLang('en')">
              <span class="nav-link pt-2 px-0 mx-lg-3 d-flex align-items-center">English UK</span>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="d-flex align-items-center"
           [ngClass]="{
          'ml-auto': !(isMobile | async)?.matches,
          'w-100': (isMobile | async)?.matches
        }">

        <div class="profile-wrapper ml-4"
             *ngIf="user && !(isMobile | async)?.matches && userInfo">
          <button [matMenuTriggerFor]="menu"
               #overlayTrigger
               class="btn-transparent">
            <img class="profile-image rounded"
                 *ngIf="userInfo && profileImgURL;else blankAvatar"
                 src="{{profileImgURL}}"
                 (error)="imgErrorHandler($event)"
                 alt="Your profile picture"

                 aria-label="Profile Avatar"
                 i18n-aria-label="Profile alternative label@@label.profileAvatar">

            <ng-template #blankAvatar>
              <friskus-blank-avatar [data]="userInfo.name"
                                    size="small">
              </friskus-blank-avatar>
            </ng-template>
          </button>

          <mat-menu #menu="matMenu"
                    xPosition="before">
            <a [routerLink]="['/profile']" *ngIf="!userMunicipality.sunset"
               mat-menu-item
               (click)="handleNavClick()"
               i18n="Profile headerItem@@header.profile">Profile</a>
            <a [routerLink]="['/profile/edit']"
               mat-menu-item
               class="d-flex align-items-center"
               (click)="handleNavClick()"> <span i18n="Settings headerItem@@header.settings">Settings</span>
              <div class="ml-auto">
                <span *ngIf="userInfo && (!userInfo.email || !userInfo.phone)"
                      class="badge-label text-center badge-label-accent round">1</span>
              </div>
            </a>
            <!-- <a [routerLink]="['/chat']"
              mat-menu-item
              (click)="handleNavClick()"
              i18n="Chats headerItem@@header.chats">Chats</a> -->

            <a [routerLink]="['/dashboard']" *ngIf="!userMunicipality.sunset"
               mat-menu-item
               (click)="handleNavClick(false, 'dashboard')"
               i18n="Dashboard headerItem@@header.dashboard">Dashboard</a>
            <a [routerLink]="['/dashboard/admin']"
              *ngIf="isSuperAdmin && !userMunicipality.sunset"
              mat-menu-item
              (click)="handleNavClick()"
              i18n="Requests tab header@@tab.requests">Requests</a>
            <a [href]="backofficeUrl"
                *ngIf="isSuperAdmin && !userMunicipality.sunset"
                mat-menu-item
                i18n="Backoffice headerItem@@link.backoffice">Backoffice</a>
            <a [href]="backofficeUrl"
                *ngIf="showAdminLink()"
                mat-menu-item
                i18n="Admin backoffice link@@link.admin">Admin</a>
            <a mat-menu-item *ngIf="!userMunicipality.sunset"
               [routerLink]="['/organisations', 'new']"
               (click)="handleNavClick()"
               i18n="Create organization headerItem@@header.createOrganization">Create organization</a>
            <a *ngIf="isSuperAdmin && !userMunicipality.sunset"
               mat-menu-item
               (click)="openSwitchUserDialog()"
               i18n="Switch user headerItem@@header.switchUser">Switch user</a>
            <button mat-menu-item
                    (click)="onLogout()"
                    i18n="Logout headerItem@@header.logout">Logout</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="container position-relative" *ngIf="(isMobile | async)?.matches">
    <friskus-check-municipality></friskus-check-municipality>
  </div>
  <friskus-announcement *ngIf="isAnnouncement"></friskus-announcement>
</nav>
