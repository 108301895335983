import {Component, OnInit} from '@angular/core';

declare let Beacon: any;

@Component({
  selector: 'friskus-help-beacon-mobile',
  templateUrl: './help-beacon-mobile.component.html',
  styleUrls: ['./help-beacon-mobile.component.scss']
})
export class HelpBeaconMobileComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

  onOpen() {
    Beacon('toggle');
  }

}
