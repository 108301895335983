import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'friskus-file-badge',
  templateUrl: './file-badge.component.html',
  styleUrls: ['./file-badge.component.scss']
})
export class FileBadgeComponent implements OnInit {
  @Input() document: any;
  @Input() control: UntypedFormControl;

  constructor() { }

  ngOnInit() {
    console.log(this.control);
  }

  public downloadDocument() {
    window.open(this.document.url);
  }

}
