import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const DrammenMunicipality: UserMunicipality = {
  name: 'drammen',
  slogan: {
    value: '',
    id: 'banner.drammen',
    meaning: 'bannerSlogan',
    description: 'slogan for drammen',
  },
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.'
  },
  bannerURL: 'https://ucarecdn.com/b1a8d267-8b51-49ef-9870-a1707bb59071/-/preview/-/quality/smart/' +
    '-/format/auto/-/brightness/-10/-/saturation/-20/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', 'b77d2b20-7a61-459c-b83b-64b720432afa'], title: "Down Under Mjøndalen"},
        {url: ['/organisations', 'fc28a4a3-e669-42d8-90da-cb1653d937a3'], title: "Drammen Kulturskole"},

      ]
    },
    {type: InfoType.FUNDING, url: 'https://www.drammen.kommune.no/tjenester/kunst-kultur/tilskudd/'},
    {
      type: InfoType.VOLUNTEER_CENTERS,
      links: [
        {url: ['/organisations', 'ed4fd56d-e3b0-47d7-96a2-d25b850c253a'], title: "Drammen Frivilligsentral"},
        {url: ['/organisations', '2bcdd1ea-b4a9-46ae-8459-5eaabc2552b7'], title: "Svelvik Frivilligsentral"},
        {url: ['/organisations', '9267fbbf-d20c-484b-8cf7-cab80e8c4e12'], title: "Mjøndalen Frivilligsentral"},
        {url: ['/organisations', 'b4495768-8ec3-4de9-9ac2-d8ea7ddf47a4'], title: "Danvik og Fjell Frivilligsentral"},
        {url: ['/organisations', 'a9ef6eda-9d64-47f6-b30b-cf7f3c9b60cf'], title: "Konnerud Frivilligsentral"},
      ]
    },
    {type: InfoType.VOLUNTEER_WORK, url: 'https://www.drammen.kommune.no/tjenester/frivillighet/'},
    {
      type: InfoType.VENUE,
      url: 'https://site1.aktiv-kommune.no/3005/bookingfrontend/',
    },
  ]
}
