import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const FanaDevMunicipality: UserMunicipality = {
  name: 'fana-dev',
  slogan: {
    value: ' <a class="slogan" href="https://sunnfjord.friskus.com/events?filters=global_filters_municipalities(EQ)1462004f-4ab3-45da-a71d-1e94d0a4e133$$true(AND)events-filterscategories(EQ)4b3931e8-fb87-43ed-864d-0c75f3617973$$false">Konserter, revy, standup og kino</a> <br>  <br> <a class="slogan" href="https://sunnfjord.friskus.com/events?filters=global_filters_municipalities(EQ)1462004f-4ab3-45da-a71d-1e94d0a4e133$$true(AND)events-filterscategories(EQ)87c373bf-626a-4c74-a5c9-70c9bcf653ff$$false,9d02701d-0e5c-4162-baa1-09cc2ddaf5cb$$false">Idrett, Kultur og Friluftsliv </a>', 
    description: 'defaultSlogan',
    logoURL: '',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/d1e89f90-214e-4ecb-b31c-78a06ad8760b/Nordsvannet.jpeg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [

    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 8,
      maxAge: 18,
      url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/friskus/',
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fana',
      minAge: 8,
      maxAge: 18,
      url: 'https://www.tonsberg.kommune.no/tjenester/kultur-idrett-og-fritid/friskus/',
    },
    {
        type: InfoType.FOR_SENIORS,
        links: [
          {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Pensjonistforeningen"},
          {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Senioruniversitetet"},
          {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Den kulturelle spaserstokken"},
               ]
      },
    {
      type: InfoType.TICKETS_CUSTOM,
      title: 'Ønskebrønnen',
      name: 'Fana',
      url: "https://example.com",
      minAge: 8,
      maxAge: 18,
    },
    {
      type: InfoType.VOLUNTEER_WORK,
      links: [
        {title: 'Redningsselskapet RS', url: ['/organisations', '867b21f7-92bd-430e-9a43-4765e2102fe0']},
        {title: 'EL Fatbike Fana', url: ['/organisations', '3dd56cef-e212-4476-a46a-f34e0ba68391']},
      ]
    },

    {type: InfoType.ACTIVITY_GUIDE, url: 'http://example.com'},

    {type: InfoType.EQUIPMENT_RENTAL, url: 'http://example.com'},
  ]
}
