import {UserMunicipality} from "@shared/models/user-municipality.model";

export const AskerDevMunicipality : UserMunicipality = {
  name: 'asker-dev',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.default', meaning: 'bannerSlogan',
    description: 'defaultSlogan',
    logoURL: '',
  },
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  hasPayment: true,
  hasTickets: true,
  sunset: false,
  infoPageTitle: 'Fritidskonto',
}
