import { Component, OnInit, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CertificatesService } from '../../../shared/services/remote-api/certificates.service';
import { SnackbarService } from '@services/snackbar.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StartAtInPastStateMatcher } from '@features/organisations/event-edit/event-edit.validators';
import * as moment from 'moment';
import { dateFormat } from '@config/index';
import { SNACKBAR_TYPE } from '@shared/components/ui-components/snackbar/snackbar.model';
import { Event } from '@shared/models/event.model';
import { StampStatus } from './../../../shared/models/certificate.model';

export interface CertificateDetailsDialogData {
  event?: Event;
  certificate?: any;
  shortMode?: boolean;
  isEdit?: boolean;
  status?: any;
  is_accepted?: boolean;
  id?: string;
  organization?: any;
  forever?: boolean;
  start_at?: string;
  end_at?: string;
}
@Component({
  selector: 'friskus-certificate-details-dialog',
  templateUrl: './certificate-details-dialog.component.html',
  styleUrls: ['./certificate-details-dialog.component.scss']
})
export class CertificateDetailsDialogComponent implements OnInit {
  public isCertificateAccepted: boolean;
  public isLoading: boolean;
  public certificateForm: UntypedFormGroup;
  public minDay = new Date();
  public startAtInPastStateMatcher = new StartAtInPastStateMatcher();
  public stampStatus = StampStatus;
  public isAcceptedByUser: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CertificateDetailsDialogData,
    private dialogRef: MatDialogRef<CertificateDetailsDialogComponent>,
    private ceritficatesService: CertificatesService,
    private snackBarService: SnackbarService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.isCertificateAccepted = this.data.is_accepted;
    if (this.data.isEdit) {
      this.certificateForm = this.initForm(this.data);
    }
  }

  public conditionsChange(event) {
    this.data.is_accepted = event.checked;
    this.isAcceptedByUser = event.checked;
  }

  public onConfirmClick() {
    if (this.data.isEdit) {
      const stampData = {...this.certificateForm.value};
      stampData.start_at = stampData.range?.start_at ? moment(stampData.range.start_at).format(dateFormat.short) : undefined;
      stampData.end_at = stampData.range?.end_at ? moment(stampData.range.end_at).format(dateFormat.short) : undefined;

      const {range, ...postableData} = stampData;
      this.updateStamp(postableData);
    }
    if (this.data.shortMode) {
      this.requestCertificate();
    }

    if (this.data.is_accepted && this.isAcceptedByUser && !this.data.isEdit && !this.data.shortMode) {
      this.acceptStamp();
    } else if (!this.data.isEdit) {
      this.dialogRef.close(false);
    }
  }

  private requestCertificate() {
    this.isLoading = true;
    this.ceritficatesService.requestCertificate(this.data.event.organization.id, this.data.certificate).subscribe(_ => {
      this.snackBarService.notificationSuccess({ type: SNACKBAR_TYPE.CERTIFICATE_REQUESTED });
      this.isLoading = false;
      this.dialogRef.close(true);
    }, err => {
      this.snackBarService.commonNotificationFail(err.message);
      this.isLoading = false;
      this.dialogRef.close(false);
    });
  }

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public get isConfirmButtonShown() {
    const isStatus = !this.data.status ||
      (this.data.status && this.data.status !== this.stampStatus.ACTIVE && this.data.status !== this.stampStatus.REQUESTED);

    return isStatus || this.data.isEdit;
  }

  private acceptStamp() {
    this.isLoading = true;
    this.ceritficatesService.acceptStamp(this.data.id).subscribe(result => {
      this.isLoading = false;
      this.data = result.data;
      this.dialogRef.close(this.data);
    }, err => {
      this.isLoading = false;
      this.snackBarService.commonNotificationFail(err.message);
    });
  }

  private updateStamp(stampData) {
    this.isLoading = true;
    this.ceritficatesService.updateStamp(this.data.id, stampData).subscribe(result => {
      this.data = result.data;
      this.isLoading = false;
      this.dialogRef.close(this.data);
    }, err => {
      this.isLoading = false;
      this.snackBarService.commonNotificationFail(err.message);
    });
  }

  private initForm(stampData) {
    return this.fb.group({
      range: this.fb.group({
        start_at: [stampData.start_at, Validators.required],
        end_at: [stampData.end_at, Validators.required],
      }),
      forever: [stampData.forever]
    });
  }

  public foreverChanged(event: MatCheckboxChange) {
    if (event.checked) {
      (this.certificateForm.controls.range as UntypedFormGroup).controls.start_at.clearValidators();
      (this.certificateForm.controls.range as UntypedFormGroup).controls.end_at.clearValidators();

      (this.certificateForm.controls.range as UntypedFormGroup).controls.start_at.reset();
      (this.certificateForm.controls.range as UntypedFormGroup).controls.end_at.reset();

      (this.certificateForm.controls.range as UntypedFormGroup).controls.start_at.disable();
      (this.certificateForm.controls.range as UntypedFormGroup).controls.end_at.disable();
    } else {
      (this.certificateForm.controls.range as UntypedFormGroup).controls.start_at.enable();
      (this.certificateForm.controls.range as UntypedFormGroup).controls.end_at.enable();

      (this.certificateForm.controls.range as UntypedFormGroup).controls.start_at.setValidators(Validators.required);
      (this.certificateForm.controls.range as UntypedFormGroup).controls.end_at.setValidators(Validators.required);
    }
  }
}
