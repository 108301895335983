import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const LorenskogMunicipality: UserMunicipality = {
  name: 'lorenskog',
  slogan: {
    value: '', id: 'banner.lorenskog', meaning: 'bannerSlogan',
    description: 'slogan for lorenskog'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/9739145a-65d7-4338-9a77-fb30e600733a/-/preview/-/quality/smart/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-lorenskog',
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '901de9ab-fca0-49da-a8c0-f8a01641846d']
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.lorenskog.kommune.no/tjenester/kultur-idrett-og-fritid/tilskudd-priser-og-stipend/tilskudd/',
    },
    {
      type: InfoType.VENUE,
      url: "https://www.lorenskog.kommune.no/tjenester/kultur-idrett-og-fritid/utleie-av-lokaler/",
    }
  ]
}
