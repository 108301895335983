<div mat-dialog-container
  class="d-flex flex-column h-100 position-relative">
  <div class="text-right position-absolute w-100">
    <mat-icon matTooltip="Close"
      class="close-icon pointer"
      i18n-matTooltip="Close tooltip@@tooltip.close"
      (click)="onCloseClick()">close</mat-icon>
  </div>
  <h3 class="text-center mb-2 font-weight-bold"
    mat-dialog-title
    i18n="Select a member to login header@@dialog.selectMemberToLogin">Select a member to login</h3>
  <div mat-dialog-content
    align="start">

    <div class="bg-wrap py-3">
      <mat-form-field appearance="outline" class="w-100 px-3">
        <mat-label i18n="Search members label@@label.searchMembers">Search members</mat-label>
        <input
          placeholder="Search members"
          matInput
          [formControl]="userSearch">
      </mat-form-field>

      <div class="px-3" *ngIf="currentUser">
        <span class="d-block text-label font-size-sm mb-3"
          i18n="Current log in label@@label.currentLogIn">Current log in</span>
        <div class="d-flex align-items-center">
          <img [src]="currentUser.thumbnail_url"
            (error)="userImgErrorHandler(currentUser)"
            alt="User"
            *ngIf="currentUser.thumbnail_url"
            class="member-img mr-3">
          <friskus-blank-avatar [data]="currentUser.name"
            *ngIf="!currentUser.thumbnail_url"
            size="xsmall"
            class="pr-3">
          </friskus-blank-avatar>
          <div>
            <span class="d-block">{{currentUser.name}}</span>
            <span class="text-label">{{currentUser.email}}</span>
          </div>
        </div>
        <hr>
      </div>

      <div class="d-flex" 
        *ngIf="paginator && paginator.items.length">
        <span class="d-block text-label font-size-sm mb-3 mt-3 px-3 w-50"
          i18n="List of members label@@label.listOfMembers">List of members</span>

        <span class="d-block text-label font-size-sm mb-3 mt-3 w-50"
          i18n="Organizations label@@label.organizations">Organizations</span>
      </div>

      <div infiniteScroll
        [scrollWindow]="false"
        [immediateCheck]="true"
        [infiniteScrollDistance]="2"
        (scrolled)="paginator.loadMore()"
        class="users-container">

        <ng-container *ngFor="let user of paginator.items; let i = index;">
          <div class="pointer py-2 px-3 user-item d-flex"
            [ngClass]="{'active': selectedIndex === i}"
            (click)="selectUser(user, i)">
            <div class="w-50 d-flex align-items-center">
              <img [src]="user.thumbnail_url"
                (error)="userImgErrorHandler(null, i)"
                alt="User"
                *ngIf="user.thumbnail_url"
                class="member-img mr-3">
              <friskus-blank-avatar [data]="user.name"
                *ngIf="!user.thumbnail_url"
                size="xsmall"
                class="pr-3">
              </friskus-blank-avatar>
              <div>
                <span class="d-block" [innerHtml]="user.name"></span>
                <span class="text-label">{{user.email || user.phone}}</span>
              </div>
            </div>
            <div class="w-50 d-flex align-items-end">
              <span class="text-label d-block" *ngIf="user.organizations && user.organizations.length < 4">
                <span *ngIf="user.organizations.length <= 3">
                  <ng-container *ngFor="let organization of user.organizations; let i = index;">
                    {{organization.name}}<ng-container *ngIf="user.organizations.length !== i + 1">, </ng-container></ng-container>
                </span>
                <span *ngIf="user.organizations.length > 3"
                  [matTooltip]="getOrganizationTooltip(user.organizations)"
                  matTooltipClass="tooltip-secondary">{{user.organizations.length}} Organizations</span>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="d-flex mt-auto pb-4 pt-3"
    mat-dialog-actions
    align="end">
    <button class="mr-2"
      mat-raised-button
      color="primary"
      (click)="loginAsUser()"
      i18n="Switch user button@@button.switchUser">
      Switch user
    </button>
    <!-- <button mat-raised-button
      class=""
      color="primary"
      (click)="deleteUser()">
      Delete user
    </button> -->
  </div>
</div>