export const VALIDATION_PATTERN = {
  email: [
    '^(([^<>()[\\]\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\.,;:\\s@\\"]+)*)|',
    '(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|',
    '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  ].join(''),
  // eslint-disable-next-line max-len
  fullName: /^([\s]+)?[æøåéèêóòöâôäa-zÆØÅÉÈÊÓÒÂÔÄÖA-Z]([-']?[æøåéèêóòâôöäa-zÆØÅÉÈÊÓÒÂÔÄÖA-Z]+)*( [æøåéèêóòâôöäa-zÆØÅÉÈÊÓÒÂÔÄÖA-Z]([-']?[æøåéèêóòâôöäa-zÆØÅÉÈÊÓÒÂÔÄÖA-Z]+([\s]+)?)*)+$/,
  phoneShort: '[0-9]{8}$',
  phoneNo: '^(0047|\\+47|47)?\\d{8}$',
  phoneLong: '^[0-9\\-\\+\\s]{2,25}$',
  dob: '^(0?[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])([0-9]{2})$',
  timePattern: '^([01]?[0-9]|2[0-3]):[0-5][0-9]$',
  number: '^[0-9]+$',
  zip: '^[0-9]{4}$',
  bankNumber: '([0-9]{4}).([0-9]{2}).([0-9]{5})',
  ssn: '^[0-9]{11}$',
  url: '',
};

export const INPUT_MASK = {
  timeInputMask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
  accountNumber: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/],
  birthDateMask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  verificationCodeMask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
};
