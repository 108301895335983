import { PushNotificationsService } from '@services/remote-api/push-notifications.service';
import { Injectable } from '@angular/core';
import { API_URL_MAP, API_URL_KEY } from '@config/api-url.config';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  private certificatesUrl = API_URL_MAP.certificates;
  private groupsUrl = API_URL_MAP.groups;

  constructor(
    private http: GenericHttpService,
    private pushNotificationsService: PushNotificationsService
  ) { }

  public getCertificates(orgaizationId: string, categoryId?: string, page: number = 1): Observable<any> {
    const url = `${this.groupsUrl.base}/${orgaizationId}${this.certificatesUrl.base}`;
    const searchParams: any = {};
    searchParams.page = page;
    if (categoryId) {
      searchParams.categories = categoryId;
    }
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  public createCertificate(orgaizationId: string, certificateData): Observable<any> {
    const url = `${this.groupsUrl.base}/${orgaizationId}${this.certificatesUrl.base}`;
    return this.http.post(API_URL_KEY.group, url, certificateData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public createGlobalCertificate(certificateData): Observable<any> {
    const url = `${this.groupsUrl.certificates}`;
    return this.http.post(API_URL_KEY.group, url, certificateData);
  }

  public createDriverLicence(certificateData): Observable<any> {
    const url = `${API_URL_MAP.users.me}${this.groupsUrl.certificates}/${certificateData.id}${API_URL_MAP.certificates.stamps}`;
    return this.http.post(API_URL_KEY.group, url, certificateData);
  }

  public getDriverLicence(certivicateId, page: number = 1): Observable<any> {
    const url = `${this.groupsUrl.certificates}?categories=${certivicateId}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public getCetificatesRequests(orgaizationId: string, page: number = 1) {
    const searchParams: any = {};
    searchParams.page = page;
    const url = `${this.groupsUrl.base}/${orgaizationId}${this.certificatesUrl.base}/requests`;
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  public detachGlobalCertificateOrganization(certificateData, organizations): Observable<any> {
    const url = `${this.groupsUrl.certificates}/${certificateData.id}/organizations/detach`;
    return this.http.post(API_URL_KEY.group, url, { organizations }).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public attachGlobalCertificateOrganization(certificateData, organizations): Observable<any> {
    const url = `${this.groupsUrl.certificates}/${certificateData.id}/organizations`;
    return this.http.post(API_URL_KEY.group, url, { organizations }).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public requestCertificate(organizationId, certificateData): Observable<any> {
    const url = `${this.groupsUrl.base}/${organizationId}${this.certificatesUrl.base}/${certificateData.id}${this.certificatesUrl.request}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public getCertificateById(certificateId: string): Observable<any> {
    const url = `${this.certificatesUrl.base}/${certificateId}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public updateCertificate(certificateId: string, certificateData): Observable<any> {
    const url = `${this.certificatesUrl.base}/${certificateId}`;
    return this.http.put(API_URL_KEY.group, url, certificateData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public deleteCertificate(certificateId: string): Observable<any> {
    const url = `${this.certificatesUrl.base}/${certificateId}`;
    return this.http.del(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public getCertificateCategories(belongs = 'organization', global = false) {
    const url = `${API_URL_MAP.certificates.categories}?type=certificate${
      global ? '' : `&belongs=${belongs}`
      }`;
    return this.http.get(API_URL_KEY.group, url);
  }

  // Stamps

  public getListOfStamps(page: number = 1) {
    const url = `${ API_URL_MAP.users.base }${ API_URL_MAP.users.me }${ this.certificatesUrl.stamps }`;
    return this.http.get(API_URL_KEY.group, url, { page });
  }

  public getStampById(stampId: string) {
    const url = `${ this.certificatesUrl.stamps }/${stampId}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public updateStamp(stampId: string, stampData) {
    const url = `${this.certificatesUrl.stamps}/${stampId}`;
    return this.http.put(API_URL_KEY.group, url, stampData);
  }

  public acceptStamp(stampId: string) {
    const url = `${this.certificatesUrl.stamps}/${stampId}/accept`;
    return this.http.post(API_URL_KEY.group, url, {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public deleteStamp(stampId: string) {
    const url = `${this.certificatesUrl.stamps}/${stampId}`;
    return this.http.del(API_URL_KEY.group, url);
  }

  public createNewStamp(organizationId, certificateId: string, stampData) {
    const url = `${this.groupsUrl.base}/${organizationId}${this.certificatesUrl.base}/${certificateId}${this.certificatesUrl.stamps}`;
    return this.http.post(API_URL_KEY.group, url, stampData);
  }
}
