import { Component, OnInit } from '@angular/core';

declare let Beacon: any;

@Component({
  selector: 'friskus-help-beacon',
  templateUrl: './help-beacon.component.html',
  styleUrls: ['./help-beacon.component.scss']
})
export class HelpBeaconComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    Beacon('init', '57c3d272-454b-453e-9b45-ae61b18d5b07');
    Beacon('config', {
      // mode: 'askFirst',
      // color: "#eeeeee",
      hideFABOnMobile: true,
      hideFABLabelOnMobile: true
    });
  }
}
