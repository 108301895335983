import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import {
  ErrorHandler,
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule,
  TRANSLATIONS_FORMAT
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeNorwegian from '@angular/common/locales/nb';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { Store } from '@store';
import { AuthModule } from '@auth/auth.module';
import { PrebootModule } from 'preboot';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { MetaService } from './features/auth/shared/services/meta/meta.service';
import * as moment from 'moment';
import { FriskusHttpClient } from '@services/generic-http/http.helper';
import { GlobalErrorHandlerService } from '@services/global-error-handler/global-error-handler.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import * as Hammer from 'hammerjs';
import { SlideshowSharedModule } from 'projects/slideshow/src/app/app.module';

export declare const require;

registerLocaleData(localeNorwegian, 'nb');

@Injectable({
  providedIn: 'root'
})
export class MyHammerConfig extends HammerGestureConfig {
  overrides: any;
  constructor() {
    super();
    this.overrides = {
      swipe: { direction: Hammer.DIRECTION_ALL },
    } as any;

    this.options =  {
      ...(this.options ?? {}),
      cssProps: {
        ...(this.options?.cssProps ?? {}),
        userSelect: "auto",
        touchSelect: "auto"
      }
    };
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'friskus' }),
    PrebootModule.withConfig({ appRoot: 'friskus-root' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    SlideshowSharedModule.forRoot(),
    AuthModule,
    ScrollingModule,
    GalleryModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    HammerModule,
  ],
  providers: [
    MetaService,
    Store,
    FriskusHttpClient,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xtb' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) locale: string) {
    locale = locale.replace(/-NO/g, "") || "en";
    (window as any).UPLOADCARE_LOCALE = locale;
    moment.locale(locale);
    this.setupUploadcareTranslations();
  }

  public setupUploadcareTranslations() {
    (window as any).UPLOADCARE_LOCALE_TRANSLATIONS = {
      uploading: $localize `:Info message|@@uploadcare.uploading:Lastar opp... Ver vennleg og vent.`,
      loadingInfo: $localize `:Info message|@@uploadcare.loadingInfo:Lastar inn info...`,
      errors: {
        default: $localize `:Info message|@@uploadcare.error.default:Feil`,
        baddata: $localize `:Error message|@@uploadcare.error.baddata:Ugyldig verdi`,
        size: $localize `:Error message|@@uploadcare.error.size:Fila er for stor`,
          upload: $localize `:Error message|@@uploadcare.error.upload:Kan ikkje laste opp`,
        user: $localize `:Error message|@@uploadcare.error.user:Opplasting avbrote`,
        info: $localize `:Error message|@@uploadcare.error.Error:Kan ikkje laste inn info`,
        image: $localize `:Error message|@@uploadcare.error.image:Berre bilete er tillatne`,
        createGroup: $localize `:Error message|@@uploadcare.error.createGroup:Kan ikkje opprette filgruppe`,
        deleted: $localize `:Error message|@@uploadcare.error.deleted:Fila er sletta`,
        dimensions: $localize `:Error message|@@uploadcare.error.dimensions:Ugyldige dimensjonar`,
      },
      draghere: $localize `:Instruction|@@uploadcare.draghere:Dra ei fil hit`,
      file: {
        one: $localize `:Label|@@uploadcare.file.one:%1 fil`,
        other: $localize `:Label|@@uploadcare.file.other:%1 filer`,
      },
      buttons: {
        cancel: $localize `:Label|@@uploadcare.buttons.cancel:Avbryt`,
        remove: $localize `:Label|@@uploadcare.buttons.emove:Fjern`,
        choose: {
          files: {
            one: $localize `:Label|@@uploadcare.buttons.choose.files.one:Veg ei fil`,
            other: $localize `:Label|@@uploadcare.buttons.choose.files.other:Vel filer`,
          },
          images: {
            one: $localize `:Label|@@uploadcare.buttons.choose.images.one:Vel eit bilete`,
            other: $localize `:Label|@@uploadcare.buttons.choose.images.other:Vel bilete`,
          },
        },
      },
      dialog: {
        done: $localize `:Label|@@uploadcare.dialog.done:Ferdig`,
        showFiles: $localize `:Label|@@uploadcare.dialog.showFiles:Vis filer`,
        tabs: {
          names: {
            preview: $localize `:Label|@@uploadcare.dialog.tabs.names.preview:Forhåndsvising`,
            file: $localize `:Label|@@uploadcare.dialog.tabs.names.file:Lokale filer`,
            url: $localize `:Label|@@uploadcare.dialog.tabs.names.url:Direktelink`,
          },
          file: {
            drag: $localize `:Instruction|@@uploadcare.dialog.tabs.file.drag:Dra og slipp ei fil her`,
            nodrop: $localize `:Instruction|@@uploadcare.dialog.tabs.file.nodrop:Last opp filer frå datamaskina`,
            cloudsTip: $localize `:Instruction|@@uploadcare.dialog.tabs.file.cloudsTip:Skylagring og sosiale tenester`,
            or: $localize `:Label|@@uploadcare.dialog.tabs.file.or:eller`,
            button: $localize `:Label|@@uploadcare.dialog.tabs.file.button:Vel ei lokal fil`,
            also: $localize `:Instruction|@@uploadcare.dialog.tabs.file.also:Du kan òg velje det frå`,
          },
          url: {
            title: $localize `:Instruction|@@uploadcare.dialog.tabs.url.title:Filer frå internett`,
            line1: $localize `:Instruction|@@uploadcare.dialog.tabs.url.line1:Vel kva som helst fil frå internett.`,
            line2: $localize `:Instruction|@@uploadcare.dialog.tabs.url.line2:Berre gi oss linken.`,
            input: $localize `:Label|@@uploadcare.dialog.tabs.url.input:Lim inn linken her...`,
            button: $localize `:Label|@@uploadcare.dialog.tabs.url.button:Last opp`,
          },
          preview: {
            unknownName: $localize `:Label|@@uploadcare.dialog.tabs.preview.unknownName:ukjent`,
            change: $localize `:Label|@@uploadcare.dialog.tabs.preview.change:Avbryt`,
            back: $localize `:Label|@@uploadcare.dialog.tabs.preview.back:Tilbake`,
            done: $localize `:Label|@@uploadcare.dialog.tabs.preview.done:Legg til`,
            unknown: {
              title: $localize `:Info message|@@uploadcare.dialog.tabs.preview.unknown.title:Lastar opp... Ver vennleg og vent på førehandsvisning.`,
              done: $localize `:Instruction|@@uploadcare.dialog.tabs.preview.unknown.done:Hopp over førehandsvisning og godkjenn`,
            },
            regular: {
              title: $localize `:Instruction|@@uploadcare.dialog.tabs.preview.regular.title:Legge til denne fila?`,
              line1: $localize `:Info message|@@uploadcare.dialog.tabs.preview.regular.line1:Fila blir no lagd til.`,
              line2: $localize `:Instruction|@@uploadcare.dialog.tabs.preview.regular.line2:Ver vennleg og bekreft.`,
            },
            image: {
              title: $localize `:Instruction|@@uploadcare.dialog.tabs.preview.image.title:Legge til dette bilete?`,
              change: $localize `:Label|@@uploadcare.dialog.tabs.preview.image.change:Avbryt`,
            },
            crop: {
              title: $localize `:Label|@@uploadcare.dialog.tabs.preview.crop.title:Kutt og legg til dette bilete`,
              done: $localize `:Label|@@uploadcare.dialog.tabs.preview.crop.done:Ferdig`,
              free: $localize `:Label|@@uploadcare.dialog.tabs.preview.crop.free:frigjer`,
            },
            error: {
              default: {
                title: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.default.title:Ops!`,
                text: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.default.text:Noko gjekk gale under opplastinga.`,
                back: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.default.back:Ver vennleg og prøv igjen`,
              },
              image: {
                title: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.image.title:Berre bilete er aksepterte.`,
                text: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.image.text:Prøv igjen med ei anna fil.`,
                back: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.image.back:Vel bilete`,
              },
              size: {
                title: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.size.title:Den valde fila overskrid tilatt storleik.`,
                text: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.size.text:Ver vennleg og prøv igjen med ei anna fil`,
              },
              loadImage: {
                title: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.loadImage.title:Feil`,
                text: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.loadImage.text:Kan ikkje laste bilete`,
              },
              dimensions: {
                title: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.dimensions.title:Bilete har for låg oppløysing`,
                  text: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.dimensionstext:Forsøk igjen med eit høgare oppløyst bilete.`,
                back: $localize `:Error message|@@uploadcare.dialog.tabs.preview.error.dimensions.back:Tilbake`,
              },
            },
            multiple: {
              title: $localize `:Info message|@@uploadcare.dialog.tabs.preview.multiple.title:Du har valt %files%`,
              question: $localize `:Instruction|@@uploadcare.dialog.tabs.preview.multiple.question:Ynskjer du å legge til alle filene?`,
              tooManyFiles: $localize `:Error message|@@uploadcare.dialog.tabs.preview.multiple.tooManyFiles:Du har valt for mange filer. %max% er maksimum.`,
                tooFewFiles: $localize `:Error message|@@uploadcare.dialog.tabs.preview.multiple.tooFewFiles:Du har valt %files%. Minimum %min% er påkravd.`,
              clear: $localize `:Label|@@uploadcare.dialog.tabs.preview.multiple.clear:Fjern alle`,
              done: $localize `:Label|@@uploadcare.dialog.tabs.preview.multiple.done:Ferdig`,
            },
          },
        },
      },
    };
  }
}
