import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const ArendalVisMunicipality: UserMunicipality = {
  name: 'arendal-vis',
  slogan: {
    value: 'Opplevelser nær deg.',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/d76bc1c8-df44-4727-ac47-4dfc84e9f672/banner_main_updated.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  hasPayment: false,
  hasTickets: true,
  sunset: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fritidskonto',
      url: 'https://www.oslo.kommune.no/tilskudd-legater-og-stipend/digitalt-kulturkort',
    },
  ]
}
