export interface ConfirmationDialogData {
  type: DIALOG_TYPE;
  organizationName?: any;
}

export enum DIALOG_TYPE {
  DELETE_PHOTO = 'DELETE_PHOTO',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  DELETE_EVENT = 'DELETE_EVENT',
  CANCEL_EVENT = 'CANCEL_EVENT',
  UNCANCEL_EVENT = 'UNCANCEL_EVENT',
  SAVE_EVENT_DRAFT = 'SAVE_EVENT_DRAFT',
  ORGANIZATION_INVITATION = 'ORGANIZATION_INVITATION',
  LEAVE_PAGE = 'LEAVE_PAGE',
  LEAVE_EVENT = 'LEAVE_EVENT',
  LEAVE_ORGANIZATION = 'LEAVE_ORGANIZATION',
  CANCEL_ORGANIZATION_REQUEST = 'CANCEL_ORGANIZATION_REQUEST',
  DELETE_THREAD = 'DELETE_THREAD',
  DELETE_CERTIFICATE = 'DELETE_CERTIFICATE',
  DELETE_POST = 'DELETE_POST',
  CHANGE_EVENT_PERIOD = 'CHANGE_EVENT_PERIOD',
  REMOVE_CULTURE_CARD = 'REMOVE_CULTURE_CARD',
  REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT',
  DELETE_USER = 'DELETE_USER',
  CONDITIONS_ACCEPT = 'CONDITIONS_ACCEPT',
  MARK_AS_COMPLETE = 'MARK_AS_COMPLETE',
  NOTIFY_USERS = 'NOTIFY_USERS',
  DELETE_COMMENT = 'DELETE_COMMENT',
}
