<div [formGroup]="parentForm">
  <mat-form-field appearance="outline"
    class="w-100">
    <mat-label i18n="Search organizations label@@label.searchOrganizations">Search organizations</mat-label>
    <input matInput
      [formControl]="organizationSearch"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-selection-list #orgSelectionList
        (selectionChange)="organizationSelected($event)"
        multiple>
        <mat-list-option *ngFor="let organization of filteredOrganizationsList | async; let i = index;"
          color="primary"
          [checkboxPosition]="'before'"
          [selected]="isOrganizationSelected(organization)"
          [value]="organization">
          <span [innerHtml]="organization.name"></span>
        </mat-list-option>
        <mat-option [style.display]="'none'"></mat-option>
      </mat-selection-list>
    </mat-autocomplete>
    <mat-icon matSuffix
      fontSet="material-icons-outlined"
      class="material-icons-round">arrow_drop_down</mat-icon>
  </mat-form-field>
</div>


<div>
  <div *ngFor="let organization of selectedOrganizations; let i = index;"
    class="d-flex align-items-center pl-2">
    <p [innerHTML]="organization?.name"
      class="my-2"></p>
    <button mat-icon-button
      class="primary-icon-button ml-auto"
      (click)="removeSelectedOrganization(i)">
      <mat-icon fontSet="material-icons-outlined"
        class="material-icons-round ml-auto pointer">delete</mat-icon>
    </button>
  </div>
</div>