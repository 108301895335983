import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';

import {AuthService} from '../services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.authState.pipe(
      map(user => {
        if (!user) {
          this.router.navigate(['/auth/get-started'], { queryParams: { returnUrl: state.url } });
        }
        return !!user;
      })
    );
  }
}
