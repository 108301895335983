import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

export type SortDirection = "none" | "asc" | "desc";

@Component({
  selector: "friskus-sort-az",
  templateUrl: "./sort-az.component.html",
  styleUrls: ["./sort-az.component.scss"]
})
export class SortAZComponent implements OnChanges {
  @Input()
  public sortDirection: SortDirection = "none";
  @Output()
  public toggleSortDirection = new EventEmitter<SortDirection>();

  public isOpen = false;
  public label = "Sort";

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const direction = changes.sortDirection.currentValue;
    switch(direction)
    {
      case "asc":
        this.label = $localize `:Alphabetically ascending sort option|@@option.alphabeticAsc:A-Z`;
        break;
      case "desc":
        this.label = $localize `:Alphabetically descending sort option|@@option.alphabeticDesc:Z-A`;
        break;
      default:
        this.label = $localize `:Label sort|@@label.sort:Sort`;
        break;
    }
  }

  public toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  public onClick(direction: "asc" | "desc") {
    this.toggleSortDirection.emit(direction);
    this.isOpen = false;
  }

  public onClear() {
    this.toggleSortDirection.emit("none");
    this.isOpen = false;
  }
}
