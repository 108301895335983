import {UserMunicipality} from "@shared/models/user-municipality.model";

export const LusterMunicipality: UserMunicipality = {
  name: 'luster',
  slogan: {value: '', id: 'banner.luster', meaning: 'bannerSlogan', description: 'slogan for luster'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
