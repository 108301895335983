import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SulaMunicipality: UserMunicipality = {
  name: 'sula',
  slogan: {
    value: 'AKTIVITETAR OG FRIVILLIGE OPPDRAG – NÆR DEG',
    id: 'banner.sula',
    meaning: 'bannerSlogan',
    description: 'slogan for sula'
  },
  data: {
    headerDescription: '',
    amount: 0,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/414158a5-5c10-41d5-a5d6-dac730c54dc5/sulafriskus.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.LEISURECARD_CUSTOM,
      title: 'Kontantkassa',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.sula.kommune.no/tenester/kultur-idrett-og-fritid/aktivitetstilskot-inkludering-i-organisert-fritidsaktivitet/',
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', 'dbd2fcfc-bda8-4f67-b703-496a941afd64'], title: "Donken Fritidsklubb"},
        {url: ['/organisations', '088b38ac-a6cc-4a0c-8537-4ec7084f2167'], title: "Sula ungdomsråd"},
        {url: ['/organisations', '3ab030de-ab33-472d-83f3-fb98b479dcbf'], title: "Fiskarstrand Idrettslag"},
      ]
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.sula.kommune.no/tenester/kultur-idrett-og-fritid/tilskot-stipend-og-kulturpris/'
    },
  ]
}
