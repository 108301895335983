import { Component, OnInit } from "@angular/core";
import { MunicipalitiesService } from "@services/remote-api/municipalities.service";
import { LeisureCardService } from "@services/remote-api/leisure-card.service";
import { LeisureCard } from "@shared/models/leisure-card.model";
import { ChildActivationStart } from "@angular/router";

@Component({
  selector: "friskus-tickets-marketplace-link",
  templateUrl: "./tickets-marketplace-link.component.html",
  styleUrls: ["./tickets-marketplace-link.component.scss"]
})
export class TicketsMarketplaceLinkComponent implements OnInit {
  public municipalityName: string;
  public municipalityEquipment: boolean;
  public municipalityTickets: boolean;
  public leisureCards: LeisureCard[];
  public hasAccessToTicketsOrEquipment: boolean;


  constructor(
    private municipalitiesService: MunicipalitiesService,
    private leisureCardService: LeisureCardService
  
  ) {}

  ngOnInit() {
    this.municipalitiesService.municipalities.subscribe(municipalities => {
      const municipality = municipalities.data.find(x => x.key === this.municipalitiesService.getUserMunicipality().name);
      this.municipalityName = municipality.name;
    });

    this.municipalityTickets = this.municipalitiesService.isTicketEnabled ? true : false;
    this.municipalityEquipment = this.municipalitiesService.isEquipmentTicketEnabled ? true : false;

    this.leisureCardService.getUserCards().subscribe((response: {data: LeisureCard[]}) => {
      this.leisureCards = this.leisureCards = response.data;
      this.hasAccessToTicketsOrEquipment = this.leisureCards.find(c => c.tickets || c.equipment) ? true : false;
    });
  }

}
