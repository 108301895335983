<input
  [formControl]="address"
  matInput
  autocorrect="off"
  autocapitalize="off"
  spellcheck="off"
  type="text"
  class="form-control"
  #search
  >

  <div class="form-text error" *ngIf="isAddressInvalid">
    <div> The address you entered is not valid! Try to insert some part of it and choose apropriate option from dropdown</div>
  </div>
