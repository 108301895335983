import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const GjesdalMunicipality: UserMunicipality = {
  name: 'gjesdal',
  slogan: {value: '', id: 'banner.gjesdal', meaning: 'bannerSlogan', description: 'slogan for gjesdal'},
  data: {},
  bannerURL: 'https://ucarecdn.com/04c069a4-a900-49bf-8834-f98f5f99696e/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      url: 'https://www.gjesdal.kommune.no/tjenester/kultur-og-fritid/idrett-og-friluftsliv/lan-av-friluftsutstyr/'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.gjesdal.kommune.no/tjenester/kultur-og-fritid/tilskudd-priser-og-stipend/'
    },
    {
      type: InfoType.VENUE,
      url: 'https://www.gjesdal.kommune.no/bookup/',
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '059fbf3a-bbee-4fcb-adb5-9f113376f0cc']
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', '08d0c29f-599f-4138-9700-bc35f81b4c9e'], title: "Ungdomshuset Veveriet"},
        {url: 'https://www.minskole.no/gjesdalk', title: "Kulturskolen"},
        {url: 'https://www.gjesdal.kommune.no/fokusnyhet/digg-sommer-2022.528937.aspx', title: "Digg Sommer"},
      ]
    },
  ]
}
