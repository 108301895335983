import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const ArendalDevMunicipality : UserMunicipality = {
  name: 'arendal-dev',
  slogan: {},
  data: {},
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  hasPayment: true,
  hasTickets: true,
  sunset: true,
  infoSlots: [
    {
      type: InfoType.VENUE,
      url: "https://example.com",
    },
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 18,
      amount: 2000,
      url: 'http://example.com',
    },
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskort+',
      minAge: 6,
      maxAge: 18,
      url: 'http://example.com',
    },
    {
      type: InfoType.LEISURECARD_CUSTOM,
      title: 'Kontantkassen',
      minAge: 6,
      maxAge: 18,
      url: 'http://example.com',
    },
    {type: InfoType.ACTIVITY_GUIDE, url: 'http://example.com'},
    {type: InfoType.EQUIPMENT_RENTAL, name: 'Utlånssentralen', url: 'http://example.com'},
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Fritidsklubb"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Ungdomshuset"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Kulturskolen"},
      ]
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Pensjonistforeningen"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Senioruniversitetet"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Den kulturelle spaserstokken"},
      ]
    },
    {type: InfoType.VOLUNTEER_WORK, url: 'http://example.com'},
    {type: InfoType.FUNDING, url: 'http://example.com'},
    {
      type: InfoType.TICKETS_CUSTOM,
      title: "Aktivitetskortet",
      url: "https://example.com",
      minAge: 3,
      maxAge: 25,
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      minAge: 5,
      maxAge: 20,
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b']
    },
    {
      type: InfoType.VOLUNTEER_CENTERS,
      links: [
        {url: 'http://example.com', title: 'Arendal Kommune'},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Lillebu Frivilligsentral"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Storebu Frivilligsentral"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Mellombu Frivilligsentral"},
      ]
    }
  ],
}
