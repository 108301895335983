import {UserMunicipality} from "@shared/models/user-municipality.model";

export const SokndalMunicipality: UserMunicipality = {
  name: 'sokndal',
  slogan: {
    value: 'AKTIVITETER OG FRIVILLIGHET NÆR DEG',
    id: 'banner.sokndal',
    meaning: 'bannerSlogan',
    description: 'slogan for sokndal'
  },
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.'
  },
  bannerURL: 'https://ucarecdn.com/2faa5aa9-e0c9-46ae-abba-c383a32fe946/' +
    '-/preview/-/enhance/-/brightness/-20/-/contrast/-10/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
}
