export class TreeItemNode {
  children: TreeItemNode[];
  item: any;
  disabled: boolean;
}

export class TreeItemFlatNode {
  item: any;
  level: number;
  expandable: boolean;
}
