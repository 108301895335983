import {InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const BydelfrognerDevMunicipality: UserMunicipality = {
  name: 'bydelfrogner-dev',
  slogan: {},
  data: {
    minAge: 6,
    maxAge: 18
  },
  bannerURL: 'https://ucarecdn.com/9c526174-7a6b-431f-861c-4727363e6dc6/bydelfrognercoverphoto.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: true,
  infoPageTitle: 'Kulturkort',
  infoSlots: [
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fritidskonto',
      title: 'Gratisbilletter/Kulturkort',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.oslo.kommune.no/tilskudd-legater-og-stipend/tilskudd-til-fritidsaktiviteter-i-bydel-frogner/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'Skattkammeret',
      name: 'Skattkammeret',
      url: 'https://kirkensbymisjon.no/skattkammeret/frogner/'
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.oslo.kommune.no/tilskudd-legater-og-stipend/#f_bydel_frogner=true&offset=0'
    }
  ]
}
