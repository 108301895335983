export const TABS = {
  info: {
    number: 0,
    label: 'Info'
  },
  settings: {
    number: 1,
    label: 'Settings'
  },
  tagging: {
    number: 2,
    label: 'Tagging'
  },
  media: {
    number: 3,
    label: 'Media'
  }
};

export const VALIDATION = {
  NAME_MAX: 200,
  DESCRIPTION_MAX: 500
};

export const EVENT_FREQUENCY = {
  ONCE: 1,
  DAILY: 2,
  WEEKLY: 3,
  CUSTOM: 4
};

export enum EVENT_DAYS {
  MO = 0,
  TU = 1,
  WE = 2,
  TH = 3,
  FR = 4,
  SA = 5,
  SU = 6
}

export const MAX_EVENT_OCCURRENCES = 300;

export const EVENT_TYPE = {
  open: 'open',
  scheduled: 'scheduled'
};

export const IMG_MIN_DIMENSIONS = {
  HEIGHT: 200,
  WIDTH: 400
};
