<ng-container>
  <div class="not-found">
    <div class="not-found-hint">
      <h3 class="font-weight-bold"
        i18n="This is private organization header@@privateOrganization">This is private organization</h3>
      <p class="mt-1"
        i18n="You don't have permission  hint@@privateOrganizationHint">You don't have permission to view, check public organizations
      </p>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button mat-stroked-button
      color="primary"
      type="button"
      [routerLink]="['/organisations']"
      i18n="Organizations button@@button.organizations">Organizations</button>
  </div>
</ng-container>