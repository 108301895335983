import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const AlesundMunicipality: UserMunicipality = {
  name: 'alesund',
  slogan: {value: '', id: 'banner.alesund', meaning: 'bannerSlogan', description: 'slogan for alesund'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
   {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Billetter',
      minAge: 6,
      maxAge: 18,
      url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/fritid/stotte-til-kultur-og-fritidsaktivitetar',
     },
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      minAge: 0,
      maxAge: 24,
    },
   {
      type: InfoType.VOLUNTEER_WORK,
      url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/frivilligheit/bli-frivillig/',
    },
    {
      type: InfoType.VOLUNTEER_CENTERS,
      links: [
        {url: ['/organisations', '6edc1f03-59e2-47a1-bb3b-1666022a5622'], title: "Ålesund Frivilligsentral"},
        {url: ['/organisations', '2eff2359-1a8f-4597-8f83-532c57e5b339'], title: "Ørskog Frivilligsentral"},
        {url: ['/organisations', 'da37f76e-d270-44fb-b469-1f09e4863f96'], title: "Skodje Frivilligsentral"},
        {url: ['/organisations', '3d59e880-c75a-464b-a9e0-1dba8ee73b01'], title: "Sandøy Frivilligsentral"},
        {url: ['/organisations', '3084d6ca-0a2a-45e9-927d-282b693c8ce9'], title: "Haram Frivilligsentral"}
      ]
    },
    {
      type: InfoType.FUNDING,
      url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/',
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {
          url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/kultur-og-fritid/senior-i-alesund/den-digitale-senior/',
          title: "Fole kjekt å vere sosial og digital"
        },
        {
          url: 'https://alesund.kommune.no/helse-og-omsorg/dagtilbod-og-aktivitetar/sterk-og-stodig-trening-for-seniorar/',
          title: "Sterk og Stødig"
        },
        {
          url: 'https://alesund.kommune.no/helse-og-omsorg/dagtilbod-og-aktivitetar/seniorsenter-og-dagtilbod-for-eldre/',
          title: "Oversikt over aktivitetar seniorsenter / møteplassar"
        },
      ]
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {
          url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/kultur-og-fritid/ung-i-alesund/',
          title: "Ung i Ålesund"
        },
        {url: 'https://alesundkulturskole.no/undervisningstilbod/', title: "Ålesund kulturskule"},
      ]
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'Lån av gratis utstyr',
      name: 'Ålesund kommune',
      url: 'https://alesund.kommune.no/kultur-idrett-og-fritid/friluftsliv-og-natur/fritidsutstyr-til-utlan/'
    },
  ]
}
