import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/ui-components/snackbar/snackbar.component';
import { SNACKBAR_TYPE } from '@shared/components/ui-components/snackbar/snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  public configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 8 * 1000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  };
  public configWarning: MatSnackBarConfig = {
    panelClass: 'style-warning',
    duration: 8 * 1000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  };

  constructor(
    private snackBar: MatSnackBar,
    ) { }

  public notificationSuccess(snackBarData: { type: string, message?: string, header?: string }) {

    this.snackBar.openFromComponent(SnackbarComponent, {
      data: snackBarData,
      ...this.configSuccess
    });
  }

  public notificationFail(snackBarData: { type: string, message?: string, header?: string }) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: snackBarData,
      ...this.configWarning
    });
  }

  public commonNotificationFail(errMessage?: string) {
    const snackBarData = {
      type: SNACKBAR_TYPE.CUSTOM,
      message: errMessage || $localize `:Error message|@@error.smthWentWrong:Something went wrong`,
      header: $localize `:Error header|@@error.errorHeader:Error`
    };
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: snackBarData,
      ...this.configWarning
    });
  }
}
