import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const KvamMunicipality: UserMunicipality = {
  name: 'kvam',
  slogan: {
    value: `Hent ditt <a class="slogan" href="/info/about/fritidskonto">Fritidskort</a> her`,
    id: 'banner.kvam',
    meaning: 'bannerSlogan',
    description: 'slogan for kvam'
  },
  data: {
    headerDescription: 'Dette er et tilbud til deg som er mellom 6 til 18 år. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering. <br><br>' +
      ' Følg veiledningen under. For mer informasjon se: <a  class="link text-white text-decoration-underline"' +
      ' href="https://www.kvam.no/tenester/kultur-idrett-og-fritid/fritidskort/"' +
      ' >egen informasjonsside</a>.<br><br>' +
      ' Ved spørsmål ta kontakt på <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:krisve@kvam.kommune.no">krisve@kvam.kommune.no</a> / 900 58 018',
    amount: 1000,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/d76bc1c8-df44-4727-ac47-4dfc84e9f672/banner_main_updated.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskort',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 18,
      amount: 2000,
      url: 'https://www.kvam.no/fritid/fritidskortet/',
    },
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet Pluss',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.kvam.no/fritid/fritidskortet/',
    },
  ]
}
