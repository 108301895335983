import {
  DateRangeFilterValue,
  DistanceFromFilterValue,
  Filter,
  FilterConfig,
  isDateRangeFilter,
  isDistanceFromFilter,
  isMultiSelectFilter,
  isSingleSelectFilter,
  MultiSelectFilter,
  MultiSelectFilterValue,
  SelectFilterValue,
} from "./filters.types";

export function calcFilterUniqueId(filter: FilterConfig) {
  return `${filter.filterGroupId}-${filter.filterId}-${filter.type}`;
}

export function isFiltersEqual(a: Filter<any>, b: Filter<any>) {
  if (a.type !== b.type || calcFilterUniqueId(a) !== calcFilterUniqueId(b))
    return false;

  if (isMultiSelectFilter(a) && isMultiSelectFilter(b))
    return isMultiSelectFiltersEqual(a, b);
  else if (isSingleSelectFilter(a) && isSingleSelectFilter(b))
    return isSingleSelectFiltersEqual(a, b);
  else if (isDateRangeFilter(a) && isDateRangeFilter(b))
    return isDateRangeFiltersEqual(a, b);
  else if (isDistanceFromFilter(a) && isDistanceFromFilter(b))
    return isDistanceFromFiltersEqual(a, b);
  else return false;
}

export function isSingleSelectFiltersEqual(
  a: Filter<SelectFilterValue<any>>,
  b: Filter<SelectFilterValue<any>>
) {
  if (calcFilterUniqueId(a) !== calcFilterUniqueId(b)) return false;

  return a.filterValue.value.value === b.filterValue.value.value;
}

export function isDateRangeFiltersEqual(
  a: Filter<DateRangeFilterValue>,
  b: Filter<DateRangeFilterValue>
) {
  if (calcFilterUniqueId(a) !== calcFilterUniqueId(b)) return false;

  return (
    a.filterValue.from.getTime() === b.filterValue.from.getTime() &&
    a.filterValue.to.getTime() === b.filterValue.to.getTime()
  );
}

export function isDistanceFromFiltersEqual(
  a: Filter<DistanceFromFilterValue>,
  b: Filter<DistanceFromFilterValue>
) {
  if (calcFilterUniqueId(a) !== calcFilterUniqueId(b)) return false;

  return (
    a.filterValue.location.address === b.filterValue.location.address &&
    a.filterValue.location.latitude === b.filterValue.location.latitude &&
    a.filterValue.location.longitude === b.filterValue.location.longitude &&
    a.filterValue.radius === b.filterValue.radius
  );
}

export function isMultiSelectFiltersEqual(
  a: MultiSelectFilter<MultiSelectFilterValue<string>>,
  b: MultiSelectFilter<MultiSelectFilterValue<string>>
) {
  if (calcFilterUniqueId(a) !== calcFilterUniqueId(b)) return false;

  const createHash = (filter: MultiSelectFilter<MultiSelectFilterValue<string>>) => filter.filterValue.value.sort((a, b) => a.value > b.value ? 1 : -1).reduce((acc, cur) => {
    acc += cur.value;
    if (cur.subOptions) {
      acc += "#" + cur.subOptions.sort((a, b) => a.value > b.value ? 1 : -1).reduce((acc, cur) => acc + cur.value, "")
    }
    acc += ";";
    return acc;
  }, "");

  const hash1 = createHash(a);
  const hash2 = createHash(b);

  return hash1 === hash2;
}

