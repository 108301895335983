import { Injectable, Inject, PLATFORM_ID, Injector, Optional, InjectionToken } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpHeaders, HttpParams, HttpClient, HttpHandler, HttpRequest, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface IHttpOptions {
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext; 
  observe?: "body"; 
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'; // responseType: "text";
  withCredentials?: boolean;
};

@Injectable()
export class FriskusHttpClient extends HttpClient {

  constructor(
    handler: HttpHandler,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super(handler);
  }

  // `first` is either method or httprequest
  // overwrites `request()` from `HttpClient`
  request(first: string | HttpRequest<any>, url?: string, options: any = {}): Observable<any> {
    // ensures headers properties are not null
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = new HttpHeaders();
    }
    if (typeof first !== 'string' && !first.headers) {
      first = (first as HttpRequest<any>).clone({ headers: new HttpHeaders() });
    }

    // xhr withCredentials flag
    if (typeof first !== 'string') {
      first = (first as HttpRequest<any>).clone({
        withCredentials: true,
      });
    }

    options.withCredentials = true;

    return super.request(first as (any), url, options);
  }

}
