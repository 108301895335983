import { Ticket, TicketGroup } from '@shared/models/ticket.model';
import { Subject } from 'rxjs';

export class InfiniteScrollPaginator {
  items = [];
  equipments = [];
  loading = false;
  meta: any = {
    total: 0,
    last_page: 0,
    page: 1
  };
  loaded: Subject<boolean> = new Subject();
  callback: (page: number) => any;
  subscription;

  constructor(callback: (page: number) => any) {
    this.callback = callback;
  }

  loadMore() {
    if (this.isLoading()) {
      return;
    }
    if (this.meta.page === this.meta.last_page) {
      return;
    }
    this.meta.page += 1;

    this.getItems();
  }

  getItems() {
    this.loading = true;
    this.subscription = this.callback(this.meta.page)
      .subscribe((r: any) => {
        r.data.forEach((obj) => {
          // Check to see if it is a ticket and then divide it into a equipment array of tickets, should be reworked.
            if(obj.equipment && isTicketType(obj)) {
              return this.equipments.push(obj);
            } else {
              return this.items.push(obj);
            }
        });
        this.meta = r.meta;
        this.loaded.next(true);
        this.loading = false;
        
      }, _ => {
        this.loading = false;
      });
  }

  destroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isLoading() {
    return this.loading;
  }

  isEmpty() {
    return !this.isLoading() && this.items.length === 0;
  }

  refresh(callback?: (page: number) => any) {
    this.destroy();

    if (callback) {
      this.callback = callback;
    }

    this.items = [];
    this.equipments = [];
    this.meta.page = 1;
    this.getItems();
  }
}

function isTicketType(obj: any): obj is TicketGroup | Ticket {
  return typeof obj === 'object' && obj !== null &&
  'provider_name' in obj && 
  'closes_at' in obj;
}