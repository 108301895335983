  <div class="text-right notifications-container" #overlayTrigger>
    <button mat-icon-button
      aria-label="Notifications number"
      i18n-aria-label="Notifications number icon@@label.notificationsNumber"
      tabindex="0"
      (click)="toggle($event)">
      <mat-icon class="text-white bell pointer" aria-hidden="true"
        [ngClass]="{'bell': runAnimation}">notifications_active</mat-icon>

      <span class="badge-label text-center badge-label-accent round pointer"
            *ngIf="currentNotificationsLength">{{currentNotificationsLength}}</span>
    </button>
  </div>
