import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpUserEvent} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import {AuthService} from '../auth/auth.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const ticket = this.authService.getTicket();
    if (ticket) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ticket.access_token}`
        }
      });

      const isReadonlyMode = this.authService.parseJwt(ticket.access_token).mode === 'readonly';
      const isLoginRequest = request.method === 'POST' && request.url.includes('login');
      
      if (isReadonlyMode && request.method !== 'GET' ) {
        if (isLoginRequest) {
          return next.handle(request);
        }

        throw(new HttpErrorResponse(
          { status: 405,
            statusText: 'You are not allowed to make this actions in readonly mode',
            error: { message: 'You are not allowed to make this actions in readonly mode'},
            url: request.url
          }
        ));
      }
    }
    
    return next.handle(request);
  }
}
