import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'friskus-aspect-ratio-image',
  templateUrl: './aspect-ratio-image.component.html',
  styleUrls: ['./aspect-ratio-image.component.scss']
})
export class AspectRatioImageComponent implements AfterViewChecked {

  @Input() public src: string;
  @Input() public alt: string = "";
  @Input() public aspectRatio?: number;

  @ViewChild("aspectElement") private aspectElement: ElementRef<HTMLDivElement>;

  ngAfterViewChecked() {
    if (!this.aspectRatio) return;

    this.aspectElement.nativeElement.style.paddingTop = `calc((1 / ${this.aspectRatio}) * 100%)`;
  }
}
