import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'deadlineDate'
})
export class DeadlineDatePipe implements PipeTransform {
  constructor() {}

  transform(date: string, ...args: unknown[]): string {
    const isSameDay = moment().format("YYYYMMDD") === moment(date).format("YYYYMMDD");
    const isMidnight = moment().endOf('day').add(1, "minute").format("YYYYMMDDHHmm") === moment(date).format("YYYYMMDDHHmm");
    if (isMidnight) {
      return `${$localize `:midnight label|@@label.midnight:Midnight`}`
    } else if (isSameDay) {
      return `${$localize `:today label|@@label.today:Today`} ${moment(date).format('HH:mm')}`;
    } else {
      return moment(date).format('DD MMM HH:mm');
    }
  }

}
