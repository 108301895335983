<div mat-dialog-container
  class="px-3 pb-3 pt-0">
  <div class="text-right">
    <mat-icon matTooltip="Close"
      class="close-icon pointer"
      i18n-matTooltip="Close tooltip@@tooltip.close"
      (click)="onCloseClick()">close</mat-icon>
  </div>

  <h3 mat-dialog-title
    class="text-center mb-0 font-weight-bold mb-3">
    <span *ngIf="data">{{data.certificate.name}}</span>
  </h3>
  <div mat-dialog-content
    align="start"
    class="bg-wrap mb-3">
    <div class="row d-flex p-0 p-lg-3">
      <div class="col-sm col-md-3 d-flex justify-content-center mb-2">
        <div class="logo-image d-flex flex-column align-items-center justify-content-center mt-2">
          <!-- Error image check -->
          <img [src]="data.certificate.thumbnail_url"
            alt="Logo"
            *ngIf="data.certificate.thumbnail_url"
            (error)="data.certificate.thumbnail_url = null">
          <img src="../../../../assets/img/ic_upload_logo.svg"
            alt="Upload logo"
            *ngIf="!data.certificate.thumbnail_url">
        </div>
      </div>
      <div class="col-sm col-md-9 d-flex justify-content-start mb-2 p-0">
        <div class="mb-2 d-flex flex-column align-items-start justify-content-center w-100 px-0 px-lg-4">
          <div class="text-left mb-2">
            <div class="d-flex align-items-start">
              <span class="mr-2 mt-2 icon icon-category"></span>
              <div class="d-inline-block">
                <label class="text-label"
                  i18n="Category label@@label.category">Category</label>:
                <div class="info-text-thin">
                  {{data.certificate.category.name}}
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!data.isEdit; else editForm">
            <div class="text-left my-2"
              *ngIf="!data.shortMode">
              <div class="d-flex align-items-start">
                <span class="mr-2 mt-2 icon icon-date"></span>
                <div class="d-inline-block">
                  <label class="text-label"
                    i18n="Valid period label@@label.validPeriod">Valid period</label>:
                  <div class="info-text-thin"
                    *ngIf="!data.forever">
                    {{data.start_at | date:'dd.MMM.yyyy'}} — {{data.end_at | date:'dd.MMM.yyyy'}}
                  </div>
                  <div i18n="No expiration date label@@label.noExpirationDate"
                    class="info-text-thin"
                    *ngIf="data.forever">No expiration date</div>
                </div>
              </div>
            </div>
            <div class="text-left my-2"
              *ngIf="!data.shortMode">
              <div class="d-flex align-items-start">
                <span class="mr-2 mt-2 icon icon-status"></span>
                <div class="d-inline-block">
                  <label class="text-label"
                    i18n="Status label@@label.status">Status</label>:
                  <div class="info-text-thin"
                    [ngSwitch]="data.status">
                    <span *ngSwitchCase="stampStatus.ACTIVE"
                      i18n="Active label@@label.active">Active</span>
                    <span *ngSwitchCase="stampStatus.DISABLED"
                      i18n="Inctive label@@label.inctive">Inctive</span>
                    <span *ngSwitchCase="stampStatus.NOT_ACTIVE_YET"
                      i18n="Future label@@label.future">Future</span>
                    <span *ngSwitchCase="stampStatus.EXPIRED"
                      i18n="Expired label@@label.expired">Expired</span>
                    <span *ngSwitchCase="stampStatus.REQUESTED"
                      i18n="Requested label@@label.requested">Requested</span>
                    <span *ngSwitchCase="stampStatus.NOT_ACCEPTED"
                      i18n="Not accepted label@@label.notAccepted">Not accepted</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #editForm >
            <form [formGroup]="certificateForm">

              <mat-form-field appearance="outline" color="primary">
                <mat-label i18n="No expiration date label@@label.noExpirationDate"
                  *ngIf="certificateForm.value.forever">No expiration date</mat-label>
                <mat-label i18n="Valid period label@@label.validPeriod"
                  *ngIf="!certificateForm.value.forever">Valid period</mat-label>
                <mat-date-range-input formGroupName="range" [rangePicker]="picker">
                  <input
                    matStartDate
                    formControlName="start_at"
                    i18n-placeholder="Start date label@@label.startDate"
                    placeholder="Start date"
                    [min]="minDay"
                    [required]="true">
                  <input
                    matEndDate
                    formControlName="end_at"
                    i18n-placeholder="End date label@@label.endDate"
                    placeholder="End date"
                    [required]="true">
                </mat-date-range-input>

                <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                {{certificateForm.controls['range'].controls['start_at'].errors}}

                <mat-error *ngIf="certificateForm.controls['range'].controls['end_at'].errors?.serverError">
                  {{ certificateForm.controls['range'].controls['end_at'].errors?.serverError }}
                </mat-error>
                <mat-error *ngIf="certificateForm.controls['range'].controls['start_at'].errors?.serverError">
                  {{ certificateForm.controls['range'].controls['start_at'].errors?.serverError }}
                </mat-error>
                <mat-error *ngIf="certificateForm.controls['range'].controls['start_at'].errors?.required"
                  i18n="Date required error@@error.date.required">
                  Date is required
                </mat-error>
                <mat-error *ngIf="form?.hasError('startAtInPast')"
                  i18n="Date in past error@@error.date.inPast">
                  Date cannot be in the past
                </mat-error>
              </mat-form-field>

              <div class="mt-0 mb-4">
                <mat-checkbox color="primary"
                  (change)="foreverChanged($event)"
                  formControlName="forever"
                  [labelPosition]="'after'">
                  <span i18n="No expiration date label@@label.noExpirationDate">No expiration date</span>
                </mat-checkbox>
              </div>
            </form>
          </ng-template>

          <div class="text-left my-2">
            <div class="d-flex align-items-start">
              <span class="mr-2 mt-2 icon icon-description"></span>
              <div class="d-inline-block">
                <label class="text-label"
                  i18n="Description label@@label.description">Description</label>:
                <div class="description-wrapper">
                  <pre class="description">{{data.certificate.description}}</pre>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left my-2" *ngIf="data.organization && !data.shortMode">
            <div class="d-flex align-items-start">
              <span class="mr-2 mt-2 icon icon-assigned"></span>
              <div class="d-inline-block">
                <label class="text-label"
                  i18n="Assigned by label@@label.assignedBy">Assigned by:</label>
                <div class="info-text-thin">
                  {{data.organization.name}}
                </div>
              </div>
            </div>
          </div>
          <div class="text-left my-2"
            *ngIf="!data.shortMode && data.certificate.documents?.length">
            <div class="d-flex align-items-start">
              <span class="mr-2 mt-2 icon icon-download"></span>
              <div class="d-inline-block">
                <label class="text-label"
                  i18n="Conditions label@@label.conditions">Conditions:</label>

                <div *ngFor="let file of data.certificate.documents"
                  class="mb-2">
                  <a class="text-break link d-block"
                    href="{{file.url}}"
                    target="_blank">
                    {{file.name}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left my-2"
            *ngIf="!data.shortMode && data.certificate.documents?.length && !data.isEdit && data.status !== stampStatus.REQUESTED">
            <mat-checkbox color="primary"
              [checked]="data.is_accepted"
              (change)="conditionsChange($event)"
              [disabled]="isCertificateAccepted"
              [labelPosition]="'after'">
              <span i18n="I read and agree with certificate conditions label@@label.agreeConditionsCheckbox">
                I read and agree with certificate conditions
              </span>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions
    align="end"
    class="pb-4 pt-0">
    <button class="mr-2"
      mat-stroked-button
      color="primary"
      mat-dialog-close
      i18n="Cancel button label@@button.cancel"
      (click)="onCloseClick()">
      CANCEL
    </button>

    <button mat-raised-button
      color="primary"
      [disabled]="isLoading"
      (click)="onConfirmClick()"
      *ngIf="isConfirmButtonShown">
      <span *ngIf="!isLoading && !data.isEdit && !data.shortMode"
        i18n="Done button@@button.done">Done</span>
      <span *ngIf="!isLoading && data.isEdit && !data.shortMode"
        i18n="Assign button@@button.assign">Assign</span>
      <span *ngIf="!isLoading && data.shortMode"
        i18n="Request certificate button@@button.requestCErtificate">Request certificate</span>
      <mat-spinner *ngIf="isLoading"
        class="mx-auto"
        [diameter]="20"
        [strokeWidth]="2"></mat-spinner>
    </button>
    
  </div>

</div>
