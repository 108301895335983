export const storageName = {
  appPrefix: 'friskus',
  user: 'user'
};

export const descriptionLength = {
  short: 270,
  full: 50000
};

export const dateFormat = {
  short: 'YYYY-MM-DD',
  full: 'YYYY-MM-DD[T]HH:mm:ssZZ'
};

export const DATEPICKER_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

export const THUMBNAIL_DEFAULT = 'https://ucarecdn.com/66fa132d-a232-4caf-a882-c130459899b6/image_cover.jpg';

export const uploadTabs = {
  documents: 'file url gdrive dropbox onedrive',
  thumbnail: 'file url camera facebook gdrive gphotos dropbox instagram flickr onedrive'
};

export const SURVEY_LINK = 'https://svar.norceresearch.no/LinkCollector?key=FK86S4KNL511';

export const NUMBER_EVENTS_ACTIVE_USER = 5;

export const POPULAR_EVENTS_NUMBER = 10;

export const FACEBOOK_IMAGE_URL = '-/resize/x275/-/crop/625x275/center';

export const TERMS_TYPE = {
  leisureCard: 'fritidskort',
  common: 'friskus'
};

export const STICKY_Y_OFFSET = 455;
