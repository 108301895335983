import {UserMunicipality} from "@shared/models/user-municipality.model";

export const ArendalMunicipality: UserMunicipality = {
  name: 'arendal',
  slogan: {value: '', id: 'banner.arendal', meaning: 'bannerSlogan', description: 'slogan for arendal'},
  data: {
    headerDescription: 'Fritidskortordningen er avsluttet. Her vil det om kort tid komme informasjon om øvrige støtteordninger.<br><br>' +
      ' Ved spørsmål kontakt kommunen på <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:julie.sundsdal.naerdal@arendal.kommune.no">julie.sundsdal.naerdal@arendal.kommune.no</a>'
  },
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: true,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
}
