import { NgModule } from '@angular/core';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';

@NgModule({
  imports: [
    AppModule
  ],
  providers: [
    { provide: 'ORIGIN_URL', useValue: location.origin },
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppBrowserModule {
}
