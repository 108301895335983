import { PushNotificationsService } from '@services/remote-api/push-notifications.service';
import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { Observable, from } from 'rxjs';
import { Organization, SMSConfiguration } from '@shared/models/organization.model';
import { shareReplay, tap } from 'rxjs/operators';
import {MunicipalitiesService} from "@services/remote-api/municipalities.service";
import {Params} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private serviceUrl = API_URL_MAP.groups;
  private categoriesRes$: Observable<any>;
  constructor(
    private http: GenericHttpService,
    private pushNotificationsService: PushNotificationsService,
    private municipalityService: MunicipalitiesService,
  ) {
  }

  getRoles(organizationId) {
    return this.http.get(API_URL_KEY.group, `${this.serviceUrl.base}/${organizationId}/roles`);
  }

  invite(organizationId, data) {
    const url = `${this.serviceUrl.base}/${organizationId}/invites`;
    return this.http.post(API_URL_KEY.group, url, data).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  acceptInvitation(organizationId, token) {
    const url = `${this.serviceUrl.base}/${organizationId}/invites/accept`;
    return this.http.post(API_URL_KEY.group, url, { token }).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  declineInvitation(organizationId, token) {
    const url = `${this.serviceUrl.base}/${organizationId}/invites/decline`;
    return this.http.post(API_URL_KEY.group, url, { token }).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  getGroups(params: any = {}, page: number = 1): Observable<any> {
    const searchParams = { ...params };
    searchParams.page = page;
    if (searchParams.type === 'member') {
      searchParams.member = true;
    }
    if (searchParams.type === 'leisure_card') {
      searchParams['leisure-card'] = true;
    }
    if (searchParams.type) {
      delete searchParams.type;
    }
    const url = `${this.serviceUrl.base}`;
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  getEventsGroups(page: number = 1): Observable<any> {
    const url = `${this.serviceUrl.base}`;
    return this.http.get(API_URL_KEY.event, url, { page });
  }

  getGlobalCertificates(page: number = 1): Observable<any> {
    const url = `${this.serviceUrl.certificates}?types[]=friskus&types[]=user`;
    return this.http.get(API_URL_KEY.group, url, { page });
  }

  public get categories() {
    if (!this.categoriesRes$) {
      this.categoriesRes$ = this.getCategories().pipe(
        shareReplay()
      );
    }

    return this.categoriesRes$;
  }

  public getCategories(): Observable<{data: Array<{ id: string; name: string; slug: string}>}> {
    const url = `${API_URL_MAP.events.categories}?type=organizations`;
    return this.http.get(API_URL_KEY.event, url);
  }

  createGroup(groupData): Observable<any> {
    const url = `${this.serviceUrl.base}`;
    return this.http.post(API_URL_KEY.group, url, groupData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  getGroupById(organizationId: string, fromFacebook?: boolean): Observable<{ data: Organization }> {
    const url = `${this.serviceUrl.base}/${organizationId}`;
    const params: Params = {};

    params.municipality = this.municipalityService.getUserMunicipality().name;
    if (fromFacebook) {
      params.from_facebook = true;
    }

    return this.http.get(API_URL_KEY.group, url, params);
  }

  getGroupByName(query: string): Observable<any> {
    const url = `${this.serviceUrl.base}${this.serviceUrl.search}`;
    return this.http.get(API_URL_KEY.group, url, { q: query });
  }

  updateGroupById(organizationId: string, groupData): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}`;
    return this.http.put(API_URL_KEY.group, url, groupData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  deleteGroupById(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}`;
    return this.http.del(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Event Gallery

  public getGroupImages(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.gallery}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public deleteGroupImageById(organizationId: string, imageId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.gallery}/${imageId}`;
    return this.http.del(API_URL_KEY.group, url);
  }

  // Members

  public getGroupMembers(organizationId: string, page: number = 1, query?: string, filterParams: any = {}): Observable<any> {
    const searchParams: any = { ...filterParams };
    searchParams.page = page;

    if (query) {
      searchParams.q = query;
    }
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}`;
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  public getMemberRequests(organizationId: string, page: number = 1): Observable<any> {
    const searchParams: any = {};
    searchParams.page = page;

    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.requests}`;
    return this.http.get(API_URL_KEY.group, url, searchParams);
  }

  public getGroupMembersForContactPersons(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}?roles[]=admin&roles[]=moderator`;
    return this.http.get(API_URL_KEY.group, url, {});
  }

  public requestGroupMembership(organizationId: string, description: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.request}`;
    return this.http.post(API_URL_KEY.group, url, { description }).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public leaveGroup(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.leave}`;
    return this.http.post(API_URL_KEY.group, url, {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public removeOrganizationMember(organizationId: string, memberId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/${memberId}`;
    return this.http.del(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public exportMembers(organizationId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/download`;
    return this.http.getFile(API_URL_KEY.group, url);
  }

  public setNewGroupOwner(organizationId: string, newOnerUserId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}/owner/${newOnerUserId}`;
    return this.http.post(API_URL_KEY.group, url, {});
  }

  public addMemberToOrganization(organizationId: string, memberData) {
   const municipality = this.municipalityService.getUserMunicipality().name;

    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/assign?municipality=${municipality}`;
    return this.http.post(API_URL_KEY.group, url, memberData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public ApproveOrDeclineOrganizationInvitation(organizationId: string, invitation: string, status: string, name: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/invitations/modify`;

    return this.http.post(API_URL_KEY.group, url, {invitation, status, name}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Requests

  public getGroupMembershipRequests(organizationId: string, page: number = 1) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.requests}`;
    return this.http.get(API_URL_KEY.group, url, { page });
  }

  public acceptGroupMembershipRequest(organizationId: string, memberId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/${memberId}${this.serviceUrl.accept}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public cancelGroupMembershipRequest(organizationId: string, memberId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}/${memberId}${this.serviceUrl.cancel}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Co-hosts requests

  public getCohostsRequests(organizationId: string, page: number = 1) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.cohosts}`;
    return this.http.get(API_URL_KEY.group, url, { page });
  }

  public approveRequest(organizationId: string, eventId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.cohosts}/${eventId}${this.serviceUrl.approve}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public cancelCohostsRequest(organizationId: string, eventId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.cohosts}/${eventId}${this.serviceUrl.cancel}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Subsidiary requests

  public getSubsidiaryRequests(organizationId: string, page: number = 1) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.subsidiaries}`;
    return this.http.get(API_URL_KEY.group, url, { page });
  }

  public approveSubsidiaryRequest(organizationId: string, requestId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.subsidiaries}/${requestId}${this.serviceUrl.accept}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public cancelSubsidiaryRequest(organizationId: string, requestId: string) {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.subsidiaries}/${requestId}${this.serviceUrl.cancel}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Roles

  public changeGroupMemberRole(organizationId: string, memberId: string, roleData): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.members}` +
      `/${memberId}${this.serviceUrl.roles}${this.serviceUrl.change}`;
    return this.http.put(API_URL_KEY.group, url, roleData);
  }

  // Permissions

  public getGroupPermissionForUser(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.permissions}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public getGroupPermissionSettings(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.permissions}${this.serviceUrl.settings}`;
    return this.http.get(API_URL_KEY.group, url);
  }

  public updateGroupPermissionSettings(organizationId: string, permissionsData): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.permissions}${this.serviceUrl.settings}`;
    return this.http.post(API_URL_KEY.group, url, permissionsData);
  }

  // Search
  public search(query: string, municipalities?) {
    const url = `${this.serviceUrl.search}`;
    if (municipalities) {
      return this.http.get(API_URL_KEY.group, url, { q: query, municipalities });
    } else {
      return this.http.get(API_URL_KEY.group, url, { q: query });
    }
  }

  public groupSearch(query: string, params?) {
    const url = `${this.serviceUrl.base}${this.serviceUrl.search}`;
    const searchParams = { ...params };
    searchParams.q = query;
    return this.http.get(API_URL_KEY.group, url, searchParams);

  }

  // Superadmin requests

  public acceptGroup(organizationId: string): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.accept}`;
    return this.http.post(API_URL_KEY.group, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public declineGroup(organizationId: string, data): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.decline}`;
    return this.http.post(API_URL_KEY.group, url, data).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  // Export
  public export(queryObj) {
    const url = `${this.serviceUrl.base}/export`;
    return this.http.getFile(API_URL_KEY.group, url, queryObj);
  }

  // Leisure card

  public addLeisureCard(organizationId: string, cardData): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${API_URL_MAP.card.base}`;
    return this.http.post(API_URL_KEY.group, url, cardData);
  }

  // Messages
  public sendMessageToMembers(organizationId: string, messageData: any): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${API_URL_MAP.messenger.messages}`;
    return this.http.post(API_URL_KEY.event, url, messageData);
  }

  public setOrganizationSMSConfig(organizationId: string, configData: SMSConfiguration): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.smsConfig}`;
    return this.http.put(API_URL_KEY.group, url, configData);
  }

  public sendSMSToMembers(organizationId: string, messageData: any): Observable<any> {
    const url = `${this.serviceUrl.base}/${organizationId}${this.serviceUrl.sendSms}`;
    return this.http.post(API_URL_KEY.group, url, messageData);
  }

}
