import { MetricsService } from './../../services/remote-api/metrics.service';
import { AuthService } from '@auth/shared/services/auth/auth.service';
import { BreakpointState } from '@angular/cdk/layout';

import {
  Component, EventEmitter, Input, Output, SimpleChanges, OnInit,
  OnChanges, OnDestroy, ViewChild, ElementRef
} from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { UserInfo } from '../../models/user.model';
import { MunicipalitiesService } from '@services/remote-api/municipalities.service';
import { CustomOverlayDialogConfig, DEFAULT_CONFIG } from '@services/overlay/overlay.service';
import { Store } from '@store';
import { MatDialog } from '@angular/material/dialog';
import { SwitchUserDialogComponent } from '../dialogs/switch-user-dialog/switch-user-dialog.component';
import { MODAL_SIZE } from '@shared/constants/modals.const';
import { UserMunicipality } from '@shared/models/user-municipality.model';
import { BANK_LOGIN_URL } from '@config/routes.config';
import { environment } from '@environments/environment';
import { GlobalFilterConfigs } from '@features/filters/services/global-filter-configs';
import { FilterService } from '@features/filters/services/filter.service';
import { MultiSelectFilter, MultiSelectFilterValue } from '../../../features/filters/services/filters.types';
import { Municipality } from '@shared/models/municipality.model';
import { calcFilterUniqueId } from '@features/filters/services/filter-compare';


@Component({
  selector: 'friskus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('overlayTrigger') overlayTrigger: ElementRef;
  @Input() public user: any;
  @Input() public userInfo: UserInfo;
  @Input() public messengerUserData: any;
  @Output() public logout = new EventEmitter<any>();
  @Output() public menuOpened = new EventEmitter<any>();
  public isMobile: Observable<BreakpointState>;
  public isTablet: Observable<BreakpointState>;
  public isMenuShown = false;
  public isCalendarView = false;
  public isSuperAdmin = false;
  public isModerator = false;
  public isMunicipalityAdmin = false;
  public isMunicipalitySupport = false;
  public isSearchShown = false;
  public isAnnouncement = false;
  public overlayConfig: CustomOverlayDialogConfig;

  public profileImgURL: string;
  public isHeaderSearchShown = true;
  public urlWithoutQueryString: string;
  public hideGuide = true;
  public municipalityNames: string[];
  private municipalities: Municipality[] = [];
  public userMunicipality: UserMunicipality;
  public currentMunicipality: Municipality | null = null;
  public isNavigateHomeDisabled = false;
  public bankLoginUrl = BANK_LOGIN_URL;
  public choosedLang: string;
  public isLoggedIn: boolean = false;

  public backofficeUrl = environment.backofficeUrl ?? 'https://admin.friskus.com';

  constructor(
    private router: Router,
    private screenType: ScreenTypeService,
    private authService: AuthService,
    private municipalityService: MunicipalitiesService,
    private store: Store,
    private municipalitiesService: MunicipalitiesService,
    private dialog: MatDialog,
    private metrics: MetricsService,
    private globalFilterConfigs: GlobalFilterConfigs,
    private filterService: FilterService,
  ) {
    this.isTablet = this.screenType.isTablet;
    this.isMobile = this.screenType.isHandset;
    this.overlayConfig = DEFAULT_CONFIG;
    this.isMobile.subscribe(res => {
      this.menuOpened.emit(false);
      if (!res.matches) {
        this.hideGuide = false;
        this.menuOpened.emit(false);
      }
    });

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const urlWithoutQueryString = this.router.url.split('?')[0];
        this.isCalendarView = urlWithoutQueryString === '/events/calendar';
        // this.isHeaderSearchShown = urlWithoutQueryString !== '/events' && urlWithoutQueryString !== '/organisations';
      }
    });
  }

  ngOnInit() {
    this.authService.authState.subscribe(user => {
      if (user) {
        const tokenData = this.authService.parseJwt(user.access_token);
        this.isSuperAdmin = tokenData.scopes.includes('admin');
        this.isModerator = tokenData.scopes.includes('moderator');
        this.isMunicipalityAdmin = tokenData.scopes.includes('MUNICIPALITY_ADMIN');
        this.isMunicipalitySupport = tokenData.scopes.includes('MUNICIPALITY_SUPPORT');
        this.isLoggedIn = true;
      } else {
        this.isSuperAdmin = this.isModerator = this.isMunicipalityAdmin = false;
      }
    });
    this.userMunicipality = this.municipalityService.getUserMunicipality();
    this.municipalitiesService.municipalities.subscribe(res => {
      this.municipalities = res.data;
      this.currentMunicipality = this.municipalities.find(municipality => municipality.key === this.userMunicipality.name);
      if (this.currentMunicipality) {
        localStorage.setItem('municipalityName', this.currentMunicipality.name);
        localStorage.setItem('municipalityKey', this.currentMunicipality.key);
      }
      if (localStorage.getItem('municipalities')) {
        this.setActiveMunicipality(JSON.parse(localStorage.getItem('municipalities')));
      }
      this.setActiveMunicipalityNames();
    });
    this.choosedLang = this.getAppLang();

    if (!window.location.pathname.includes('/slideshow')) {
      this.isAnnouncement = environment.news;
      this.store.select('announcement').subscribe((announcement: string) => {
        this.isAnnouncement = environment.news || !!announcement;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userInfo && changes.userInfo.currentValue) {
      this.userInfo = changes.userInfo.currentValue;
      if (changes.userInfo.currentValue.thumbnail_url) {
        this.profileImgURL = changes.userInfo.currentValue.thumbnail_url;
      } else {
        this.profileImgURL = null;
      }
    }
  }

  ngOnDestroy(): void {}


  isLinkActive(url): boolean {
    const baseUrl = this.router.url.split('?')[0];
    return baseUrl === url;
  }
  imgErrorHandler(event) {
    this.profileImgURL = null;
  }
  public toggleSearch() {
    this.isSearchShown = !this.isSearchShown;
  }

  public get returnUrl() {
    return this.router.url;
  }

  public toggleMenu(close?: boolean) {
    if (close) {
      this.isMenuShown = false;
    } else {
      this.isMenuShown = !this.isMenuShown;
      this.menuOpened.emit(this.isMenuShown);
    }
  }

  public loginNavigate() {
    this.handleNavClick();
    if (this.returnUrl.includes('/auth/')) {
      return;
    } else {
      this.router.navigate(['/auth', 'get-started'], {queryParams: {returnUrl: this.returnUrl}});
    }
  }

  public showAdminLink(): boolean
  {
    return this.userMunicipality && (this.isMunicipalitySupport || this.isMunicipalityAdmin);
  }

  public handleNavClick(closeMenu?: boolean, action ?: string) {
    if (action) {
      let role = 'user';
      if (this.isSuperAdmin) {
        role = 'super';
      } else if (this.isModerator) {
        role = 'moderator';
      } else if (this.isMunicipalityAdmin) {
        role = 'munAdmin';
      }
      this.metrics.incrementMetrics(`menu:${action}`, role).subscribe();
    }
    const mobileSub = this.isMobile.subscribe(res => {
      if (res.matches) {
        closeMenu ? this.toggleMenu(closeMenu) : this.toggleMenu();
      }
    });

    mobileSub.unsubscribe();
  }

  public navigateHome() {
    if (!this.isNavigateHomeDisabled) {
      this.filterService.clearFilters();
      this.router.navigateByUrl('/', { replaceUrl: true }).then(() =>
      this.router.navigate(['/']));
      this.isNavigateHomeDisabled = true;
    }
    setTimeout(() => this.isNavigateHomeDisabled = false, 1000);
  }

  public handleSearchItemChosen(item) {
    this.router.navigate(item.itemRoute, item.query);
    this.handleNavClick();
  }

  public onLogout() {
    this.handleNavClick();
    this.logout.emit();
  }

  public openSwitchUserDialog() {
    this.dialog.open(SwitchUserDialogComponent, {
      data: {},
      maxWidth: MODAL_SIZE.medium,
      width: MODAL_SIZE.medium,
      panelClass: 'full-width-dialog',
      disableClose: true,
      autoFocus: false
    });

    this.handleNavClick();
  }

  public navigateToLang(lang: string): void {
    const route = window.location.pathname;
    let redirectRoute = route;
    this.choosedLang = lang;

    if (lang === 'no') {
      this.navigateToDefaultLanguage(redirectRoute);
      return;
    }

    if (route.includes(`/nn/`) || route.includes(`/en/`)) {
      redirectRoute = redirectRoute.replace('/nn/', '/').replace('/en/', '/');
    }

    if (redirectRoute) {
      window.location.href = `/${lang}${redirectRoute}`;
    } else {
      window.location.href = `/${lang}`;
    }
  }

  private setActiveMunicipality(activeMunicipalityIds: string[]) {
    const activeMunicipalityFilter = this.municipalities.reduce((acc, cur, i) => {
      if (activeMunicipalityIds.includes(cur.id)) {
        acc.indices.push({ index: i });
        acc.value.push({
          label: cur.name,
          value: cur.id,
          lock: cur.id === this.currentMunicipality?.id,
        });
      }
      return acc;
    }, {value: [], indices: []} as MultiSelectFilterValue<string>);

    const municipalitiesFilterConfig = this.globalFilterConfigs.getMunicipalityFilterConfig();
    const municipalityFilterAlreadyExists =
      this.filterService.Filter$.value.find(x => calcFilterUniqueId(x) === calcFilterUniqueId(municipalitiesFilterConfig)) as MultiSelectFilter<MultiSelectFilterValue<string>>;
    const lockedMunicipalityExists = municipalityFilterAlreadyExists?.filterValue.value.find(x => x.lock && activeMunicipalityFilter.value.some(y => y.value === x.value))

    if (!lockedMunicipalityExists) {
      this.filterService.addFilter({
        ...municipalitiesFilterConfig,
        filterValue: activeMunicipalityFilter,
      });

      this.store.set(
        "selectedMunicipalityList",
        activeMunicipalityFilter.value.map((x) => x.label)
      );
    }
  }

  private setActiveMunicipalityNames() {
    this.store.select('selectedMunicipalityList').subscribe((municipalityNames: string[]) => {
      this.municipalityNames = municipalityNames;

      if (!this.municipalityNames || this.municipalityNames.length === 0) {
        const subdomainMunicipalityName = this.municipalitiesService.getUserMunicipality().name;

        if (subdomainMunicipalityName === 'default') {
          this.municipalitiesService.getUserLocation().subscribe(res => {
            const municipalityName = this.municipalitiesService.getUserMunicipalityByIP(res.data.city);
            if (municipalityName !== 'default') {
              const municipalityIdx = this.municipalities.findIndex(x => x.name === municipalityName);
              const municipality = this.municipalities[municipalityIdx];
              const municipalitiesFilterConfig = this.globalFilterConfigs.getMunicipalityFilterConfig();

              const municipalityFilterAlreadyExists =
                this.filterService.Filter$.value.find(x => calcFilterUniqueId(x) === calcFilterUniqueId(municipalitiesFilterConfig)) as MultiSelectFilter<MultiSelectFilterValue<string>>;
              const lockedMunicipalityExists = municipalityFilterAlreadyExists?.filterValue.value.find(x => x.lock && x.value === municipality.id);

              if (!lockedMunicipalityExists && municipality) {
                this.filterService.addFilter<MultiSelectFilterValue<string>>({
                  ...municipalitiesFilterConfig,
                  filterValue: {
                    indices: [{index: municipalityIdx}],
                    value: [{label: municipality.name, value: municipality.id, lock: true}]
                }});
                this.store.set('selectedMunicipalityList', [municipalityName]);
              }
            }
          });
        } else {
          const activeMunicipalityIdx = this.municipalities.findIndex(x => x.key === subdomainMunicipalityName);
          const activeMunicipality = this.municipalities[activeMunicipalityIdx];
          if (activeMunicipality) {
              const municipalitiesFilterConfig = this.globalFilterConfigs.getMunicipalityFilterConfig();

              const municipalityFilterAlreadyExists =
                this.filterService.Filter$.value.find(x => calcFilterUniqueId(x) === calcFilterUniqueId(municipalitiesFilterConfig)) as MultiSelectFilter<MultiSelectFilterValue<string>>;
              const lockedMunicipalityExists = municipalityFilterAlreadyExists?.filterValue.value.find(x => x.lock && x.value === activeMunicipality.id);

              if (!lockedMunicipalityExists) {
                this.filterService.addFilter<MultiSelectFilterValue<string>>({
                  ...municipalitiesFilterConfig,
                  filterValue: {
                    indices: [{ index: activeMunicipalityIdx }],
                    value: [
                      {
                        label: activeMunicipality.name,
                        value: activeMunicipality.id,
                        lock: true,
                      },
                    ],
                  },
                });
                this.store.set('selectedMunicipalityList', [activeMunicipality.name]);
              }
            }
          }
        }
      });
  }

  private getAppLang() {
    const route = window.location.pathname;
    if (route.includes(`/nn/`)) {
      return 'nn';
    }

    if (route.includes(`/en/`)) {
      return 'en';
    }

    return 'no';
  }

  private navigateToDefaultLanguage(route: string): void {
    // Redirect only if on other language
    let redirectRoute: string;
    if (route.includes(`/nn/`) || route.includes(`/en/`)) {
      redirectRoute = route.replace('/nn/', '/').replace('/en/', '/');
    }

    if (redirectRoute) {
      window.location.href = `${redirectRoute}`;
    }
  }
}
