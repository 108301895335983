import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SandefjordMunicipality: UserMunicipality = {
  name: 'sandefjord',
  slogan: {
    value: 'sandefjord', id: 'banner.sandefjord', meaning: 'bannerSlogan',
    description: 'slogan for sandefjord'
  },
  data: {
    headerTitle: 'Fritidskonto',
    headerDescription: 'Denne tjenesten er ikke lansert. Siden er under utvikling.',
    minAge: 2,
    maxAge: 18,
    amount: 0,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  sunset: false,
  infoPageTitle: 'Fritidskortet',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet',
      minAge: 2,
      maxAge: 18,
      url: 'https://www.sandefjord.kommune.no/kultur-idrett-fritid/tilskudd-og-stotteordninger/kultur/fritidskortet/',
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Fritidskortet',
      minAge: 2,
      maxAge: 18,
      url: 'https://www.sandefjord.kommune.no/kultur-idrett-fritid/tilskudd-og-stotteordninger/kultur/fritidskortet/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'BUA',
      name: 'BUA',
      links: [
        {
          url: 'https://sandefjord.frivilligsentral.no/aktivitet?utlånssentral&Id=3637',
          title: "BUA Sandefjord"
        },
        {
          url: 'https://www.bua.no/utlansordninger/bua-andebu',
          title: "BUA Andebu"
        },
        {
          url: 'https://www.bua.no/utlansordninger/skattkammeret-stokke',
          title: "BUA Stokke"
        },
      ]
    },
  ]
}
