import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MunicipalitiesService } from '@services/remote-api/municipalities.service';
import { Router } from '@angular/router';
import { MUNICIPALITY_CONFIG } from '@config/municipality.config';

@Component({
  selector: 'friskus-municipality-banner',
  templateUrl: './municipality-banner.component.html',
  styleUrls: ['./municipality-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MunicipalityBannerComponent implements AfterViewInit, OnInit {

  public isLoading: boolean;
  public eventsSearchItems: any = [];
  public organizationSearchItems: any = [];
  public bannerConfig = MUNICIPALITY_CONFIG;
  public bannerValue: any;
  public hasHost: boolean;
  public isFirstSearch = true;

  constructor(
    private municipalitiesService: MunicipalitiesService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    Object.keys(this.bannerConfig).forEach(municipalityName => {
      this.preselectMunicipality(municipalityName);
    });

    if (!this.hasHost) {
      this.bannerValue = this.bannerConfig.default;
    }
    this.cdr.detectChanges();
  }

  private preselectMunicipality(municipalityName) {
    const municipalityFromHostName = this.municipalitiesService.getUserMunicipality().name;

    if (municipalityFromHostName === municipalityName) {
      this.hasHost = true;
      this.bannerValue = this.bannerConfig[municipalityName];
    }
  }

  public get sloganWithTranslation() {
    // cause of [innerHTML] binding, should be sent HTML string, but i18n will work incorrect way
    // so lets just send it as value atm

    if (this.bannerValue) {
      return this.bannerValue.slogan.value;
    } else {
      return this.bannerConfig.default.slogan.value;
    }
  }

  public bannerImgErrorHandler(event) {
    this.bannerValue = this.bannerConfig.default;
  }
  public handleSearchItemChosen(item) {
    this.router.navigate(item.itemRoute, item.query);
  }
}
