export const API_URL_MAP = {
  events: {
    base: '/events',
    event: '/events',
    types: '/types',
    draft: '/draft',
    keywords: '/keywords',
    gallery: '/gallery',
    comments: '/comments',
    categories: '/categories',
    organizations: '/organizations',
    occurrences: '/occurrences',
    publish: '/publish',
    unpublish: '/unpublish',
    cancel: '/cancel',
    uncancel: '/uncancel',
    attend: '/attend',
    leave: '/leave',
    collection: '/collection',
    attendees: '/attendees',
    multiple: '/multiple',
    slots: '/slots',
    my: '/my',
    joinAll: '/join-all',
    complete: '/complete'
  },
  groups: {
    base: '/organizations',
    certificates: '/certificates',
    group: '/group',
    gallery: '/gallery',
    members: '/members',
    accept: '/accept',
    decline: '/decline',
    approve: '/approve',
    cancel: '/cancel',
    request: '/request',
    requests: '/requests',
    permissions: '/permissions',
    settings: '/settings',
    leave: '/leave',
    roles: '/roles',
    change: '/change',
    cohosts: '/cohosts',
    subsidiaries: '/subsidiaries',
    search: '/search',
    statistic: '/statistic',
    create: '/create',
    smsConfig: '/smsconfig',
    sendSms: '/sendsms'
  },
  participants: {
    base: '/event',
    participants: '/participants'
  },
  users: {
    base: '/users',
    me: '/me',
    geoip: '/geoip',
    search: '/search',
    organizations: {
      base: '/organizations',
      joined: '/joined'
    },
    events: {
      base: '/events',
      attend: '/attend',
      collection: '/collection',
      slots: '/slots'
    },
    cv: {
      base: '/cv',
      download: '/download'
    }
  },
  municipalities: {
    base: '/municipalities'
  },
  auth: {
    base: '/auth',
    login: '/login',
    register: '/register',
    logout: '/logout',
    usernameVerifications: '/username-verifications',
    verifyCode: '/verify-code',
    sendCode: '/username',
    sendEmailCode: '/email',
    sendPhoneCode: '/phone',
    resend: '/resend',
    password: {
      base: '/password',
      restore: '/restore',
      reset: '/reset',
    }
  },
  card: {
    base: '/cards',
    check: '/check',
    share: '/share',
    take: '/take',
    bankId: '/bankid',
    minid: '/minid',
    auth: '/auth',
    status: '/status',
    providers: '/providers',
    transactions: '/transactions',
    withdraw: '/withdraw',
    paymentIntent: '/payment/intent',
    paymentAndWithdraw: '/payment-and-withdraw',
    attachments: '/attachments',
    import: '/import',
    initialize: '/initialize'
  },
  transaction: {
    base: '/transactions',
    retry: '/retry'
  },
  notifications: {
    base: '/notifications',
    settings: '/settings',
    mark: '/mark',
    read: '/read'
  },
  messenger: {
    me: '/me',
    unreadTotal: '/unread/total',
    base: '/messenger',
    threads: '/threads',
    leave: '/leave',
    messages: '/messages',
    participants: '/participants',
    direct: '/direct'
  },
  certificates: {
    base: '/certificates',
    categories: '/categories',
    stamps: '/stamps',
    request: '/request'
  },
  activities: {
    base: '/activities',
    comments: '/comments',
    likers: '/likers',
    like: '/like',
    unlike: '/unlike',
    draft: '/draft'
  },
  culturecards: {
    base: '/culture_cards',
    assigned: '/assigned',
    visited: '/visited',
    groups: '/culture_groups',
    culture_group_members: '/culture_group_members',
    list: '/list',
    visits: '/visits'
  }
};


export const API_URL_KEY = {
  event: 'event',
  group: 'group',
  identity: 'identity',
  user: 'user',
  notifications: 'notifications',
  messenger: 'messenger',
  card: 'card',
  cardV2: 'cardV2',
  mock: 'mock',
  tickets: 'tickets',

};
