<span [matMenuTriggerFor]="menu" 
  class="text-truncate d-block">{{text}}</span>
<mat-menu #menu="matMenu"
  xPosition="before"
  class="text-helper">
  <a mat-menu-item
    (click)="copyToClipboard()">
    <span class="pr-3">{{text}}</span>

    <button mat-button
      color="primary"
      (click)="copyToClipboard()"
      i18n="Copy label@@label.copy">
      Copy
    </button>
  </a>
</mat-menu>