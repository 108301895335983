<div class="container mt-7">
  <div class="auth-form d-flex justify-content-center">
    <div class="col-12 col-md-6 bg-wrap mt-5 login-form py-lg-3 px-lg-5 py-lg-5 px-lg-7"
      *ngIf="form">
      <form [formGroup]="form"
        (ngSubmit)="onSubmit()">

        <h2 class="text-center text-uppercase font-weight-bold">
          <span i18n="Mobile phone number header@@header.mobilePhone"
            *ngIf="isPhone && usernameObj">Mobile phone number</span>
          <span i18n="Email address header@@header.emailAddress"
            *ngIf="!isPhone && usernameObj">Email address</span>
          <br>
          <span *ngIf="!usernameObj"
            i18n="Enter email or phone to verify header@@header.enterPhoneOrEmail">
            Enter email or phone you want to verify
          </span>
        </h2>

        <p *ngIf="!isPhone && usernameObj"
          class="my-0"
          i18n=" Make sure you have an access to the email header@@header.makeSureEmail">
          Make sure you have an access to the email you entered
        </p>

        <p *ngIf="isPhone && usernameObj"
          class="my-0"
          i18n=" Make sure you have an access to the phone header@@header.makeSurePhone">
          Make sure you have an access to the phone you entered
        </p>

        <mat-form-field appearance="outline"
          class="w-100">
          <mat-label i18n="Phone label@@label.phone" *ngIf="isPhone && usernameObj">Phone</mat-label>
          <mat-label i18n="Email label@@label.email" *ngIf="!isPhone && usernameObj">Email</mat-label>
          <span matPrefix  *ngIf="isPhone">+47 &nbsp;</span>
          <input matInput
            formControlName="username"
            [type]="isPhone ? 'tel' : 'email'"
            autocapitalize="none">
          <mat-error *ngIf="form.controls.username.hasError('required')"
            i18n="Phone or email required error@@error.emailOrPhone.required">
            Phone or email is required
          </mat-error>
          <mat-error *ngIf="form.controls.username.hasError('pattern')">
            <span *ngIf="isPhone"
              i18n="Phone invalid error@@error.phoneInvalid">
              Please enter valid phone number
            </span>
            <span *ngIf="!isPhone"
              i18n="Email invalid error@@error.emailInvalid">
              Please enter valid email
            </span>
          </mat-error>
          <mat-error *ngIf="form.controls['username'].errors?.serverError">
            {{ form.controls['username'].errors?.serverError }}
          </mat-error>
        </mat-form-field>

        <div class="d-flex">
          <button mat-stroked-button
            type="button"
            color="primary"
            class="mr-2 mb-4"
            (click)="onCancelClick()">
            <span *ngIf="isSecondNameVerify" i18n="Not now button@@button.notNow">Not now</span>
            <span *ngIf="!isSecondNameVerify" i18n="Cancel button@@button.cancel"> CANCEL </span>
          </button>

          <button mat-raised-button
            type="submit"
            color="primary"
            class="mb-4 w-100"
            [disabled]="form.invalid || isLoading"
            i18n="Send me verification code button label@@button.sendVerificationCode">Send me verification code
          </button>
        </div>

      </form>
    </div>
  </div>
</div>