<div class="drop-down mat-elevation-z8"
  [@dialog]
  infiniteScroll
  cdkTrapFocus
  cdkTrapFocusAutoCapture
  [scrollWindow]="false"
  [immediateCheck]="true"
  [infiniteScrollDistance]="2"
  (scrolled)="paginator.loadMore()"
  [ngClass]="{'overflow-auto p-3': !injectedData?.data?.length}">
  <div class="notifications-heading d-flex justify-content-between"
    [ngClass]="{'p-3': injectedData?.data?.length}">
    <button
      class="position-absolute right-2 top-2 w-5 h-5 mt-0"
      aria-label="Close"
      mat-icon-button
      (click)="close()">
      <mat-icon class="color-gray-700">close</mat-icon>
    </button>

    <div class="d-flex flex-column justify-content-center mt-4">
      <div>
        <span class="heading-text"
          i18n="Notifications @@label.notificatons">Notifications</span>
        <span class="badge-label badge-label-accent round ml-2"
          *ngIf="injectedData?.meta?.total_unread">{{injectedData?.meta?.total_unread}}</span>
      </div>
    </div>
    <button id="mark-as-read-notification-button" mat-button
      color="primary"
      type="button"
      i18n="Mark as read @@button.markAsRead"
      class="mt-4"
      (click)="markAllAsRead()">Mark as read</button>
  </div>
  <div class="empty-notifications-area mt-2 d-flex flex-column justify-content-center text-center"
    *ngIf="!injectedData?.data?.length;else notifications">
    <img src="../../../../../assets/img/ic_empty_notification.svg"
      class="align-self-center"
      alt="Empty notifications">
    <span class="d-block mt-3"
      i18n="You don’t have notifications to review @@label.notificationsEmpty">You don’t have notifications to
      review</span>
  </div>
  <ng-template #notifications>
    <div class="text-left"
      *ngFor="let item of paginator.items">
      <div class="px-3 py-2 notification"
        [ngSwitch]="item.type"
        [ngClass]="{'new-notification': !item.is_read}"
        (click)="markNotificationAsRead(item)">
        <a *ngSwitchCase="'member:request'"
          [routerLink]="['/organisations',item.payload.organization.id]"
          [queryParams]="{tab:'members'}">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="Push requested membership @@label.pushRequestedMembership">
              {{item.payload.member.user.name}} requested membership.
            </span>
            <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Check details @@button.checkDetails">Check details</button>
          </div>
        </a>
        <a *ngSwitchCase="'member:accepted'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="You request for member was accepted @@label.pushYourRequestMembershipAccepted">
              {{item.user.name}}, your request for membership was accepted.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'member:cancelled'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="Your request for membership@@label.pushYourRequestForMembershipWasCancelled">
              {{item.user.name}}, your request to membership was cancelled.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:invite:accepted'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="accepted your organization invitation @@label.acceptedYourOrganizationInvitation">
              {{item.payload.organization.accepted_by}}, accepted your organization invitation.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:invite:decline'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="declined your organization invitation @@label.declinedYourOrganizationInvitation">
              {{item.payload.member.name}}, declined your organization invitation.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:invite:created'"
          [routerLink]="['/organisations',item.payload.organization.id]"
          [queryParams]="{'invitationToken': item.payload.invite.token, 'name': item.payload.organization.name}">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Invitation sent @@label.invitationSent">
              Invitation sent
            </span>
            <span i18n="You have been successfully invited @@label.youHaveBeenSuccessfullyInvitedUser">
              You have been successfully invited {{item.user.name}} to {{item.payload.organization.name}}.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'member:role:changed'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.organization.name}}
            </span>
            <span i18n="Your role was changed @@label.pushYourRoleWasChanged">
              Your role was changed to {{item.payload.role}}
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:updated'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.payload.event.name}}
            </span>
            <span i18n="Event change @@label.pushEventChanged">
              {{item.user.name}} please be advised that there has been an event change to your event booking.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:created'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span i18n="Event created @@label.eventCreated"
              class="font-weight-bold d-block mb-2">Event created</span>
            <span i18n="event wascreated @@label.eventWasCreated">
              «{{item.payload.event.name}}» was created.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:cancelled'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Cancellation notice@@label.pushCancellationNotice">
              Cancellation notice
            </span>
            <span i18n="Event cancelled @@label.pushEventCancelled">
              {{item.user.name}} we are sorry to inform you that {{item.payload.event.name}} which was scheduled on
              {{item.payload.event.start_at | date:'dd MMM yyyy , HH:MM'}} has been cancelled.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:attendee:in'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.user.name}}
            </span>
            <span i18n="You attend event@@label.pushAttendedEvent">
              You successfully attended event {{item.payload.event.name}}
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:attendee:admin'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="New attendee@@label.pushNewAttendedAdmin">
              New participant
            </span>
            <span i18n="You attend event@@label.pushNewAttendedAdminDesc">
              {{item.payload.attendee.name}} attends your event {{item.payload.event.name}}<br />
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:attendee:left'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Event left@@label.eventLeft">
              Event left
            </span>
            <span i18n="You left event@@label.pushLeftEvent">
              You successfully left event {{item.payload.event.name}}
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:attendee:left:admin'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Participant left@@label.participantLeft">
              Participant left
            </span>
            <span i18n="Participant left event@@label.pushParticipantLeftEvent">
              {{item.payload.userLeft.name }} left event {{item.payload.event.name}}
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:attendee:pending'"
           [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2">
              {{item.user.name}}
            </span>
            <span i18n="You have been added to the waiting list for event@@label.pushAttendedEventPending">
              You have been added to the waiting list for event {{item.payload.event.name}}
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:published'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span i18n="Request for create organisation approved@@label.pushRequestCreatingOrganisationApproved">
              {{item.user.name}} your request for creating {{item.payload.organization.name}} organization was approved.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:cancelled'"
          [routerLink]="['/organisations',item.payload.organization.id]">
          <div>
            <span i18n="Request for create organisation declined@@label.pushRequestCreatingOrganisationDeclined">
              {{item.user.name}} your request for creating {{item.payload.organization.name}} organization was declined.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'organization:created'"
          [routerLink]="['/dashboard/admin']">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Request for new organisation@@label.pushRequestForNewOrganisation">
              Request for a new organization
            </span>
            <span i18n="Request for new organisation desc@@label.pushRequestForNewOrganisationDesc">
              {{item.payload.organization.user.name}} sent request for creating new organization
            </span>
            <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Check details @@button.checkDetails">Check details</button>
          </div>
        </a>
        <a *ngSwitchCase="'event:cohost:requested'"
          [routerLink]="['/organisations',item.payload.cohost.id]"
          [queryParams]="{tab:'cohosts'}">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Co-host@@label.pushCoHost">
              Co-host
            </span>
            <span i18n="Co host request@label.pushCoHostRequest">
              «{{item.payload.organization.name}}» want to publish «{{item.payload.event.name}}» in your
              «{{item.payload.cohost.name}}» organisation.
            </span>
            <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Check details @@button.checkDetails">Check details</button>
          </div>
        </a>
        <a *ngSwitchCase="'event:cohost:approved'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Co-host@@label.pushCoHost">
              Co-host
            </span>
            <span i18n="Co-host request approved@@label.pushCoHostRequestApproved">
              «{{item.payload.organization.name}}» approved your request to publish «{{item.payload.event.name}}».
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:cohost:cancelled'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              id="Co-host@@label.coHost">
              Co-host
            </span>
            <span i18n="Co-host request declined@@label.pushCoHostRequestDeclined">
              «{{item.payload.organization.name}}» cancelled request to publish «{{item.payload.event.name}}».
            </span>
          </div>
        </a>

        <a *ngSwitchCase="'event:subsidiary:request'"
          [routerLink]="['/organisations',item.payload.cohost.id]"
          [queryParams]="{tab:'cohosts'}">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Subsidiary request@@label.pushSubsidiary">
              Subsidiary request
            </span>
            <span>
              <span i18n="Want to link hint@@hint.wantToLink">{{item.payload.subsidiary.name}} organization, wants to link </span>
              <span i18n="As parent org@@hint.asParentOrg"> {{item.payload.parent.name}} as a parent organization.</span>
            </span>

            <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Check details @@button.checkDetails">Check details</button>
          </div>
        </a>
        <a *ngSwitchCase="'event:subsidiary:approved'"
          [routerLink]="['/events', item.payload.subsidiary.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Subsidiary request@@label.pushSubsidiary">
              Subsidiary request
            </span>
            <span i18n="Subsidiary request request approved@@label.pushSubsidiaryRequestApproved">
              «{{item.payload.parent.name}}» approved your request to link «{{item.payload.subsidiary.name}}» as subsidiary.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'event:subsidiary:cancelled'"
          [routerLink]="['/events', item.payload.subsidiary.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              id="Subsidiary request@@label.coHost">
              Subsidiary request
            </span>
            <span i18n="Subsidiary request request declined@@label.pushSubsidiaryRequestDeclined">
              «{{item.payload.parent.name}}» cancelled request to link «{{item.payload.subsidiary.name}}» as subsidiary.
            </span>
          </div>
        </a>

        <a *ngSwitchCase="'messenger:message:created'"
          [routerLink]="['/chat', item.payload.thread.id]">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n=" New message in @@label.newMessage">
              New message in "{{item.payload.thread.name}}"
            </span>
            <span class="message-text">
              <!-- The one in middle is just temporary to show message nicely for messages without payload with author -->
              {{item.payload.author?.name}} {{item.payload.author?.name ? ':' : ''}} "{{item.payload.message.body}}"
            </span>
            <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Go to chat @@button.goToChat">Go to chat</button>
          </div>
        </a>

        <a *ngSwitchCase="'certificate:expired'"
          [routerLink]="['/profile/edit']">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Certificate expired @@label.certificateExpired">Certificate expired</span>
            <span i18n="certificate was expired. @@label.certificateWasExpired">
              «{{item.payload.certificate.name}}» certificate was expired. <br>
              {{item.payload.certificate.expired | date:'dddd MMM yyyy'}}
            </span>
            <!-- <button mat-button
              class="d-block px-0"
              color="primary"
              type="button"
              i18n="Request for prolonging @@button.requestForProlonging">Request for prolonging</button> -->
          </div>
        </a>
        <a *ngSwitchCase="'certificate:assigned'"
          (click)="openCertificateViewDialog(item.payload.stamp.id)">
          <div>
            <span *ngIf="item.payload.assigned_by"
              class="font-weight-bold d-block mb-2"
              i18n="New certificate @@label.newCertificate">New certificate</span>
            <span *ngIf="!item.payload.assigned_by"
              class="font-weight-bold d-block mb-2"
              i18n="New driver licence @@label.newDriverLicence">New driver licence</span>
            <span *ngIf="item.payload.assigned_by"
              i18n="Certificate assigned to you @@label.certificateAssigned">
              «{{item.payload.assigned_by.name}}» assigned to you «{{item.payload.certificate.name}}» certificate.
            </span>
            <span *ngIf="!item.payload.assigned_by"
              i18n="You added licence to your profile@@label.addedLicenceToYourProfile">
              You added «{{item.payload.certificate.name}}» licence to your profile.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'certificate:accepted'"
          [routerLink]="['/organisations',item.payload.organization.id]"
          [queryParams]="{tab:'members'}">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Certificate accepted @@label.certificateAccepted">Certificate accepted</span>
            <span i18n="Certufucate accepted by user @@label.certificateAcceptedByUser">
              {{item.payload.user.name}} accepted «{{item.payload.certificate.name}}» certificate.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'certificate:requested'"
           [routerLink]="['/organisations',item.payload.organization.id]"
           [queryParams]="{tab:'members'}">
          <div>
            <span class="font-weight-bold d-block mb-2"
              i18n="Certificate request @@label.certificateRequest">Certificate request</span>
            <span i18n="Certificate requested @@label.certificateRequested">
              {{item.payload.user.name}}, requested «{{item.payload.certificate.name}}» certificate.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'certificate:changed'"
          [routerLink]="['/profile/edit']">
          <div>
            <span class="font-weight-bold d-block mb-2">
              «{{item.payload.certificate.name}}»
            </span>
            <span i18n="Please be advised that there has been a certificate settings change. @@label.certificateChanged">
              Please be advised that there has been a certificate settings change.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'stamp:changed'"
          [routerLink]="['/profile/edit']">
          <div>
            <span class="font-weight-bold d-block mb-2">
              «{{item.payload.certificate.name}}»
            </span>
            <span i18n="Please be advised that there has been a global certificate settings change. @@label.stampChanged">
              Please be advised that there has been a global certificate settings change.
            </span>
          </div>
        </a>
        <a *ngSwitchCase="'slot:created'"
          [routerLink]="['/events',item.payload.event.id]">
          <div>
            <span i18n="Booking @@label.booking"
              class="font-weight-bold d-block mb-2">Booking</span>
            <span i18n="user have booked an event. @@label.eventBookedSlot">
              {{item.payload.participant?.name}} has booked {{item.payload.slot.start_at | date:'dd MMM yyyy'}}
              from {{item.payload.slot.start_at | date:'HH:mm'}} to {{item.payload.slot.end_at | date:'HH:mm'}} on
              «{{item.payload.event.name}}»
            </span>
          </div>
        </a>
      </div>
    </div>
  </ng-template>

  <friskus-spinner class="col-md-12"
    [loading]="paginator.isLoading()"></friskus-spinner>
  <div class="p-2 notifications-toggle d-lg-none">
    <button mat-raised-button
      color="primary"
      class="w-100"
      i18n="Close button@@button.close"
      (click)="close()">Close</button>
  </div>
</div>
