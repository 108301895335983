import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const TromsoMunicipality: UserMunicipality = {
  name: 'tromso',
  slogan: {
    value: 'Aktiviteter og frivillighet',
    id: 'banner.tromso', meaning: 'bannerSlogan', description: 'Slogan for Tromsø'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/f33fd667-5ad0-43d9-b954-73143f48f64f/tromso-background-image-friskus_2.jpg',
  bannerType: 'image',
  sunset: false,
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Opplevelseskortet',
  infoSlots: [
    {
      type: InfoType.TICKETS_CUSTOM,
      title: "Opplevelseskortet",
      url: 'https://tromso.kommune.no/natur-kultur-og-fritid/kultur-og-fritidstilbud/stotte-til-kultur-og-fritidsaktiviteter',
      minAge: 4,
      maxAge: 17,
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Billetter',
      minAge: 0,
      maxAge: 13,
      url: 'https://tromso.kommune.no/natur-kultur-og-fritid/kultur-og-fritidstilbud/stotte-til-kultur-og-fritidsaktiviteter',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'Turbo Tromsø',
      name: 'Turbo',
      url: 'https://tromso.kommune.no/natur-kultur-og-fritid/lane-og-leie-utstyr/lan-gratis-fritidsutstyr-hos-turbo'
    },
  ]
}
