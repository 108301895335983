import {storageName} from '@config/index';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  appPrefix = storageName.appPrefix;
  constructor() { }

  setItem(key: string, value: any): void {
    localStorage.setItem(`${this.appPrefix}-${key}`, value);
  }

  setObject(key: string, value: any): void {
    localStorage.setItem(`${this.appPrefix}-${key}`, JSON.stringify(value));
  }

  getItem(key: string): any {
    return localStorage.getItem(`${this.appPrefix}-${key}`);
  }

  getObject(key: string): any {
    return JSON.parse(localStorage.getItem(`${this.appPrefix}-${key}`));
  }

  removeItem(key: string): any {
    localStorage.removeItem(`${this.appPrefix}-${key}`);
  }

  clear(): void {
    localStorage.clear();
  }
}
