import { Component, Inject, OnInit }  from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type InformationDialogAction = {
    text: string;
    onClick?: () => void;
}

export type InformationDialogData = {
  title: string;
  text: string;
  primaryAction?: InformationDialogAction;
  secondaryAction?: InformationDialogAction;
  actionButtonsAlign?: "start" | "center" | "end";
  actionButtonsDirection?: "row" | "column";
}

@Component({
  selector: 'friskus-information-dialog',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationDialogComponent implements OnInit {
  public title: string;
  public text: string;
  public primaryAction?: InformationDialogAction;
  public secondaryAction?: InformationDialogAction;
  public actionButtonsAlign: InformationDialogData["actionButtonsAlign"]  = "end";
  public actionButtonsDirection: InformationDialogData["actionButtonsDirection"]  = "row";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InformationDialogData,
    private dialogRef: MatDialogRef<InformationDialogComponent>) {
    if (data) {
      this.title = data.title;
      this.text = data.text;
      this.primaryAction = data.primaryAction;
      this.secondaryAction = data.secondaryAction;
      this.actionButtonsAlign = data.actionButtonsAlign ?? this.actionButtonsAlign;
      this.actionButtonsDirection = data.actionButtonsDirection ?? this.actionButtonsDirection;
    }
  }

  ngOnInit() {}

  onPrimaryAction() {
    this.dialogRef.close(false);
    this.primaryAction?.onClick();
  }

  onSecondaryAction() {
    this.dialogRef.close(false);
    this.secondaryAction?.onClick();
  }

}
