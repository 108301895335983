import { Injectable } from '@angular/core';
import { createFormatter, EventInput } from '@fullcalendar/core';
import * as moment from 'moment';
import { DatesService } from '@services/dates/dates.service';
@Injectable({
  providedIn: 'root'
})
export class EventCalendarService {
  public timeFormat: any = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false
  };
  constructor( ) { }

  public mapEventCalendarItems(events): EventInput[] {
    const calendarEvents: EventInput[] = [];
    events.forEach(event => {
      if (event.event.rrule) {
        const calendarEvent: EventInput = {
          id: event.id,
          title: event.event.name,
          start: event.start_at,
          end: event.end_at,
          allDay: false,
          extendedProps: event,
          on_waiting_list: event.on_waiting_list,
        };

        if (moment(calendarEvent.end).isBefore(moment.now())) {
          calendarEvent.color = '#E76A6A';
        }
        if (event.event.is_canceled) {
          calendarEvent.color = '#7F7F7F';
          calendarEvent.title += $localize `:Event canceled|@@label.eventCanceled:(Event canceled)`;
        }

        if (event.on_waiting_list) {
          calendarEvent.className = 'slot-waitinglist';
        }
        calendarEvents.push(calendarEvent);
      }
    });

    return calendarEvents;
  }
}
