import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  transform(value: string) {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return $localize `:a few seconds ago label|@@label.fewSecAgo:a few seconds ago`;
    } else if (seconds <= 90) {
      return $localize `:a minute ago label|@@label.minAgo:a minute ago`;
    } else if (minutes <= 45) {
      return `${minutes} ${$localize `:minutes ago label|@@label.minsAgo:minutes ago`}`;
    } else if (minutes <= 90) {
      return $localize `:an hour ago label|@@label.hourAgo:an hour ago`;
    } else if (hours <= 22) {
      return `${hours} ${$localize `:hours ago label|@@label.hoursAgo:hours ago`}`;
    } else if (hours <= 36) {
      return $localize `:a day ago label|@@label.dayAgo:a day ago`;
    } else if (days <= 25) {
      return `${days} ${$localize `:days ago label|@@label.daysAgo:days ago`}`;
    } else if (days <= 45) {
      return $localize `:a month ago label|@@label.monthAgo:a month ago`;
    } else if (days <= 345) {
      return `${months} ${$localize `:months ago label|@@label.monthsAgo:months ago`}`;
    } else if (days <= 545) {
      return $localize `:a year ago label|@@label.yearAgo:a year ago`;
    } else { // (days > 545)
      return `${years} ${$localize `:years ago label|@@label.yearsAgo:years ago`}`;
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }
}
