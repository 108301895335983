import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OverlayService, CustomOverlayDialogConfig, DEFAULT_CONFIG } from '@services/overlay/overlay.service';
import { CustomOverlayRef } from '@services/overlay/overlay-ref';
import { Observable, Subject, Subscription, of, interval } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { PushNotificationsService } from '../../services/remote-api/push-notifications.service';
import { delay, mergeMap, take, takeUntil } from 'rxjs/operators';
import { CustomOverlayComponent } from '@services/overlay/overlay-component/overlay-component.component';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';
import { Store } from '@store';
import { PushNotification } from '@shared/models/push-notification.model';

export const NOTIFICATIONS_POOLING_DELAY = 3;

@Component({
  selector: 'friskus-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('overlayTrigger', { static: true }) overlayTrigger: ElementRef;
  public overlayConfig: CustomOverlayDialogConfig;
  public isOpened: boolean;
  public overlayREf: CustomOverlayRef;
  public isMobile: Observable<BreakpointState>;
  public isAbsoluteOverlay: boolean;
  public storeSub: Subscription;
  public isLoading: boolean;
  public runAnimation: boolean;
  public currentNotificationsLength: number;
  protected onDestroy = new Subject<void>();
  private intervalSub: Subscription;
  private messageNotificationsArr: any[] = [];

  constructor(
    private overlayService: OverlayService,
    private pushNotificationsService: PushNotificationsService,
    private screenType: ScreenTypeService,
    private authService: AuthService,
    private store: Store
  ) {
    this.isMobile = this.screenType.isHandset;
    this.overlayConfig = DEFAULT_CONFIG;
    this.isMobile.subscribe(result => {
      this.overlayConfig.globalPosition = result.matches;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    //  Initial notifications getting
    this.authService.authState
      .pipe(
        take(1),
        mergeMap(user => {
          return user ? this.pushNotificationsService.getNotifications() : of(null);
        })
      )
      .subscribe(notifications => {
        if (notifications) {
          this.overlayConfig.injectedDataList = notifications;
          this.currentNotificationsLength = notifications.meta.total_unread;
          if (this.overlayConfig.injectedDataList.meta.total_unread) {
            this.toggleAnimation();
          }
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      });

    // Notifications polling process
    this.authService.authState
      .pipe(
        takeUntil(this.onDestroy),
      )
      .subscribe(user => {
        if (user && !this.intervalSub) {
          this.runPollNotifications();
          this.isLoading = false;
        } else if (!user && this.intervalSub) {
          this.intervalSub.unsubscribe();
          this.intervalSub = null;
          this.overlayConfig.injectedDataList = [];
          this.currentNotificationsLength = 0;
        }
      });

    // Run update notifications after requests with 5 sec delay
    this.pushNotificationsService.updateNotifications$.pipe(
      delay(5000),
      takeUntil(this.onDestroy)
    )
    .subscribe(_ => {
      this.getNotifications();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  toggleAnimation() {
    this.runAnimation = true;
    of(true).pipe(
      delay(4000)
    ).subscribe(_ => {
      this.runAnimation = false;
    });
  }

  toggle(event) {
    event.preventDefault();
    if (this.isOpened) {
      this.overlayREf.close();
    } else {
      this.overlayREf = this.overlayService.open(this.overlayConfig, this.overlayTrigger, CustomOverlayComponent);
    }

    if (this.overlayREf) {
      this.overlayREf.afterClosed(_ => {
        this.isOpened = false;
        this.pushNotificationsService.updateNotifications$.next(true);
      });
    }
  }

  private getNotifications() {
    this.pushNotificationsService.getNotifications().subscribe(notifications => {
      this.trackMessageNotifications(notifications.data);
      if (this.currentNotificationsLength !== notifications.meta.total_unread) {
        this.toggleAnimation();
      }
      this.currentNotificationsLength = notifications.meta.total_unread;
      this.overlayConfig.injectedDataList = notifications;
    }, err => {
      console.log(err);
    });
  }

  private runPollNotifications() {
    const minute = 1000 * 60;
    this.intervalSub = interval(minute * NOTIFICATIONS_POOLING_DELAY).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(i => {
      this.getNotifications();
    });
  }

  private trackMessageNotifications(notifications: PushNotification[]) {
    if (!notifications || !notifications.length) return;
    const arr = notifications.filter(item => item.type === 'messenger:message:created' && !item.is_read);
    if (arr?.length) {

      if (!this.messageNotificationsArr.length) {
        this.messageNotificationsArr = arr;
        this.store.set('messengerArr', arr);
      } else {
        arr.forEach(notification => {
          const isInArray = this.messageNotificationsArr.find(unread => unread.id === notification.id);

          if (!isInArray) {
            this.messageNotificationsArr.push(notification);
            this.store.set('messengerArr', notification);
          }
        });
      }
    }
  }

}
