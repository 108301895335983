import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { STICKY_Y_OFFSET } from '@config/index';

@Component({
  selector: 'friskus-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isSticky: boolean;
  @ViewChild('footerElement', { static: true }) el: ElementRef<HTMLElement>;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= STICKY_Y_OFFSET;
  }

  ngOnInit() {
  }

}
