import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { CustomOverlayRef } from '@services/overlay/overlay-ref';
import { CUSTOM_DIALOG_DATA } from '@services/overlay/overlay.tokens';
import { GroupsService } from '../../remote-api/groups.service';
import { PushNotificationsService } from '@services/remote-api/push-notifications.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { InfiniteScrollPaginator } from '@shared/services/infinite-scroll-paginator.service';
import { MatDialog } from '@angular/material/dialog';
import { CertificateDetailsDialogComponent } from '@features/certificates/certificate-details-dialog/certificate-details-dialog.component';
import { MODAL_SIZE } from '@shared/constants/modals.const';
import { CertificatesService } from '@services/remote-api/certificates.service';


@Component({
  selector: 'friskus-overlay-component',
  templateUrl: './overlay-component.component.html',
  styleUrls: ['./overlay-component.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.0, 1, 1)' }),
        animate(100)
      ])
    ])
  ]
})
export class CustomOverlayComponent implements OnInit {
  public paginator: InfiniteScrollPaginator;
  eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    public certificatesService: CertificatesService,
    public groupsService: GroupsService,
    public pushNotificationsService: PushNotificationsService,
    public dialogRef: CustomOverlayRef,
    @Inject(CUSTOM_DIALOG_DATA) @Optional() public injectedData: any
  ) {
    this.paginator = new InfiniteScrollPaginator(page =>
      this.pushNotificationsService.getNotifications(page)
    );
  }

  ngOnInit() {
    this.paginator.getItems();
  }

  public close() {
    this.dialogRef.close();
  }

  public markAllAsRead() {
    this.pushNotificationsService.markAllAsRead().subscribe(success => {
      this.injectedData.meta.total_unread = 0;
      this.paginator.items.forEach(element => {
        element.is_read = true;
      });
      this.pushNotificationsService.updateNotifications$.next(true);
      this.close();
    }, err => {
      console.log(err);
    });
  }

  public markNotificationAsRead(notification) {
    this.pushNotificationsService.markAsRead(notification.id).subscribe(_ => {
      if (!notification.is_read) {
        notification.is_read = true;
        this.injectedData.meta.total_unread--;
      }
      this.pushNotificationsService.updateNotifications$.next(true);
      this.close();
    });
  }

  public openCertificateViewDialog(stampId) {
    this.certificatesService.getStampById(stampId).subscribe(res => {
      this.dialog.open(CertificateDetailsDialogComponent, {
        data: { ...res.data },
        maxWidth: MODAL_SIZE.medium,
        width: MODAL_SIZE.medium,
        panelClass: 'full-width-dialog',
        disableClose: true,
        autoFocus: false
      });
    });
  }
}
