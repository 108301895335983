import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "friskus-dropdown-chip",
  templateUrl: "./dropdown-chip.component.html",
  styleUrls: ["./dropdown-chip.component.scss"],
})
export class DropdownChipComponent {
  @Input()
  public label: string = "";
  @Input()
  public selected: boolean = false;
  @Input()
  public showDropdownIcon?: boolean = false;
  @Input()
  public showClearIcon?: boolean = true;

  @Output()
  public clickChip: EventEmitter<void> = new EventEmitter();
  @Output()
  public clear: EventEmitter<void> = new EventEmitter();

  @ViewChild("chip")
  public chipRef: ElementRef<HTMLElement>;

  public onClick() {
    this.clickChip.emit();
  }

  public onClear(event: Event) {
    event.stopPropagation();
    this.clear.emit();
  }
}
