export const ROUTES_MAP: any = {
  events: 'events',
  groups: 'organisations',
  auth: 'auth',
  styleguide: 'styleguide',
  profile: 'profile',
  search: 'search',
  dashboard: 'dashboard',
  notifications: 'notifications',
  chat: 'chat',
  minIdLogin: 'minid-login',
  leisureCard: 'fritidskortet',
  privateOrg: 'private-organisation',
  sunset: 'sunset',
  tickets: 'tickets',
  marketplace: 'marketplace',
  info: 'info',
};


export const BANK_LOGIN_URL = ROUTES_MAP.minIdLogin;


export const APP_SUPPORTED_LANGUAGES = ['en', 'no', 'nb', 'nn'];
