import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { VerificationComponent } from './verification/verification.component';
import { EditVerifyUsernameComponent } from './edit-verify-username/edit-verify-username.component';

export const ROUTES: Routes = [
  { path: 'code', component: VerificationComponent },
  { path: 'verify-username', component: EditVerifyUsernameComponent }
];

@NgModule({
  declarations: [VerificationComponent, EditVerifyUsernameComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
})
export class VerificationModule { }
