import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const InderoyMunicipality: UserMunicipality = {
  name: 'inderoy',
  slogan: {value: '', id: 'banner.inderoy', meaning: 'bannerSlogan', description: 'slogan for inderoy'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
  {
    type: InfoType.FRISKUS_LEISURE_CARD,
    title: 'Fritidskortet',
    minAge: 6,
    maxAge: 19,
  },
  ]
}
