<div class="position-relative d-flex flex-column w-100 bg-primary rounded justify-content-center align-items-center p-3 mb-4">
  <p class="color-accent-green font-size-sm w-full m-0"
     i18n="Municipality offers these tickets@@label.municipalityOffers"
     >{{municipalityName}} municipality offers</p>
  <p class="color-white font-size-lg font-weight-bold w-full m-0 mb-3"
    ><span i18n="Municipality offers@@hint.free">Free </span> 
    <ng-container *ngIf="municipalityTickets && !municipalityEquipment; else bothActivityAndEquipment">
      <span i18n="Municipality offers@@hint.freeActivity">activity</span> 
      <span i18n="Municipality offers@@hint.freeTickets">tickets to experiences</span>
    </ng-container>
    <ng-template #bothActivityAndEquipment>
      <ng-container *ngIf="municipalityTickets && municipalityEquipment; else onlyEquipment">
        <span i18n="Municipality offers@@hint.freeActivityAndEquipment">activity and </span> 
        <span i18n="Municipality offers@@hint.freeEquipment">equipment</span> 
        <span i18n="Municipality offers@@hint.freeTickets" >tickets to experiences</span>
      </ng-container>
      <ng-template #onlyEquipment>
        <span i18n="Municipality offers@@hint.freeEquipment">equipment</span> 
        <span i18n="Municipality offers@@hint.freeTickets">tickets to experiences</span>
      </ng-template>
    </ng-template>
  <div class="position-absolute top-full">
    <a
      [routerLink]="['/tickets', 'marketplace']"
      i18n="Explore marketplace@@label.exploreMarketplace"
      (click)="hasAccessToTicketsOrEquipment && $event.preventDefault()"
      [attr.disabled]="hasAccessToTicketsOrEquipment ? null : 'disabled'"
      [ngClass]="{'disabled-link': !hasAccessToTicketsOrEquipment}"
    >Explore marketplace</a>
  </div>
</div>
