<div mat-dialog-container
  class="d-flex flex-column h-100">
  <div class="text-right">
    <mat-icon matTooltip="Close"
      class="close-icon pointer"
      i18n-matTooltip="Close tooltip@@tooltip.close"
      (click)="onCloseClick()">close</mat-icon>
  </div>
  <div mat-dialog-content
    align="start"
    *ngIf="filterForm">
    <form [formGroup]="filterForm">
      <mat-form-field appearance="outline"
        class="w-100"
        color="primary"
        *ngIf="currentParticipantType === participantTypes.event && occurrences.length > 1">
        <mat-label i18n="Select day label@@label.selectDay">Select day</mat-label>
        <mat-select formControlName="occurrence"
          class="w-100">
          <mat-option [value]="null">
            <span i18n="All days@@label.allDays">All days</span>
          </mat-option>
          <ng-container *ngFor="let occurrence of occurrences">
            <mat-option [value]="occurrence"
              *ngIf="occurrence.published">
              {{occurrence.start_at | date : 'EEEE, d MMM y'}}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline"
        class="w-100"
        color="primary"
        *ngIf="currentParticipantType === participantTypes.organization">
        <mat-label i18n="Type of certificates label@@label.typeOfCertificates">Type of certificates:</mat-label>
        <mat-select formControlName="certificate"
          class="w-100"
          multiple>
          <ng-container *ngFor="let certificate of certificates;">
            <mat-option [value]="certificate.id">
              {{certificate.name}}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline"
        class="w-100"
        color="primary"
        *ngIf="currentParticipantType === participantTypes.organization">
        <mat-label i18n="Role label@@label.role">Role</mat-label>:
        <mat-select formControlName="role"
          class="w-100"
          multiple>
          <mat-option value="admin"
            i18n="Admin role option label@@roleOption.admin">Admin</mat-option>
          <mat-option value="moderator"
            i18n="Moderator role option label@@roleOption.moderator">Moderator</mat-option>
          <mat-option value="member"
            i18n="Member role option label@@roleOption.member">Member</mat-option>
          <mat-option value="applicant"
            i18n="Applicant role option label@@roleOption.applicant">Applicant</mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <hr>

      <mat-form-field appearance="outline"
        class="w-100"
        color="primary"
        *ngIf="currentParticipantType === participantTypes.organization">
        <mat-label i18n="By name label@@label.byName">By name</mat-label>
        <mat-select formControlName="name"
          class="w-100"
          (selectionChange)="nameFilterChanged()">
          <mat-option [value]="null">
            <span i18n="Default sorting@@label.defaultSorting">Default</span>
          </mat-option>
          <mat-option value="asc"
            i18n="Alphabetic asc label@@option.alphabeticAsc">A-Z</mat-option>
          <mat-option value="desc"
          i18n="Alphabetic desc label@@option.alphabeticDesc">Z-A</mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline"
        class="w-100"
        color="primary"
        *ngIf="currentParticipantType === participantTypes.organization">
        <mat-label i18n="By email label@@label.byEmail">By email</mat-label>
        <mat-select formControlName="email"
          class="w-100"
          (selectionChange)="emailFilterChanged()">
          <mat-option [value]="null">
            <span i18n="Default sorting@@label.defaultSorting">Default</span>
          </mat-option>
          <mat-option value="asc"
            i18n="Alphabetic asc label@@option.alphabeticAsc">A-Z</mat-option>
          <mat-option value="desc"
          i18n="Alphabetic desc label@@option.alphabeticDesc">Z-A</mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline"
        *ngIf="currentParticipantType === participantTypes.organization"
        class="w-100"
        color="primary">
        <mat-label i18n="By phone number label@@label.byPhone">By phone number</mat-label>
        <mat-select formControlName="phone"
          class="w-100"
          (selectionChange)="phoneFilterChanged()">
          <mat-option [value]="null">
            <span i18n="Default sorting@@label.defaultSorting">Default</span>
          </mat-option>
          <mat-option value="asc">0-9</mat-option>
          <mat-option value="desc">9-0</mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field>

      <!-- <mat-form-field appearance="outline"
        class="w-100"
        color="primary">
        <mat-label i18n="By phone label@@label.byPhone">By phone</mat-label>
        <mat-select formControlName="phone"
          class="w-100">
          <mat-option [value]="null">
            <span i18n="Default sorting@@label.defaultSorting">Default</span>
          </mat-option>
          <mat-option value="asc">
            0-9
          </mat-option>
          <mat-option value="desc">
            9-0
          </mat-option>
        </mat-select>
        <mat-icon matSuffix fontSet="material-icons-outlined" class="material-icons-round">arrow_drop_down</mat-icon>
      </mat-form-field> -->
    </form>
  </div>
  <div class="d-flex mt-auto pb-4 pt-0">
    <button class="mr-2 flex-grow-1"
      mat-stroked-button
      color="primary"
      (click)="onResetClick()"
      i18n="Reset button@@button.resett">Reset</button><!-- {{dialogData.controls.close}} -->
    <button mat-raised-button
      class="flex-grow-1"
      color="primary"
      (click)="onConfirmClick()"
      i18n="Apply button@@button.appy">
      apply
      <!-- {{dialogData.controls.apply}} -->
    </button>
  </div>
</div>
