import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {AuthGuard} from './guards/auth.guard';
import {GoogleMapsModule} from '@angular/google-maps';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonComponent} from './components/ui-components/button/button.component';
import {InputComponent} from './components/ui-components/input/input.component';
import {SelectComponent} from './components/ui-components/select/select.component';
import {TabsComponent} from './components/ui-components/tabs/tabs.component';
import {TabComponent} from './components/ui-components/tabs/tab.component';
import {FormMessageComponent} from './components/ui-components/form-message/form-message.component';
import {MaterialModule} from '../material/material.module';
import {UcWidgetModule} from 'ngx-uploadcare-widget';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {TreeChecklistComponent} from './components/ui-components/tree-checklist/tree-checklist.component';
import {AddressInputComponent} from './components/ui-components/address-input/address-input.component';
import {InputSuffixComponent} from './components/ui-components/input-suffix/input-suffix.component';
import {BlankAvatarComponent} from './components/ui-components/blank-avatar/blank-avatar.component';
import {MomentModule} from 'ngx-moment';
import {SnackbarComponent} from './components/ui-components/snackbar/snackbar.component';
import {CanDeactivateGuard} from './guards/can-deactivate.guard';
import {TextMaskModule} from 'angular2-text-mask';
import {OverlayService} from './services/overlay/overlay.service';
import {CustomOverlayComponent} from '@services/overlay/overlay-component/overlay-component.component';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {FooterComponent} from './components/footer/footer.component';
import {MunicipalityBannerComponent} from './components/municipality-banner/municipality-banner.component';
import {MainSearchComponent} from './components/main-search/main-search.component';
import {LeisureCardComponent} from './components/leisure-card/leisure-card.component';
import {PushNotificationsComponent} from './components/push-notifications/push-notifications.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {BrowserPlatformComponent} from './components/browser-platform/browser-platform.component';
import {MessengerIconComponent} from './components/messenger-icon/messenger-icon.component';
import {ContactPersonChatDialogComponent} from './components/dialogs/contact-person-chat-dialog/contact-person-chat-dialog.component';
import {LinkifyPipe} from './services/pipes/linkify.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FullCalendarModule} from '@fullcalendar/angular';
import {OrganizationSearchComponent} from './components/organization-search/organization-search.component';
import {UsersSearchComponent} from './components/ui-components/users-search/users-search.component';
import {
  MobileParticipantsFilterDialogComponent
} from './components/dialogs/mobile-participants-filter-dialog/mobile-participants-filter-dialog.component';
import {FileBadgeComponent} from './components/ui-components/file-badge/file-badge.component';
import {TextHelperComponent} from './components/ui-components/text-helper/text-helper.component';
import {ImageEditControlsComponent} from './components/ui-components/image-edit-controls/image-edit-controls.component';
import {SurveyPopupOverlayComponent} from './services/overlay/survey-popup-overlay/survey-popup-overlay.component';
import {EventCalendarComponent} from './components/event-calendar/event-calendar.component';
import {EventCalendarService} from './components/event-calendar/event-calendar.service';
import {MobileCalendarComponent} from './components/event-calendar/mobile-calendar/mobile-calendar.component';
import {EventCardComponent} from './components/event-card/event-card.component';
import {FileUploadButtonComponent} from './components/ui-components/file-upload-button/file-upload-button.component';
import {SwitchUserDialogComponent} from './components/dialogs/switch-user-dialog/switch-user-dialog.component';
import {CheckMunicipalityComponent} from './components/dialogs/check-municipality/check-municipality.component';
import {ChooseMunicipalityComponent} from './components/dialogs/choose-municipality/choose-municipality.component';
import {InputAutofocusDirective} from './components/ui-components/input-autofocus/input-autofocus.directive';
import {EventFilterDialogComponent} from './components/events/event-filter-dialog/event-filter-dialog.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {AnnouncementComponent} from './components/ui-components/announcement/announcement.component';
import {DeadlineDatePipe} from './services/pipes/deadline-date.pipe';
import {MomentPipe} from './services/pipes/moment.pipe';
import {TimeAgoPipe} from './services/pipes/timeago.pipe';
import {TimeMaskDirective} from './directives/time-mask.directive';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {InfiniteScrollComponent} from './components/infinite-scroll/infinite-scroll.component';
import {ProgressBarModule} from './components/progress-bar/progress-bar.module';
import {FocusTrapDirective} from './directives/focus-trap/focus-trap.directive';
import {AspectRatioImageComponent} from './components/aspect-ratio-image/aspect-ratio-image.component';
import {ShareCardComponent} from './components/dialogs/share-card/share-card.component';
import {DropdownChipComponent} from './components/dropdown-chip/dropdown-chip.component';
import {SortAZComponent} from './components/sort-az/sort-az.component';
import {TicketsMarketplaceLinkComponent} from './components/tickets-marketplace-link/tickets-marketplace-link.component';
import {InformationDialogComponent} from './components/dialogs/information/information.component';
import {BeforeUnloadGuard} from './guards/before-unload.guard';
import {MatNativeDateModule} from '@angular/material/core';
import {HelpBeaconComponent} from "@shared/components/help-beacon/help-beacon.component";
import {HelpBeaconMobileComponent} from "@shared/components/help-beacon-mobile/help-beacon-mobile.component";
import {VossBannerComponent} from "@shared/components/municipality-banner/custom/voss/voss-banner.component";

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
]);

const components = [
  FooterComponent,
  HeaderComponent,
  ButtonComponent,
  InputComponent,
  SelectComponent,
  TabsComponent,
  TabComponent,
  FormMessageComponent,
  ConfirmationDialogComponent,
  TreeChecklistComponent,
  AddressInputComponent,
  BlankAvatarComponent,
  SnackbarComponent,
  InputSuffixComponent,
  BlankAvatarComponent,
  CustomOverlayComponent,
  MunicipalityBannerComponent,
  MainSearchComponent,
  LeisureCardComponent,
  PushNotificationsComponent,
  BrowserPlatformComponent,
  MessengerIconComponent,
  ContactPersonChatDialogComponent,
  LinkifyPipe,
  DeadlineDatePipe,
  MomentPipe,
  TimeAgoPipe,
  TimeMaskDirective,
  OrganizationSearchComponent,
  UsersSearchComponent,
  SurveyPopupOverlayComponent,
  MobileParticipantsFilterDialogComponent,
  FileBadgeComponent,
  TextHelperComponent,
  ImageEditControlsComponent,
  EventCalendarComponent,
  MobileCalendarComponent,
  EventCardComponent,
  FileUploadButtonComponent,
  SwitchUserDialogComponent,
  CheckMunicipalityComponent,
  ChooseMunicipalityComponent,
  InputAutofocusDirective,
  EventFilterDialogComponent,
  AnnouncementComponent,
  SpinnerComponent,
  InfiniteScrollComponent,
  FocusTrapDirective,
  AspectRatioImageComponent,
  ShareCardComponent,
  HeaderComponent,
  DropdownChipComponent,
  SortAZComponent,
  TicketsMarketplaceLinkComponent,
  InformationDialogComponent,
  HelpBeaconComponent,
  HelpBeaconMobileComponent,
  VossBannerComponent
];

@NgModule({
  declarations: [...components],
  providers: [
    AuthGuard,
    CanDeactivateGuard,
    OverlayService,
    EventCalendarService,
    BeforeUnloadGuard,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UcWidgetModule,
    MomentModule,
    TextMaskModule,
    GalleryModule,
    FullCalendarModule,
    ScrollingModule,
    ProgressBarModule,
    InfiniteScrollModule,
    NgxMaterialTimepickerModule.setLocale("nb"),
    GoogleMapsModule,
    MatNativeDateModule,
  ],
  exports: [
    ...components,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UcWidgetModule,
    TextMaskModule,
    GalleryModule,
    ProgressBarModule,
    FullCalendarModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    GoogleMapsModule,

  ],
})
export class SharedModule {
}
