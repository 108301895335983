import {UserMunicipality} from '@shared/models/user-municipality.model';

export const DyroyMunicipality: UserMunicipality = {
  name: 'dyroy',
  slogan: {},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto'
}
