<div mat-dialog-container
  *ngIf="chatForm">
  <div class="text-right">
    <mat-icon matTooltip="Close"
      class="close-icon pointer"
      i18n-matTooltip="Close tooltip@@tooltip.close"
      (click)="onCloseClick()">close</mat-icon>
  </div>

  <h3 class="text-center mb-2 font-weight-bold"
    mat-dialog-title
    i18n="Send a message header@@dialog.sendMessage">Send a message</h3>
  <div mat-dialog-content>
    <form [formGroup]="chatForm">
      <div class="persons-wrap">
        <mat-selection-list
          formControlName="users"
          class="mb-3 pt-0">
          <ng-container *ngFor="let person of dialogData.contactPersons">
            <mat-list-option [checkboxPosition]="'before'"
              color="primary"
              [value]="person.id">
              <div class="d-flex align-items-center">
                <img [src]="person.thumbnail_url"
                  (error)="imgErrorHandler(person)"
                  alt="User"
                  *ngIf="person.thumbnail_url"
                  class="member-img mr-3">
                <friskus-blank-avatar [data]="person.name"
                  *ngIf="!person.thumbnail_url"
                  size="xsmall"
                  class="pr-3">
                </friskus-blank-avatar>
                <div>
                  <span class="d-block"> {{person.name}}</span>
                  <!-- <span class="text-label">{{person.phone || person.email}}</span> -->
                </div>
              </div>
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
      <div class="d-flex mb-2" *ngIf="chatForm.controls.files.value.length">
        <div *ngFor="let document of chatForm.controls.files.value; let i = $index;"
          class="d-flex p-2 bg-wrap mr-3 align-items-center">
          <a [href]="document.url"
            target="_blank"
            class="text-accent mr-3 font-size-md text-decoration-none">
            {{document.name}}
          </a>
      
          <button mat-icon-button
            type="button"
            aria-label="Delete file"
            matTooltip="Delete file"
            i18n-matTooltip="Delete file tooltip@@tooltip.deleteFile"
            (click)="deleteFile(i)">
            <mat-icon fontSet="material-icons-outlined"
              class="material-icons-round text-accent">clear</mat-icon>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <mat-form-field appearance="outline"
          class="w-100 mr-3">
          <mat-label aria-hidden="true" i18n="Type a message label@@label.typeMessage">Type a message</mat-label>
          <textarea matInput
            placeholder="Type a message"
            i18n-placeholder="Type a message label@@label.typeMessage"
            aria-label="Type a message"
            i18n-aria-label="Type a message label@@label.typeMessage"
            formControlName="body"
            rows="3">
          </textarea>
          <mat-error *ngIf="chatForm.controls['body'].hasError('required')"
            i18n="Message is required error@@error.message.required">
            Message is required
          </mat-error>
          <mat-error *ngIf="chatForm.controls['body']?.errors?.serverError">
            {{ chatForm.controls['body'].errors?.serverError}}
          </mat-error>
        </mat-form-field>
        <friskus-file-upload-button (fileUploaded)="fileUploaded($event)"></friskus-file-upload-button>
      </div>
    </form>
  </div>

  <div mat-dialog-actions
       align="end"
       class="pb-4 pt-0 mt-3">

    <button mat-stroked-button
            type="button"
            color="primary" class="mr-2"
            (click)="onCloseClick()">
      <span i18n="Back button@@button.back">Back</span>
    </button>
    <button mat-raised-button
      color="primary" class="mr-2"
      type="submit"
      [disabled]="!chatForm.valid || isLoading"
      (click)="sendMessage()">
      <span *ngIf="!isLoading"
        i18n="Send button@@button.send">Send</span>
      <mat-spinner *ngIf="isLoading"
        class="mx-auto"
        [diameter]="20"
        [strokeWidth]="2"></mat-spinner>
    </button>
  </div>
</div>
