import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SenjaMunicipality: UserMunicipality = {
  name: 'senja',
  slogan: {value: '', id: 'banner.senja', meaning: 'bannerSlogan', description: 'slogan for senja'},
  data: {},
  bannerURL: 'https://ucarecdn.com/4938211b-df24-4ce4-9471-575d5ffd2d8d/Gisundbrua2.JPG',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Gode ordninger',
  infoSlots: [
   {
      type: InfoType.FRISKUS_TICKETS,
      name: 'Senja',
      minAge: 6,
      maxAge: 19,
      },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: "Lån av utstyr",
      url: 'https://www.senja.kommune.no/tjenester/kultur-idrett-og-aktiviteter-lag-og-foreninger/idrett-og-friluftsliv/lan-av-utstyr/'
    },
    {
      type: InfoType.FUNDING,
      title: "Tilskuddsordninger",
      url: 'https://www.senja.kommune.no/tjenester/kultur-idrett-og-aktiviteter-lag-og-foreninger/tilskudd-stipender-og-priser/',
    },
    {
      type: InfoType.OTHER,
      title: "Fritidskasse for barn og unge - Senja kommune",
      url: 'https://www.senja.kommune.no/tjenester/barn-og-familie/fritidskasse/',
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {title: 'Alt for eldre', url: 'https://www.senja.kommune.no/alt-for-eldre/'},
      ]
    },
  ]
}
