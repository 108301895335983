import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const BjerkreimMunicipality: UserMunicipality = {
  name: 'bjerkreim',
  slogan: {value: 'AKTIVITETER OG FRIVILLIGE OPPDRAG - I Bjerkreim', id: 'banner.bjerkreim', meaning: 'bannerSlogan', description: 'slogan for bjerkreim'},
  data: {
    headerDescription: '',
    amount: 0,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/d3378f5c-9083-4927-a0f0-1ca31928db9e/DestinasjonBjerkreim_KevinMolstad_Hengekoye.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Nyttige ordninger',
   infoSlots: [
   {
      type: InfoType.FUNDING,
      url: 'https://www.bjerkreim.kommune.no/soknadssenter/',
    },
   {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'Lån av gratis utstyr',
      name: 'Dalane Friluftsråd',
      url: 'http://www.xn--dalanefriluftsrd-rob.no/utstyrsbank/utstyrsbank-bjerkreim',
    },
    {
      type: InfoType.VENUE,
      url: 'https://www.bjerkreim.kommune.no/priser-og-gebyr/priser-div-utleie-og-salg/priser-utleie-kommunale-bygg/',
    },
     ]
}
