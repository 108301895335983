import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  DateRangeFilterValue,
  DistanceFromFilterValue,
  Filter,
  FilterConfig,
  FilterType,
  isDateRangeFilter,
  isDateRangeFilterConfig,
  isDateRangeFilterValue,
  isDistanceFromFilter,
  isDistanceFromFilterConfig,
  isMultiSelectFilter,
  isMultiSelectFilterConfig,
  isMultiSelectFilterValue,
  isQuickFilter,
  isQuickFilterConfig,
  isQuickFilterItem,
  isSelectFilterValue,
  isSingleSelectFilter,
  isSingleSelectFilterConfig,
  MultiSelectFilterValue,
  SelectFilterValue,
} from "@features/filters/services/filters.types";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilterService, GLOBAL_FILTER_GROUP } from "./filter.service";
import { TransitionOldToNewFiltersService } from "./transition-old-to-new-filters.service";

@Injectable({
  providedIn: "root",
})
export class FilterUrlUtilsService {
  constructor(
    private router: Router,
    private filterService: FilterService,
    private transitionOldToNewFiltersService: TransitionOldToNewFiltersService
  ) {
    this.filterService.Filter$.subscribe(() => this.updateUrlQuery());
  }

  public getQueryParamObject$(
    filterGroupId: string,
    includeGlobalFilters = true
  ): Observable<{ [key: string]: string }> {
    return this.filterService.FiltersMatchingGroupId$(filterGroupId).pipe(
      map(() => {
        return this.getQueryParamObject(filterGroupId, includeGlobalFilters);
      })
    );
  }

  /**
   * Reduce each type of filter into its parameter form,
   * taking into account that keys with multiple values
   * should be structured as arrays.
   * E.g:
   * {
   *     age_group: "adult",
   *     municipalities: ["xxx-xxx-xxx", "yyy-yyy-yyy"],
   * }
   *
   */
  public getQueryParamObject(filterGroupId: string, includeGlobalFilters = true) {
    // we should not process quick filters as those are only wrappers around the ACTUAL filters included in the quick filter
    const filters = this.filterService.Filter$.value.filter(
      (f) =>
        (
          f.filterGroupId === filterGroupId ||
          (includeGlobalFilters && f.filterGroupId === GLOBAL_FILTER_GROUP)
        ) &&
        !isQuickFilter(f)
    );

    if (filters.length === 0) return {};

    return filters.reduce((acc, cur) => {
      if (isMultiSelectFilter(cur)) {
        return this.reduceMultiSelectFilter(acc, cur);
      } else if (isSingleSelectFilter(cur)) {
        return this.reduceSingleSelectFilter(acc, cur);
      } else if (isDateRangeFilter(cur)) {
        return this.reduceDateRangeFilter(acc, cur);
      } else if (isDistanceFromFilter(cur)) {
        return this.reduceDistanceFromFilter(acc, cur);
      } else if (isQuickFilterItem(cur)) {
        return this.reduceQuickFilterItem(acc, cur);
      } else {
        return acc;
      }
    }, {} as { [key: string]: string | string[] });
  }

  public getFilterQueryParamString(filterGroupId: string) {
    const params = this.getQueryParamObject(filterGroupId);
    return Object.entries(params).reduce((acc, [key, val], i) => {
      if (i !== 0) acc += "&";
      if (Array.isArray(val)) {
        acc += val.reduce(
          (str, part, j) => `${str}${j !== 0 ? "&" : ""}${key}[]=${part}`,
          ""
        );
      } else {
        acc += `${key}=${val}`;
      }
      return acc;
    }, "");
  }

  public async parseFiltersFromUrl(filterConfigs: FilterConfig[]) {
    const urlFilterStr = new URLSearchParams(window.location.search).get(
      "filters"
    );

    // Add filters from old filter solution
    await this.transitionOldToNewFiltersService.parseOldMunicipalityFilter(
      filterConfigs
    );

    if (!urlFilterStr) return;

    const urlFilters = urlFilterStr
      .split("(AND)")
      .map((urlFilter) => urlFilter.split("(EQ)"));

    urlFilters.forEach(([urlFilterKey, urlFilterValue]) => {
      if (!urlFilterKey || !urlFilterValue) return;

      const filterConfig = filterConfigs.find(
        (fc) => fc.filterGroupId + fc.filterId === urlFilterKey
      );

      if (!filterConfig) return;

      if (isMultiSelectFilterConfig(filterConfig)) {
        const filterValueParsed: MultiSelectFilterValue<string> = {
          indices: [],
          value: urlFilterValue.split(",").map((filterValue) => {
            const [optionValue, lock, subOptionString] =
              filterValue.split("$$");
            const subOptionValues = subOptionString?.split("$$$");
            return {
              value: optionValue,
              label: "",
              lock: lock === "true",
              subOptions: subOptionValues?.map((x) => ({
                label: "",
                value: x,
              })),
            };
          }),
        };

        const filter: Filter<any> = {
          ...filterConfig,
          filterValue: filterValueParsed,
        };

        this.filterService.addFilter(filter);
      } else if (isSingleSelectFilterConfig(filterConfig)) {
        const filter: Filter<SelectFilterValue<string>> = {
          ...filterConfig,
          filterValue: {
           index: -1,
           value: {
             label: "",
             value: urlFilterValue,
           },
          }
        };

        this.filterService.addFilter(filter);
      } else if (isDateRangeFilterConfig(filterConfig)) {
        const [fromDate, toDate] = urlFilterValue.split(",");

        if (!fromDate || !toDate) return;

        const filter: Filter<DateRangeFilterValue> = {
          ...filterConfig,
          filterValue: {
            from: new Date(fromDate),
            to: new Date(toDate),
          },
        };

        this.filterService.addFilter(filter);
      } else if (isDistanceFromFilterConfig(filterConfig)) {
        const [latitude, longitude, radius, address] = urlFilterValue.split(",");

        if (!latitude || !longitude || !radius || !address) return;

        const filter: Filter<DistanceFromFilterValue> = {
          ...filterConfig,
          filterValue: {
            location: {
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
              address: address?.replace(/\|/g, ","),
            },
            radius: parseFloat(radius),
          }
        };

        this.filterService.addFilter(filter);
      } else if (isQuickFilterConfig(filterConfig)) {
        this.filterService.addFilter({
          ...filterConfig,
          filterValue: filterConfig.filters,
        });
      }
    });
  }

  private reduceMultiSelectFilter(
    acc: { [key: string]: string | string[] },
    cur?: Filter<MultiSelectFilterValue<any>>
  ) {
    if (!cur || !cur.queryParamName) return acc;

    let result = {...acc};
    if (isMultiSelectFilterConfig(cur) && cur.subOptionsQueryParamName) {
      const subOptionParamValue = cur.filterValue.value.reduce(
        (acc, x) => [...acc, ...(x.subOptions?.map((y) => y.value) ?? [])],
        [] as string[]
      );
      if (subOptionParamValue.length > 0) {
        result[cur.subOptionsQueryParamName as string] = subOptionParamValue;
      }
    }

    const current = acc[cur.queryParamName as string];
    if (current) {
      result = {
        ...result,
        [cur.queryParamName as string]: [
          ...(Array.isArray(current) ? current : [current]),
          ...cur.filterValue.value.map((v) => v.value),
        ],
      };
    } else {
      result = {
        ...result,
        [cur.queryParamName as string]: cur.filterValue.value.map(
          (v) => v.value
        ),
      };
    }
    return result;
  }

  private reduceSingleSelectFilter(
    acc: { [key: string]: string | string[] },
    cur?: Filter<SelectFilterValue<any>>
  ) {
    if (!cur || !cur.queryParamName) return acc;

    if (typeof acc[cur.queryParamName as string] === "string") {
      return {
        ...acc,
        [cur.queryParamName as string]: [
          acc[cur.queryParamName as string],
          cur.filterValue.value.value,
        ],
      };
    }

    if (!cur?.filterValue?.value) {
      return acc;
    }
    return {
      ...acc,
      [cur.queryParamName as string]: cur.filterValue.value.value,
    };
  }

  private reduceDateRangeFilter(
    acc: { [key: string]: string | string[] },
    cur?: Filter<DateRangeFilterValue>
  ) {
    if (!cur || !cur.queryParamName) return acc;

    return {
      ...acc,
      start_at: (moment(cur.filterValue.from)).format('YYYY-MM-DD'),
      end_at: (moment(cur.filterValue.to)).format('YYYY-MM-DD'),
    };
  }

  private reduceDistanceFromFilter(
    acc: { [key: string]: string | string[] },
    cur?: Filter<DistanceFromFilterValue>
  ) {
    if (!cur || !cur.queryParamName) return acc;

    return {
      ...acc,
      "distance[lat]": cur.filterValue.location.latitude,
      "distance[lng]": cur.filterValue.location.longitude,
      "distance[radius]": cur.filterValue.radius,
    };
  }

  private reduceQuickFilterItem(
    acc: { [key: string]: string | string[] },
    cur?: Filter<any>
  ) {
    if (!cur || !cur.queryParamName) return acc;

    if (Array.isArray(cur.queryParamName)) {
      return {
        ...acc,
        ...cur.queryParamName.reduce((paramObj, name, i) => {
          if (acc[name]) {
            return { ...acc, [name]: [acc[name], cur.filterValue[i]] };
          }
          return {
            ...paramObj,
            [name]: cur.filterValue[i],
          };
        }, {}),
      };
    } else {
      if (acc[cur.queryParamName]) {
        return {
          ...acc,
          [cur.queryParamName]: [acc[cur.queryParamName], cur.filterValue],
        };
      }
      return { ...acc, [cur.queryParamName]: cur.filterValue };
    }
  }

  private isFilterValueEmpty(value: any): boolean {
    if (isSelectFilterValue(value)) {
      return (
        (!value.value || (value as any).length === 0) &&
        (value.index === null ||
          value.index === -1 ||
          value.index === undefined)
      );
    } else if (isMultiSelectFilterValue(value)) {
      return (
        (!value.value || value.value.length === 0) &&
        (value.indices === null ||
          value.indices === undefined ||
          value.indices.length === 0)
      );
    } else if (isDateRangeFilterValue(value)) {
      return !value || !value.from || !value.to;
    } else {
      return value && value.hasOwnProperty("value") ? !value.value : !value;
    }
  }

  public updateUrlQuery() {
    if (this.filterService.Filter$.value.length === 0) return;

    const query = this.filterService.Filter$.value.reduce((acc, cur, i) => {
      if (
        cur.type === FilterType.QuickFilterItem ||
        this.isFilterValueEmpty(cur.filterValue)
      )
        return acc;

      if (isMultiSelectFilter(cur)) {
        const urlFilterValues = cur.filterValue.value.reduce(
          (optionsAcc, value) => [
            ...optionsAcc,
            {
              option: value.value,
              lock: value.lock ?? false,
              subOptions: value.subOptions?.map((x) => x.value).join("$$$"),
            },
          ],
          [] as { option: string; lock: boolean; subOptions?: string }[]
        );

        if (i !== 0) acc += "(AND)";

        const queryParamId = `${cur.filterGroupId}${cur.filterId}`;
        return `${acc}${queryParamId}(EQ)${urlFilterValues.map(
          (x) =>
            x.option + "$$" + x.lock + (x.subOptions ? "$$" + x.subOptions : "")
        )}`;
      } else if (isSingleSelectFilter(cur)) {
        const queryParamId = `${cur.filterGroupId}${cur.filterId}`;
        return `${acc}${i !== 0 ? "(AND)" : ""}${queryParamId}(EQ)${
          cur.filterValue.value.value
        }`;
      } else if (isDateRangeFilter(cur)) {
        const queryParamId = `${cur.filterGroupId}${cur.filterId}`;

        const from = (moment(cur.filterValue.from)).format('YYYY-MM-DD');
        const to = (moment(cur.filterValue.to)).format('YYYY-MM-DD');

        return `${acc}${i !== 0 ? "(AND)" : ""}${queryParamId}(EQ)${from},${to}`;
      } else if (isDistanceFromFilter(cur)) {
        const queryParamId = `${cur.filterGroupId}${cur.filterId}`;
        return `${acc}${i !== 0 ? "(AND)" : ""}${queryParamId}(EQ)${
          cur.filterValue.location.latitude
        },${cur.filterValue.location.longitude},${cur.filterValue.radius},${cur.filterValue.location.address?.replace(/,/g, "|") ?? ""}`;
      } else if (isQuickFilter(cur)) {
        const queryParamId = `${cur.filterGroupId}${cur.filterId}`;
        return `${acc}${i !== 0 ? "(AND)" : ""}${queryParamId}(EQ)${true}`;
      }
      return acc;
    }, "");

    const urlParams = { filters: query };

    this.router.navigate([], {
      queryParams: urlParams,
      replaceUrl: true,
      queryParamsHandling: "merge",
    });
  }
}
