import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { environment } from '@environments/environment';
import { UploadFilesTermsService } from '@services/upload-files-terms/upload-files-terms.service';
import { UploadFile } from '@shared/models/uploads.model';
import { UcWidgetCustomComponent } from 'ngx-uploadcare-widget';

@Component({
  selector: 'friskus-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent implements OnInit {
  @Output() fileUploaded: EventEmitter<UploadFile> = new EventEmitter();
  @ViewChild(UcWidgetCustomComponent) widgetCustomComponent: UcWidgetCustomComponent;

  public uploadTabs = {
    documents: 'file url gdrive dropbox onedrive',
    images: 'file url camera facebook gdrive gphotos dropbox instagram flickr onedrive'
  };
  public upoloadConfig = {
    imagesOnly: false,
    tabs: this.uploadTabs.documents,
    publicKey: environment.uploadCareKey,
    multiple: false
  };
  public document: UploadFile;

  constructor(
    private uploadFilesTermsService: UploadFilesTermsService
  ) { }

  ngOnInit() {
  }

  public openUploadFile() {
    this.uploadFilesTermsService.isTermsAccepted();
    this.uploadFilesTermsService.uploadTermsAccepted.subscribe(isAccepted => {
      if (isAccepted) {
        this.widgetCustomComponent.tabs = this.uploadTabs.documents;
        this.widgetCustomComponent.imagesOnly = false;
        this.widgetCustomComponent.openDialog();
      }
    });
  }

  public onDocumentLoad(data) {
    const file: UploadFile = { url: data.cdnUrl, name: data.name, id: data.uuid };
    this.fileUploaded.emit(file);
  }

}
