import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class ScreenTypeService {

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  get isHandset() {
    return this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape, '(max-width: 991px)']);
  }

  get isTablet() {
    return this.breakpointObserver.observe(Breakpoints.Tablet);
  }
  get isWeb() {
    return this.breakpointObserver.observe([Breakpoints.WebLandscape, Breakpoints.WebPortrait]);
  }
  get isPortrait() {
    return this.breakpointObserver.observe('(orientation: portrait)');
  }

  get isLandscape() {
    return this.breakpointObserver.observe('(orientation: landscape)');
  }
}
