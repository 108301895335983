<div class="banner-container">
  <img #img
       (error)="bannerImgErrorHandler($event)"
       *ngIf="bannerValue?.bannerType ==='image'"
       class="video"
       [ngClass]="{'default': bannerValue.name === 'default'}"
       src="{{bannerValue?.bannerURL}}" alt="Banner" title="Banner"/>
  <!-- Optional: some overlay text to describe the video -->
  <div class="content"
       *ngIf="bannerValue">
    <div [ngClass]="{
      'container': bannerValue.name === 'default',
      'w-100 h-100': bannerValue.name !== 'default'}">

      <div class="slogan-container"
           [ngClass]="{'default': bannerValue.name === 'default'}">
        <div class="banner-logo" *ngIf="bannerValue.slogan.logoURL">
          <img src="{{bannerValue.slogan.logoURL}}"/>
        </div>
        <div class="slogan text-uppercase"
             [ngClass]="{'col-lg-8 text-left px-0': bannerValue.name === 'default'}"
             [innerHTML]="sloganWithTranslation">
        </div>
      </div>

      <div class="search-container px-lg-0"
           [ngClass]="{'px-4': bannerValue.name !== 'default'}">
        <div class="search  mx-auto"
             [ngClass]="{'col-12 ml-0 px-0 default': bannerValue.name === 'default'}">
          <friskus-main-search (chosen)="handleSearchItemChosen($event)" [insideSidenav]="false"></friskus-main-search>
        </div>
      </div>
    </div>
  </div>
</div>
