import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MunicipalitiesService } from '@services/remote-api/municipalities.service';
import { Router } from '@angular/router';

@Component({
  selector: 'friskus-municipality-banner-custom-voss',
  templateUrl: './voss-banner.component.html',
  styleUrls: ['./voss-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VossBannerComponent implements OnInit {

  constructor(
    private municipalitiesService: MunicipalitiesService,
    private cdr: ChangeDetectorRef,
    private router: Router,

  ) { }

  ngOnInit() {
  }

  public handleSearchItemChosen(item) {
    this.router.navigate(item.itemRoute, item.query);
  }

  public filterEvents() {
    //PROD
    window.location.href = `/events?filters=global_filters_municipalities(EQ)e172af93-bfa0-48b6-8505-823988b61c06(AND)events-filterscategories(EQ)4b3931e8-fb87-43ed-864d-0c75f3617973,87c373bf-626a-4c74-a5c9-70c9bcf653ff`;

    //DEV
    //window.location.href = `/events?filters=global_filters_municipalities(EQ)bd3adda6-904e-4f8d-a15d-edf7f2fa731c(AND)events-filterscategories(EQ)bb5eea2c-ed42-4b37-8f3c-d3fd22c0bd12`;
  }
}
