import {UserMunicipality} from "@shared/models/user-municipality.model";

export const FrolandMunicipality: UserMunicipality = {
  name: 'froland',
  slogan: {value: '', id: 'banner.froland', meaning: 'bannerSlogan', description: 'slogan for froland'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
}
