import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(link: string): string {
    return this.linkify(link);
  }

  private linkify(plainText): string {
    if (!plainText) {
      return '';
    }

    let replacedText;
    let replacePattern1;
    let replacePattern2;
    let replacePattern3;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-ZÆØÅÉ0-9+&@#\/%?=~_|!:,.;]*[-A-ZÆØÅÉ0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a href="$1" class="link" target="_blank">$1</a>');

    // URLs starting with "". (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" class="link" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
    replacePattern3 = /([a-zæøåéA-ZÆØÅÉ0-9._-]+@[a-zæøåA-ZÆØÅÉ0-9._-]+\.[a-zæøåéA-ZÆØÅÉ0-9_-]+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" class="link">$1</a>');

    return replacedText;
  }

}
