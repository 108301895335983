import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class MetaService {
  private renderer: Renderer2;

  constructor(
    private meta: Meta,
    private title: Title,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    // private gtmService: GoogleTagManagerService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // push GTM data layer for every visited page
  pushGtmTag(route): void {
    const gtmTag = {
      event: 'page',
      pageName: route.url
    };

    // this.gtmService.pushTag(gtmTag);
  }

  unsetRobots(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  addMetaProperty(metaPropertyName: string, metaContent: string) {
    this.meta.addTag({ property: metaPropertyName, content: metaContent }, true);
  }

  updateMetaProperty(metaProperty: string, metaText: string): void {
    this.meta.updateTag(
      {
        property: metaProperty,
        content: metaText
      }
    );
  }

  updateMetaName(metaName: string, metaText: string): void {
    this.meta.updateTag(
      {
        name: metaName,
        content: metaText
      }
    );
  }

  setTitle(title: string): void {
    this.title.setTitle(title);
  }

  addToHeader(name, content): void {
    let script = this.renderer.createElement('script');
    script.type = name;
    script.text = content;
    this.renderer.appendChild(this._document.head, script);
  }

  removeRobots(): void {
    this.meta.removeTag('name="robots"');
  }

  enableProdRobots(): void {
    if (environment.production) {
      this.removeRobots();
    }
  }
}
