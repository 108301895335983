<div class="event-container bg-wrap pointer position-relative overflow-hidden">

  <div *ngIf="isOnWaitingList" class="position-absolute right-0 top-2 rounded-left bg-accent-blue z-index-100 px-2 py-1">
    <span class="font-size-sm" i18n="Waiting list@@label.waitingList">Waiting list</span>
  </div>


  <a [routerLink]="routerLink">
    <div class="event-img d-flex flex-column"
      [ngClass]="{'canceled': event.is_canceled || isEnded || event.is_completed}">
      <friskus-aspect-ratio-image [src]="backgroundImg"></friskus-aspect-ratio-image>

      <div class="inset-0 p-3" *ngIf="event.is_free">
        <div class="card-label d-inline-flex align-items-center py-2 px-3">
          <span i18n="Free entry label@@label.freeEntry">Free entry</span>
          <img src="../../../../assets/icons/ic_mark_card.svg" alt="" class="ml-2 rounded-circle">
        </div>
      </div>

      <div class="inset-0 d-flex justify-content-between mb-2"
        [ngClass]="event.is_canceled ? 'mt-auto' : 'mt-2'">
        <span class="d-block badge-label badge-label-neutral mx-2 text-uppercase w-100 text-center p-2"
          *ngIf="event.is_canceled"
          i18n="Canceled label@@label.canceled">Canceled</span>
        <span *ngIf="event.is_completed"
          class="d-block badge-label badge-label-neutral mx-2 text-uppercase w-100 text-center p-2"
          i18n="Completed label@@label.completed">Completed</span>
        <span class="d-block badge-label badge-label-neutral mx-2"
          *ngIf="isEnded"
          i18n="Ended label@@label.ended">Ended</span>
        <div class="d-flex ml-auto align-items-end">
          <span class="d-flex badge-label badge-label-popular mx-2"
            *ngIf="event.attendees_count >= popularEventsNumber && !isEnded && !event.is_canceled">
            <mat-icon fontSet="material-icons-outlined"
              class="material-icons-round mr-2">thumb_up</mat-icon>
            <span i18n="Popular label@@label.popular">Popular</span>
          </span>
          <span class="d-block badge-label badge-label-primary mx-2"
            *ngIf="event.is_attendee"
            i18n="Attending label@@label.attending">Attending</span>
        </div>
      </div>
    </div>

    <div class="px-3 pt-3">
      <p class="mt-0 mb-2 text-label text-truncate card-date">
        <span class="text-capitalize">{{eventTimes.startDate}}</span>
        <span class="text-capitalize"
          *ngIf="!isOneDayEvent && event.start_at"> - {{eventTimes.endDate}}</span>
        <span *ngIf="rrule?.weekdays">&nbsp;(
          <ng-container *ngFor="let weekday of rrule.weekdays; let i = index">
            <span [ngSwitch]="weekday">
              <span *ngSwitchCase="days.SU"
                i18n="sunday abbreviation label@@label.su">Su</span>
              <span *ngSwitchCase="days.MO"
                i18n="monday abbreviation label@@label.mo">Mo</span>
              <span *ngSwitchCase="days.TU"
                i18n="tuesday abbreviation label@@label.tu">Tu</span>
              <span *ngSwitchCase="days.WE"
                i18n="wednesday abbreviation label@@label.we">We</span>
              <span *ngSwitchCase="days.TH"
                i18n="thursday abbreviation label@@label.th">Th</span>
              <span *ngSwitchCase="days.FR"
                i18n="friday abbreviation label@@label.fr">Fr</span>
              <span *ngSwitchCase="days.SA"
                i18n="saturday abbreviation label@@label.sa">Sa</span>
            </span><ng-container *ngIf="i < rrule.weekdays.length - 1">,&nbsp;</ng-container>
          </ng-container> )</span>
        <span class="d-block mt-1">
          <span class="text-capitalize" *ngIf="event.rrule?.frequency !== 4">{{eventTimes.startTime}} - {{eventTimes.endTime}} </span>
          <span *ngIf="event.is_recurring && event.rrule?.frequency !== 4">&nbsp;&#183; &nbsp;</span>
          <span *ngIf="event.is_recurring">{{event.occurrences_count}} &nbsp;<ng-container
            i18n="Recurring events label@@label.recurringEvents">Reccurring events</ng-container></span>
        </span>
        <span class="d-block mt-2">
          <span *ngIf="event.attendees_count"
            [ngClass]="{'text-accent': !event.is_recurring && event.capacity.max > 0 && event.attendees_count >= event.capacity.max}">
            {{event.attendees_count}}
            <ng-container *ngIf="event.capacity.max > 0 && event.occurrences_count === 1">/{{event.capacity.max}}&nbsp;</ng-container>
            <ng-container i18n="Attendees label@@label.attendees">Attendees</ng-container>&nbsp;
            <ng-container *ngIf="!event.is_recurring && event.capacity.max > 0 && event.attendees_count >= event.capacity.max"
              i18n="Waiting list available label@@label.waitingListAvailable">(Waiting list available)</ng-container>
          </span>
        </span>

      </p>
      <h2 class="m-0 card-header">{{event.name}}</h2>

    </div>
  </a>
  <div class="px-3 pb-3">
    <div class="text-label mb-2">
      <span *ngIf="event.municipalities?.length">{{event.municipalities[0] ? event.municipalities[0].name : ''}}
        &#183;</span>
      <span *ngIf="event.municipalities && event.municipalities.length > 1"> +{{event.municipalities.length}} <span i18n="+ more label@@label.plusMore">more</span>
        &#183;</span>
      <div class="mt-2" *ngIf="event.age_group && event.age_group.length > 0 && event.age_group.length < 5; else allBadges">
        <span class="badge-label mr-2"
          *ngFor="let ag of event.age_group"
          [ngClass]="{
            'badge-label-children': ag === 'children',
            'badge-label-teenger': ag === 'teenager',
            'badge-label-family': ag === 'family',
            'badge-label-senior': ag === 'senior',
            'badge-label-pensioner': ag === 'pensioner'
          }">
          {{ageGroupKeyToLabel(ag)}}
        </span>

        <span *ngIf="event.distance">
          &#183;&nbsp;
          {{+event.distance / 1000 | number : '1.0-0'}} km
        </span>
      </div>
      <ng-template #allBadges>
        <div class="mt-2">
          <span class="badge-label badge-label-all mr-2" i18n="Suitable for all badge@@badge.all">
            All
          </span>

          <span *ngIf="event.distance">
            &#183;&nbsp;
            {{+event.distance / 1000 | number : '1.0-0'}} km
          </span>
        </div>
      </ng-template>

      <div class="mt-2 text-truncate">{{event.organization?.name}}</div>
    </div>

    <div class="d-flex">
      <ng-container *ngIf="!isParticipantsShown">
        <button mat-stroked-button
          *ngIf="editLink && !isEnded"
          color="primary"
          class="mr-2"
          [routerLink]="editLink"
          i18n="Edit button label@@editButtonLabel">Edit</button>
        <button mat-stroked-button
          *ngIf="editLink"
          color="primary"
          [routerLink]="copyLink"
          i18n="Copy button label@@copyButtonLabel">
          Kopier
        </button>
        <button mat-button
          *ngIf="editLink && event.is_canceled"
          color="primary"
          i18n="Canceled button label@@canceledButton"
          disabled>
          Canceled
        </button>
        <button mat-button
          *ngIf="editLink && !event.is_published"
          class="drafted"
          i18n="Drafted button label@@draftedButton"
          disabled>
          Drafted
        </button>
      </ng-container>

      <ng-container *ngIf="isParticipantsShown">
        <button mat-stroked-button
          color="primary"
          class="mr-2 mt-2"
          (click)="openParticipantsDialog($event)"
          i18n="Participation list button label@@button.participationList">
          Participation list
        </button>
      </ng-container>

      <button mat-icon-button
        color="primary"
        class="primary-icon-button ml-auto"
        [matMenuTriggerFor]="cardMenu"
        *ngIf="editLink"
        (click)="$event.stopPropagation()"
        aria-label="Open settings menu"
        i18n-aria-label="Open settings menu@@tooltip.openSettingMenu">
        <mat-icon fontSet="material-icons-outlined"
          class="material-icons-round">more_vert
        </mat-icon>
      </button>

      <mat-menu #cardMenu="matMenu">
        <a mat-menu-item
          (click)="deleteEvent($event)">
          <span i18n="Delete activity button@@button.deleteActivity">Delete activity</span>
        </a>
      </mat-menu>
    </div>

  </div>
</div>
