import {UserMunicipality} from "@shared/models/user-municipality.model";

export const HopDevMunicipality: UserMunicipality = {
  name: 'hop-dev',
  slogan: {
    value: 'Happenings & FUN stuff for innbyggere på Hop', id: 'banner.default', meaning: 'bannerSlogan',
    description: 'defaultSlogan',
    logoURL: '',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/2b0b8ea4-209c-4504-9d82-7a4f64187bdc/Troldhaugen.avif',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
}
