import { environment } from '@environments/environment';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@store';
import { BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Component({
  selector: 'friskus-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  public isCritical = false;
  public message: string;
  public visible: boolean = !!environment.news;
  public isMobile: Observable<BreakpointState>;
  public isExpanded = false;

  @ViewChild("container")
  public containerRef: ElementRef<HTMLElement>;

  constructor(
    private store: Store,
    private screenType: ScreenTypeService,
  ) {
    this.isMobile = this.screenType.isHandset;
  }

  public get classFromType(): string {
    return (this.isCritical)
      ? 'announcement-container announcement-important'
      : 'announcement-container announcement-news';
  }

  public get iconFromType(): string {
    return (this.isCritical) ? 'announcement' : 'campaign';
  }

  expand(event): void {
    if (event.target.nodeName == 'A') return;
    if (this.isExpanded) {
      this.containerRef.nativeElement?.classList.remove('expand');
      this.isExpanded = false;
    } else {
      this.containerRef.nativeElement?.classList.add('expand');
      this.isExpanded = true;
    }
  }

  ngOnInit(): void {
    this.store.select('announcement').subscribe((announcement: string) => {
      if (announcement) {
        this.isCritical = true;
        this.visible = true;
        this.message = announcement;
      }
    });
  }
}
