import { Injectable, Inject, OnInit, Injector, ComponentRef, ElementRef } from '@angular/core';
import {
  Overlay, OverlayConfig, OverlayRef, OriginConnectionPosition, OverlayConnectionPosition, ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { CustomOverlayRef } from './overlay-ref';
import { CUSTOM_DIALOG_DATA } from './overlay.tokens';



export interface Image {
  name: string;
  url: string;
}

export interface CustomOverlayDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  injectedDataList?: any;
  globalPosition?: boolean;
  scrollStrategy?: OverlayScrollStrategy;
}

export enum OverlayScrollStrategy {
  block = 'block',
  reposition = 'reposition',
  close = 'close'
}

export const DEFAULT_CONFIG: CustomOverlayDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  injectedDataList: null,
  scrollStrategy: OverlayScrollStrategy.block
};

@Injectable()
export class OverlayService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: CustomOverlayDialogConfig = {}, elementToConnectTo: ElementRef, componentForPortal: any) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef: any = this.createOverlay(dialogConfig, elementToConnectTo);

    // Instantiate remote control
    const dialogRef = new CustomOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef, componentForPortal);
    dialogRef.eventEmitter = overlayComponent.eventEmitter;
    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: CustomOverlayDialogConfig, elementToConnectTo: ElementRef) {
    const overlayConfig = this.getOverlayConfig(config, elementToConnectTo);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef, config: CustomOverlayDialogConfig,
    dialogRef: CustomOverlayRef, componentForPortal) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(componentForPortal, null, injector);
    const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: CustomOverlayDialogConfig, dialogRef: CustomOverlayRef) {

    const newInjector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: CUSTOM_DIALOG_DATA, useValue: config.injectedDataList },
        { provide: CustomOverlayRef, useValue: dialogRef }
      ]
    })

    return newInjector;
  }

  private getOverlayConfig(config: CustomOverlayDialogConfig, elementToConnectTo: ElementRef): OverlayConfig {
    let positionStrategy;
    let scrollStrategy;
    if (config.globalPosition) {
      positionStrategy = this.overlay.position()
        .global();
    } else {
      positionStrategy = this.overlay.position()
        .flexibleConnectedTo(elementToConnectTo).withPositions([
          // To top
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          },
          // To bottom
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          // To right
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
          }
        ])
        .withFlexibleDimensions(false)
        .withPush(false);
    }

    switch (config.scrollStrategy) {
      case OverlayScrollStrategy.block:
        scrollStrategy = this.overlay.scrollStrategies.block();
        break;

      case OverlayScrollStrategy.reposition:
        scrollStrategy = this.overlay.scrollStrategies.reposition();
        break;

      case OverlayScrollStrategy.close:
        scrollStrategy = this.overlay.scrollStrategies.close();
        break;

      default:
        break;
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy,
      positionStrategy
    });

    return overlayConfig;
  }
}
