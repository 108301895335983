export const MODAL_SIZE = {
    small: '420px',
    medium: '600px',
    large: '800px',
    fullScreen: {
        minWidth: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        minHeight: '80vh'
    }
};
