import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData, DIALOG_TYPE } from './confirmation-dialog.model';


@Component({
  selector: 'friskus-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public dialogType = DIALOG_TYPE;
  public dialogData: ConfirmationDialogData = {
    type: this.dialogType.DELETE_PHOTO
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.dialogData = Object.assign({}, this.dialogData, data);
    }
  }

  ngOnInit() {
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

}
