<button mat-icon-button
  type="button"
  class="upload-button"
  aria-label="Upload a file"
  i18n-aria-label="Upload a file button@@button.uploadFile"
  (click)="openUploadFile()">
  <mat-icon>attach_file</mat-icon>
</button>

<ngx-uploadcare-widget-custom [images-only]="upoloadConfig.imagesOnly"
  [public-key]="upoloadConfig.publicKey"
  (on-upload-complete)="onDocumentLoad($event)"
  [tabs]="upoloadConfig.tabs"
  [multiple]="upoloadConfig.multiple"
  previewStep="true"
  effects="all">
</ngx-uploadcare-widget-custom>