//////////////////////////
// General filter types //
//////////////////////////

export enum FilterType {
  SingleSelect = 0,
  MultiSelect = 1,
  NumericRange = 2,
  Date = 3,
  DateRange = 4,
  QuickFilter = 5,
  QuickFilterItem = 6,
  DistanceFrom = 7,
}

export type FilterConfig = {
  filterGroupId: string;
  filterId: string;
  type: FilterType;
  queryParamName: string | string[];
  label: string;
  hideUi?: boolean;
  disableClear?: boolean;
  conflictingFilterIds?: string[];
  requireLogin?: boolean;
};

export type Filter<T> = FilterConfig & {
  filterValue: T;
};

///////////////////
// Select filter //
///////////////////

export type SelectFilterOption<T> = {
  label: string;
  subLabel?: string,
  value: T;
  lock?: boolean;
};

export type SelectFilterValue<T> = {
  value: SelectFilterOption<T>;
  index: number;
};


export function isSelectFilterValue(obj: any): obj is SelectFilterValue<any> {
  return (
    typeof obj === "object" &&
    obj.hasOwnProperty("value") &&
    obj.hasOwnProperty("index")
  );
}

export type SelectFilterConfig<T> = FilterConfig & {
  optionFactory: () => Promise<SelectFilterOption<T>[]>;
};

export function isSingleSelectFilterConfig(
  obj: any
): obj is SelectFilterConfig<any> {
  return obj.type === FilterType.SingleSelect;
}
export function isSingleSelectFilter(
  obj: any
): obj is Filter<SelectFilterValue<any>> {
  return isSingleSelectFilterConfig(obj) && obj.hasOwnProperty("filterValue");
}

/////////////////////////
// Multi-select filter //
/////////////////////////

export type MultiSelectFilterOption<T> = {
  label: string;
  subLabel?: string;
  value: T;
  subOptions?: SelectFilterOption<T>[];
  lock?: boolean;
};

export type MultiSelectIndex = {
  index: number;
  subIndices?: number[];
};

export type MultiSelectFilterValue<T> = {
  value: MultiSelectFilterOption<T>[];
  indices: MultiSelectIndex[];
};

export function isMultiSelectFilterValue(
  obj: any
): obj is MultiSelectFilterValue<any> {
  return (
    typeof obj === "object" &&
    obj.hasOwnProperty("value") &&
    obj.hasOwnProperty("indices")
  );
}

export type MultiSelectFilterConfig<T> = FilterConfig & {
  subOptionsQueryParamName?: string;
  optionFactory: () => Promise<MultiSelectFilterOption<T>[]>;
};

export type MultiSelectFilter<T> = MultiSelectFilterConfig<T> & {
  filterValue: T;
};

export function isMultiSelectFilterConfig(
  obj: any
): obj is MultiSelectFilterConfig<any> {
  return obj.type === FilterType.MultiSelect;
}

export function isMultiSelectFilter(
  obj: any
): obj is MultiSelectFilter<MultiSelectFilterValue<any>> {
  return isMultiSelectFilterConfig(obj) && obj.hasOwnProperty("filterValue");
}

//////////////////////////
// Numeric range filter //
//////////////////////////

export type NumericRangeFilterValue = {
  start: number;
  end: number;
};

export type NumericRangeFilterConfig = FilterConfig & {
  min: number;
  max: number;
  stepSize?: number;
};

export function isNumericRangeFilterConfig(
  obj: any
): obj is NumericRangeFilterConfig {
  return obj.type === FilterType.NumericRange;
}

export function isNumericRangeFilter(
  obj: any
): obj is Filter<NumericRangeFilterValue> {
  return isNumericRangeFilterConfig(obj) && obj.hasOwnProperty("filterValue");
}

///////////////////////
// Date range filter //
///////////////////////

export type DateRangeFilterValue = {
  from: Date | null;
  to: Date | null;
};

export function isDateRangeFilterConfig(
  obj: any
): obj is Filter<DateRangeFilterValue> {
  return obj.type === FilterType.DateRange;
}

export function isDateRangeFilter(
  obj: any
): obj is Filter<DateRangeFilterValue> {
  return (
    isDateRangeFilterConfig(obj) &&
    obj.hasOwnProperty("filterValue") &&
    !!obj.filterValue.from &&
    !!obj.filterValue.to
  );
}

export function isDateRangeFilterValue(obj: any): obj is DateRangeFilterValue {
  return (
    typeof obj === "object" &&
    !!obj.from &&
    !!obj.to
  );
}

//////////////////
// Quick filter //
//////////////////

export type QuickFilterConfig<T> = FilterConfig & {
  filters: Filter<T>[];
};

export function isQuickFilterConfig<T>(obj: any): obj is QuickFilterConfig<T> {
  return obj.type === FilterType.QuickFilter && obj.value === undefined;
}

export function isQuickFilter<T>(obj: any): obj is Filter<Filter<T>[]> {
  return obj.type === FilterType.QuickFilter && obj.filterValue !== undefined;
}

export function isQuickFilterItem<T>(obj: any): obj is Filter<T> {
  return (
    obj.type === FilterType.QuickFilterItem && obj.hasOwnProperty("filterValue")
  );
}

///////////////////
// Distance from //
///////////////////

export type DistanceFromFilterValue = {
  location: { latitude: number; longitude: number; address: string };
  radius: number;
};

export function isDistanceFromFilterConfig(
  obj: any
): obj is Filter<DistanceFromFilterValue> {
  return obj.type === FilterType.DistanceFrom;
}

export function isDistanceFromFilter(
  obj: any
): obj is Filter<DistanceFromFilterValue> {
  return isDistanceFromFilterConfig(obj) && obj.hasOwnProperty("filterValue");
}

export function isDistanceFromFilterValue(
  obj: any
): obj is DistanceFromFilterValue {
  return (
    typeof obj === "object" &&
    obj.hasOwnProperty("location") &&
    obj.hasOwnProperty("radius")
  );
}
