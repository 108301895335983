import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(
    private http: HttpClient
  ) { }

  public incrementMetrics(name: string, tag: string) {
    const url = `${environment.baseUrl}/analytics/metrics/increment`;
    return this.http.post(url, {name, tag});
  }

}
