import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventOccurrence } from '@shared/models/event.model';
import { Certificate } from '@shared/models/certificate.model';

export interface MobileFilterDialogData {
  filters: MobileParticipantsFilter;
  occurrences?: EventOccurrence[];
  certificates?: Certificate[];
  participantType: FilterParticipantType;
}

export interface MobileParticipantsFilter {
  occurrence?: EventOccurrence;
  certificate?: string[];
  role?: string[];
  name?: string;
  email?: string;
  phone?: string;
}

export enum FilterParticipantType {
  event = 'event',
  organization = 'organization'
}

@Component({
  templateUrl: './mobile-participants-filter-dialog.component.html',
  styleUrls: ['./mobile-participants-filter-dialog.component.scss']
})
export class MobileParticipantsFilterDialogComponent implements OnInit {
  public filterForm: UntypedFormGroup;
  public occurrences: EventOccurrence[] = [];
  public certificates: Certificate[] = [];
  public participantTypes = FilterParticipantType;
  public currentParticipantType: FilterParticipantType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MobileFilterDialogData,
    private dialogRef: MatDialogRef<MobileParticipantsFilterDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.occurrences = this.data.occurrences || [];
    this.certificates = this.data.certificates || [];
    this.currentParticipantType = this.data.participantType;
    this.filterForm = this.initForm(this.data.filters);
  }

  public nameFilterChanged() {
    this.filterForm.patchValue({email: null});
    this.filterForm.patchValue({phone: null});
  }

  public emailFilterChanged() {
    this.filterForm.patchValue({name: null});
    this.filterForm.patchValue({phone: null});
  }

  public phoneFilterChanged() {
    this.filterForm.patchValue({email: null});
    this.filterForm.patchValue({name: null});
  }

  public onConfirmClick(): void {
    this.dialogRef.close(this.filterForm.value);
  }

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public onResetClick(): void {
    this.filterForm.reset();
    this.dialogRef.close(this.filterForm.value);
  }

  private initForm(initData: MobileParticipantsFilter) {
    return this.fb.group({
      name: [initData ? initData.name : null],
      email: [initData ? initData.email : null],
      phone: [initData ? initData.phone : null],
      role: [initData ? initData.role : []],
      occurrence: [initData ? initData.occurrence : null],
      certificate: [initData ? initData.certificate : null]
    });
  }
}
