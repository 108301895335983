import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const NaroysundMunicipality: UserMunicipality = {
  name: 'naroysund',
  slogan: {value: '', id: 'banner.naroysund', meaning: 'bannerSlogan', description: 'slogan for naroysund'},
  data: {
    headerTitle: 'Fritidskortet i Nærøysund - støtte til fritidsaktiviteter ' +
      'for barn og unge i familier med økonomiske utfordringer.',
    headerTitleQuestion: ' ',
    headerDescription: ' ',
    headerDescription2: 'Fritidskortet innebærer at barn og unge med behov' +
      ' for det, kan få økonomisk støtte til å drive med organiserte ' +
      ' fritidsaktiviteter uten at foreldrene er nødt til å måtte igjennom en lang søknadsprosess om dette. ' +
      ' <br><br>Fritidskortet er en mulighet for at barn og unge ' +
      ' kan få dekke utgifter til medlemskontingent, utstyr, reiser, cuper,' +
      ' turneringer og lignende, slik at det blir mulig for flere å delta i ' +
      ' organiserte fritidsaktiviteter sammen med venner og klassekamerater.  <br><br>' +
      ' Følg veiledningen under. For mer informasjon se: <a  class="link text-white text-decoration-underline"' +
      ' href="https://namsos.kommune.no/aktuelt/fritidskortet-for-barn-og-unge.10185.aspx"' +
      ' >egen informasjonsside</a>.<br><br>' +
      ' Ved spørsmål ta kontakt på <a  class="link text-white text-decoration-underline"' +
      ' href="mailto:stine.holm@naroysund.kommune.no">stine.holm@naroysund.kommune.no</a> / 476 87 648' +
      ' <br><br> <a class="link text-white text-decoration-underline"' +
      ' href="https://www.bua.io/utlansordninger/bua-naeroysund">BUA Nærøysund:</a>' +
      '<br>De låner ut utstyr til sport og fritid GRATIS, dersom man ønsker å teste en ny aktivitet.',
    amount: 0,
    minAge: 3,
    maxAge: 18,
    minAmountOfActivities: 10
  },
  bannerURL: 'https://ucarecdn.com/12bf9610-3904-4dc2-aa30-f94d0aa41da8/nrysund.png',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskort',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskort',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.naroysund.kommune.no/tjenester/kultur-og-fritid/friskus-og-fritidskort/fritidskort/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.naroysund.kommune.no/tjenester/kultur-og-fritid/bua-naroysund/'
    },
    {type: InfoType.FUNDING, url: 'https://www.naroysund.kommune.no/tjenester/kultur-og-fritid/tilskuddsordninger/'},
  ]
}
