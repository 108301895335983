<mat-icon class="text-error"
  *ngIf="controlName.touched && controlName.invalid"
  matTooltip="Entered data is invalid"
  i18n-matTooltip="Entered data is invalid@@error.enteredDataInvalid">
  error_outline
</mat-icon>

<mat-icon class="rotate-45 pointer icon-reset"
  *ngIf="isReset && controlName.value && !outline"
  (mousedown)="$event.preventDefault()"
  (click)="resetControl()"
  matTooltip="Reset input"
  i18n-matTooltip="Reset input@@hint.resetInput">
  add_circle
</mat-icon>
<mat-icon class="rotate-45 pointer icon-reset-outline"
  *ngIf="isReset && controlName.value && outline"
  (mousedown)="$event.preventDefault()"
  (click)="resetControl()"
  matTooltip="Reset input"
  i18n-matTooltip="Reset input@@hint.resetInput">
  add_circle_outline
</mat-icon>
