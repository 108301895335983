import { THUMBNAIL_DEFAULT, POPULAR_EVENTS_NUMBER } from '@config/index';
import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { EventListItem } from '@shared/models/event.model';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { EVENT_DAYS } from '@features/organisations/event-edit/event-edit.config';
import { EventsFilterBarService } from '@features/events/events-filter-bar/events-filter-bar.service';

@Component({
  selector: 'friskus-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnChanges, OnInit {
  @Input() event: EventListItem;
  @Input() editLink: any;
  @Input() copyLink: any;
  @Input() routerLink: RouterLink;
  @Input() isParticipantsShown: boolean;
  @Input() isOnWaitingList: boolean;
  @Output() openParticipants: EventEmitter<boolean> = new EventEmitter();
  @Output() deleted: EventEmitter<boolean> = new EventEmitter();

  public eventTimes: any = {};
  public isOneDayEvent: boolean;
  public backgroundImg: any;
  public rrule: any;
  public days = EVENT_DAYS;
  public isEnded: boolean;
  public popularEventsNumber = POPULAR_EVENTS_NUMBER;
  public ageGroups: { name: string; id: string; }[];

  constructor(
    private sanitizer: DomSanitizer,
    private eventsFilterBarService: EventsFilterBarService,
    ) { }

  ngOnInit() {
    this.ageGroups = this.eventsFilterBarService.ageGropusTypes;
  }

  ageGroupKeyToLabel(id:string): string {
    let translated:string = '';
    this.ageGroups.forEach(g => {
      if (g.id === id) translated = g.name;
    });
    return translated;
  }

  ngOnChanges() {
    if (this.event.rrule) {
      this.rrule = this.event.rrule;
    }
    if (this.event.start_at && this.event.end_at) {
      this.eventTimes = {
        startDate: moment(this.event.start_at).format('DD MMM YYYY'),
        endDate: moment(this.event.end_at).format('DD MMM YYYY'),
        startTime: moment(this.event.start_at).format('HH:mm'),
        endTime: moment(this.event.end_at).format('HH:mm'),
      };
    }
    if (this.event.start_at) {
      this.isOneDayEvent = moment(this.eventTimes.startDate, 'DD MMM YYYY').isSame(moment(this.eventTimes.endDate, 'DD MMM YYYY'));
      this.isEnded = moment(this.event.end_at).isBefore(moment());
    }
    const thumbnail = this.event.thumbnail_url ? this.event.thumbnail_url : THUMBNAIL_DEFAULT;
    this.backgroundImg = thumbnail;
  }

  public openParticipantsDialog(event) {
    event.stopPropagation();
    this.openParticipants.emit(true);
  }

  public deleteEvent(event) {
    event.stopPropagation();
    this.deleted.emit(true);
  }

}
