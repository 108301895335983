import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {ProgressBarComponent} from './progress-bar.component';
import {ProgressBarService} from './progress-bar.service';
@NgModule({
  declarations: [ProgressBarComponent],
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatProgressBarModule
  ],
  exports: [
    ProgressBarComponent
  ],
  providers: [ProgressBarService]
})
export class ProgressBarModule { }
