import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const BodoVisMunicipality: UserMunicipality = {
  name: 'bodo-vis',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.bodo', meaning: 'bannerSlogan',
    description: 'slogan for Bodø'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/5d1221e8-de50-44d9-a1a3-a12501ec3493/-/preview/-/quality/smart/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: true,
  sunset: false,
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 10,
      maxAge: 13,
      amount: 1600,
      url: 'https://bodo.kommune.no/fritidskortet/',
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      links: [
        {
          title: 'Skattekammeret',
          url: 'https://kirkensbymisjon.no/skattkammeret/bodo/'
        },
        {
          title: 'BUA',
          url: 'https://www.bua.no/bua-bodo/utstyr '
        }
      ]
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {
          title: 'Tusenhjemmet Kultur- og Kunnskapssenter',
          url: 'https://bodo.friskus.com/organisations/187f3447-74d9-4f6c-bb24-771f3b619335'
        },
        {
          title: 'Pensjonistrevyen',
          url: 'https://bodo.friskus.com/organisations/5abef25d-579f-4d68-af80-521db10d66d5'
        },
      ]
    },
    {
      type: InfoType.VENUE,
      url: "https://bodo.kommune.no/kultur/idrettslokaler-og-anlegg/utleie-av-idrettslokaler-og-anlegg/",
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: 'https://bodo.frivilligsentral.no/'
    },
    {
      type: InfoType.VOLUNTEER_WORK,
      url: 'https://bodo.kommune.no/medvirkning-og-frivillighet/frivillighet-i-bodo/'
    },
    {
      type: InfoType.FUNDING, url: 'https://bodo.kommune.no/kultur/tilskuddsordninger/'
    },
  ]
}
