import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SnillbuMunicipality: UserMunicipality = {
  name: 'snillbu',
  slogan: {
    value: 'Snillbu er et flott sted', id: 'banner.jotun', meaning: 'bannerSlogan',
    description: 'slogan for jotun'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  sunset: false,
  infoPageTitle: 'Opplevelsekort',
  infoSlots: [
    {
      type: InfoType.VENUE,
      title: "Kommunesenteret",
      url: "https://example.com",
    },
    {
      type: InfoType.OTHER,
      links: [
        {title: 'BPA', url: 'http://example.com'},
        {title: 'Biler på deling', url: 'http://example.com'},
        {title: 'Fun times', url: 'http://example.com'},
      ]
    },
    {
      type: InfoType.OTHER,
      title: 'BPA',
      url: 'http://example.com'
    },
    {
      type: InfoType.SUPPORT_CONTACT,
      links: [
        {title: 'Søknad om støttekontakt', url: 'http://example.com'}
      ]
    },
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskortet',
      minAge: 12,
      maxAge: 20,
      amount: 2000,
      url: 'http://example.com',
    },
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskort+',
      minAge: 6,
      maxAge: 18,
      url: 'http://example.com',
    },
    {
      type: InfoType.LEISURECARD_CUSTOM,
      title: 'Kulturkort',
      minAge: 9,
      maxAge: 16,
      amount: 1200,
      url: 'http://example.com',
    },
    {type: InfoType.ACTIVITY_GUIDE, url: 'http://example.com'},
    {type: InfoType.EQUIPMENT_RENTAL, url: 'http://example.com'},
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {url: ['/organisations', '7c96875a-8c76-4ffa-a559-879b8aca5d3a'], title: "Badeplassen"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Vindtunnel"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Ungdomshus"},
      ]
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Prate over kaffen"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Tur i fjellet"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Balletskolen"},
      ]
    },
    {type: InfoType.VOLUNTEER_WORK, url: 'http://example.com'},
    {type: InfoType.FUNDING, url: 'http://example.com'},
    {
      type: InfoType.TICKETS_CUSTOM,
      title: "Opplevelseskort",
      url: "https://example.com",
      minAge: 12,
      maxAge: 20,
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      minAge: 12,
      maxAge: 20,
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b']
    },
    {
      type: InfoType.VOLUNTEER_CENTERS,
      links: [
        {url: 'http://example.com', title: 'Arendal Kommune'},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Første Sentral"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Andre Sentral"},
        {url: ['/organisations', 'd047b278-02e5-4672-8069-104e36473d3b'], title: "Tredje Sentral"},
      ]
    }
  ]
}
