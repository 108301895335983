import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const BergenDevMunicipality: UserMunicipality =
  {
  name: 'bergen-dev',
  slogan: {value: '', id: 'banner.begen', meaning: 'bannerSlogan', description: 'slogan for bergen'},
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: true,
  infoSlots: [
    {
      type: InfoType.FRISKUS_TICKETS,
      title: 'Opplevelseskortet',
      name: 'Opplevelseskortet',
      minAge: 6,
      maxAge: 18,
      url: 'https://www.stavanger.kommune.no/sosialtjenester/opplevelseskortet/',
    },
  ]
}
