import { enableProdMode, MissingTranslationStrategy, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppBrowserModule, {
    missingTranslation: MissingTranslationStrategy.Error,
  }).then((ref) => {
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('./ngsw-worker.js');
    // }
    const applicationRef = ref.injector.get(ApplicationRef);
    const appComponent = applicationRef.components[0];
    enableDebugTools(appComponent);
    document.querySelector('.app-loading').classList.add('trans-out');
    document.querySelector('.root').classList.remove('trans-out');
  }).catch(err => console.log(err));
});
