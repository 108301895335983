<div [formGroup]="controlsForm"
  *ngIf="controlsForm"
  class="d-flex align-items-center flex-wrap">
  <mat-checkbox color="primary"
    class="mr-3"
    formControlName="smartCrop"
    (change)="controlChange($event, 'smart')">
    <span i18n="Smart stretch label@@label.smartStretch">Smart stretch</span>
    <mat-icon class="icon icon-hint"
      matTooltip="Scales down an image until one of its dimensions gets equal to some of the specified ones; the rest is cropped. 
        This proves useful when your want to fit as much of your image as possible into a box."
      i18n-matTooltip="Smart stretch image hint@@hint.imageSmartStretch"
      matTooltipClass="tooltip-secondary">info</mat-icon>
  </mat-checkbox>
  <mat-checkbox color="primary"
    class="mr-3"
    formControlName="crop"
    (change)="controlChange($event, 'crop')">
    <span i18n="Crop centered label@@label.cropCentered">Crop centered</span>
    <mat-icon class="icon icon-hint"
      matTooltip="Crops an image using specified dimensions and offsets.
      The color of that box can be changed via the setfill operation."
      i18n-matTooltip="Crop centered hint@@hint.cropCentered"
      matTooltipClass="tooltip-secondary">info</mat-icon>
  </mat-checkbox>
  <mat-checkbox color="primary"
    class="mr-3"
    formControlName="resize"
    (change)="resizeChange($event)">
    <span i18n="Resize to fit label@@label.resizeToFit">Resize to fit</span>
    <mat-icon class="icon icon-hint"
      matTooltip="Resizes an image to fit by height.
      Get more effect for vertical images, with colaboration «Crop centered» function."
      i18n-matTooltip="Resize to fit hint@@hint.resizeToFit"
      matTooltipClass="tooltip-secondary">info</mat-icon>
  </mat-checkbox>
  <mat-checkbox color="primary"
    class="mr-3"
    formControlName="setColor"
    (change)="setFillChange($event, controlsForm.controls.color.value)">
    <span i18n="Set fill color label@@label.setFillColor">Set fill color</span>
    <mat-icon class="icon icon-hint"
      matTooltip="Sets the fill color used with crop, if you are using vertical image"
      i18n-matTooltip="Set fill color hint@@hint.setFillColor"
      matTooltipClass="tooltip-secondary">info</mat-icon>
  </mat-checkbox>
  <mat-form-field appearance="outline"
    class="color-picker">
    <mat-label i18n="Color label@@label.color">Color</mat-label>
    <input matInput
      type="color"
      formControlName="color"
      (change)="colorChanged($event)">
  </mat-form-field>

  <!-- <button type="button"
    mat-stroked-button
    color="primary"
    class="mb-2"
    i18n="Change cover button@@button.changeCover">Set stretch center</button> -->
</div>
