<div mat-dialog-container class="px-3">
  <div mat-dialog-title>
    <h2 class="font-size-xl m-0">{{ title }}</h2>
  </div>
  <div mat-dialog-content>
    <p class="m-0 mb-4 pt-2">{{ text }}</p>
  </div>
  <div
    mat-dialog-actions
    [align]="actionButtonsAlign"
    class="pb-4 pt-0 gap-2"
    [ngClass]="{'grid-row': actionButtonsDirection === 'row', 'grid-col':  actionButtonsDirection === 'column'}"
  >
    <button
      *ngIf="secondaryAction"
      mat-stroked-button
      color="primary"
      type="button"
      class="m-0"
      (click)="onSecondaryAction()"
    >
      {{ secondaryAction.text }}
    </button>
    <button
      class="m-0"
      mat-raised-button
      color="primary"
      mat-dialog-close
      type="button"
      (click)="onPrimaryAction()"
    >
      <span *ngIf="primaryAction">{{ primaryAction?.text }}</span>
      <span *ngIf="!primaryAction" i18n="Close button@@button.close">Close</span>
    </button>
  </div>
</div>
