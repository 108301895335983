import { Inject, Injectable, Optional } from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { bugsnagClient } from '@config/bugsnag.config';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends BugsnagErrorHandler {

  constructor(@Optional() @Inject('ERROR_WRAPPER') private errorWrapper: any) {
    super(bugsnagClient);
  }

  handleError(error: any): any {
    const localStorageDenied  = /localStorage.*denied/;

    if (this.errorWrapper) {
      this.errorWrapper.error = error;
    }

    if (localStorageDenied.test(error.message)) {
      console.error("Friskus.com needs access to local storage to work");
    } else {
      if (typeof error === 'string' || error instanceof Error) {
        // Some other errors could be omited here
        super.handleError(error);
      }
    }
  }
}

// Handler for global errors we want to address.
// Currently, we want to reload the app when encountering a ChunkLoadError,
// as this is most likely due to stale .js files running on client machines after a fresh deploy.
window.addEventListener("error", (error) => {
    const chunkFailedMessage = /ChunkLoadError/;
    if (chunkFailedMessage.test(typeof(error) === "object" ? error.toString() : error)) {
      window.location.reload();
    }
});