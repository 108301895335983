import {UserMunicipality} from "@shared/models/user-municipality.model";
import {environment} from "@environments/environment";

export const DefaultMunicipality: UserMunicipality = {
  name: 'default',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.default', meaning: 'bannerSlogan',
    description: 'defaultSlogan',
    logoURL: '',
  },
  data: {
    headerDescription: 'Dette er et tilbud til deg som er mellom 6 til 18 år. Du kan få 1000 kr som du kan' +
      ' bruke på en eller flere fritidsaktiviteter. Fritidskortet er personlig.' +
      ' Målet med fritidskortet er å bidra til økt deltakelse og inkludering.',
    amount: 1000,
    minAmountOfActivities: 10,
    minAge: 6,
    maxAge: 18
  },
  bannerURL: 'https://ucarecdn.com/d76bc1c8-df44-4727-ac47-4dfc84e9f672/banner_main_updated.jpg',
  bannerType: 'image',
  hasLeisureCard: environment.defaultHasLeisureCard,
  userCanClaimLeisureCard: environment.defaultHasLeisureCard,
  canClaimInOrganization: environment.defaultHasLeisureCard,
  hasSubsidiaryOrg: environment.hasSubsidiaryOrg,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
}
