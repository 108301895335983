import {UserMunicipality} from "@shared/models/user-municipality.model";

export const HaugesundMunicipality: UserMunicipality = {
  name: 'haugesund',
  slogan: {},
  data: {},
  bannerURL: 'https://s3.eu-north-1.amazonaws.com/media.friskus.com/banners/haugesund.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
