import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessengerService } from '@services/remote-api/messenger.service';
import { SnackbarService } from '@services/snackbar.service';
import { Utils } from '@shared/utils/index';
import { SNACKBAR_TYPE } from '@shared/components/ui-components/snackbar/snackbar.model';
import { User } from '@shared/models/user.model';
import { UploadFile } from '@shared/models/uploads.model';

export interface ContactPersonsChatDialogData {
  contactPersons: User[];
  user?: User;
  name?: string;
}
@Component({
  selector: 'friskus-contact-person-chat-dialog',
  templateUrl: './contact-person-chat-dialog.component.html',
  styleUrls: ['./contact-person-chat-dialog.component.scss']
})
export class ContactPersonChatDialogComponent implements OnInit {
  public chatForm: UntypedFormGroup;
  public contactPersons: User[];
  public selectedPerson: User;
  public user: any;
  public isLoading: boolean;
  public dialogData: ContactPersonsChatDialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ContactPersonsChatDialogData,
    private dialogRef: MatDialogRef<ContactPersonChatDialogComponent>,
    private messengerService: MessengerService,
    private fb: UntypedFormBuilder,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit() {
    this.dialogData = this.data;
    this.chatForm = this.initForm();
  }

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public fileUploaded(file: UploadFile) {
    const filesArray = this.chatForm.controls.files as UntypedFormArray;
    filesArray.push(new UntypedFormControl(file));
  }

  public deleteFile(index: number) {
    const filesArray = this.chatForm.controls.files as UntypedFormArray;
    filesArray.removeAt(index);
  }

  public sendMessage() {
    if (!this.chatForm.valid) return;
    this.isLoading = true;
    this.messengerService.sendDirectMessage(this.chatForm.value).subscribe(_ => {
      this.snackBarService.notificationSuccess({type: SNACKBAR_TYPE.MESSAGE_SENT});
      this.dialogRef.close(false);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.snackBarService.commonNotificationFail(err.message);
      Utils.setValidationErrors(this.chatForm, err.errors);
    });
  }

  public imgErrorHandler(person) {
    person.thumbnail_url = null;
  }

  private initForm() {
    return this.fb.group({
      name: [this.dialogData.name || this.dialogData.contactPersons.join(', ')],
      body: ['', Validators.required],
      files: this.fb.array([]),
      users: [
        this.dialogData.user ? [this.dialogData.user.id] : this.dialogData.contactPersons.map(person => person.id),
        Validators.required
      ]
    });
  }

}

