import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const LorenskogVisMunicipality: UserMunicipality = {
  name: 'lorenskog-vis',
  slogan: {},
  data: {},
  bannerURL: 'https://ucarecdn.com/5a92bc8e-2278-4470-bf74-1101e0058287/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  infoPageTitle: 'Leisure account',
  infoSlots: [
    {
      type: InfoType.EQUIPMENT_RENTAL,
      name: 'BUA',
      url: 'https://www.bua.no/utlansordninger/bua-lorenskog',
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '901de9ab-fca0-49da-a8c0-f8a01641846d']
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.lorenskog.kommune.no/tjenester/kultur-idrett-og-fritid/tilskudd-priser-og-stipend/tilskudd/',
    },
    {
      type: InfoType.VENUE,
      url: "https://www.lorenskog.kommune.no/tjenester/kultur-idrett-og-fritid/utleie-av-lokaler/",
    },
    {
      type: InfoType.FRISKUS_TICKETS,
      minAge: 12,
      maxAge: 20,
    },
  ]
}
