import {UserMunicipality} from "@shared/models/user-municipality.model";

export const SorreisaMunicipality: UserMunicipality = {
  name: 'sorreisa',
  slogan: {value: '', id: 'banner.sorreisa', meaning: 'bannerSlogan', description: 'slogan for sorreisa'},
  data: {
    headerDescription: 'Slogan for Sorreisa'
  },
  bannerURL: 'image',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
}

