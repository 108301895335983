import { Component, OnInit, Input, Optional, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'friskus-input-suffix',
  templateUrl: './input-suffix.component.html',
  styleUrls: ['./input-suffix.component.scss']
})
export class InputSuffixComponent implements OnInit {
  @Input() controlName;
  @Optional() @Input() outline = false;
  @Input() isReset = true;
  @Output() valueReset: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
  }

  public resetControl() {
    this.controlName.reset();
    this.valueReset.emit(true);
  }

}
