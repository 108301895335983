import { Component, OnInit } from '@angular/core';
import { EventsService } from '@services/remote-api/events.service';
import { Event } from '@shared/models/event.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { transition, style, animate, trigger, query, stagger } from '@angular/animations';
import { EventFilterQuery } from '@features/events/events-filter-bar/event-filter-query.class';
import { MunicipalitiesService } from '@services/remote-api/municipalities.service';
import {GroupsService} from '@services/remote-api/groups.service';
import { Organization } from '@shared/models/organization.model';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('0.2s', animate('0.5s ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('50ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  animations: [listAnimation],
})
export class SlideshowComponent implements OnInit {
  public events: Event[];
  public slideshowConfigs: any;
  public page = 1;
  public organization: Organization;
  public meta: any;
  public municipalitiesNameFilter: any[] = [];

  private queryParamsSub: Subscription;
  private organizationId: string;
  private defaultSlideshowConfigs = {
    interval: 30,
    size: 15
  };
  private intervalSub: Subscription;
  private interval: Observable<number>;
  private unsubscriber: Subject<void>;
  private query: any = {};
  private cols = 5;

  constructor(
    private eventsService: EventsService,
    private groupService: GroupsService,
    private route: ActivatedRoute,
    private municipalitiesService: MunicipalitiesService
  ) { }

  ngOnInit() {
    this.organizationId = this.route.snapshot.params.id;
    this.queryParamsSub = this.route.queryParams.subscribe((params: any) => {
      const eventQueryParams = Object.assign({}, params);
      delete eventQueryParams.size;
      delete eventQueryParams.interval;
      this.cols = params.col ?? 5;

      if (eventQueryParams && eventQueryParams['municipalities[]']) {
        const municipalitiesArray = Array.isArray(eventQueryParams['municipalities[]']) ?
        eventQueryParams['municipalities[]'] : [eventQueryParams['municipalities[]']];

        this.municipalitiesService.getMunicipalities().subscribe(res => {
          const municipalityList = res.data;

          municipalitiesArray.forEach(municipalityId => {
            this.municipalitiesNameFilter.push(municipalityList.find(item => item.id === municipalityId));
          });
        });
      }

      if (this.organizationId) {
        this.groupService.getGroupById(this.organizationId).subscribe(res => {
          this.organization = res.data;
        });
      }

      this.query = new EventFilterQuery(eventQueryParams);
      this.slideshowConfigs = {
        interval: params.interval || this.defaultSlideshowConfigs.interval,
        size: params.size || this.defaultSlideshowConfigs.size
      };

      this.initEventsSlideshow(this.slideshowConfigs.interval);
    });
  }

  public eventStyle(): string {
    return `grid-template-columns: repeat(${this.cols}, minmax(0, 1fr));`;
  }

  private getEvents(page: number) {
    this.events = [];
    let eventsSub: Observable<any>;

    if (this.organizationId) {
      eventsSub = this.eventsService.getEvents({
        perPage: this.slideshowConfigs.size,
        organization: [this.organizationId],
        ...this.query
      }, page);
    } else {
      eventsSub = this.eventsService.getEvents({perPage: this.slideshowConfigs.size, ...this.query}, page);
    }

    eventsSub.subscribe(res => {
      this.events = res.data;
      this.meta = res.meta;
    });
  }

  private initEventsSlideshow(timeoutTime: number) {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe();
    }
    this.page = 1;
    this.getEvents(this.page);
    this.unsubscriber = new Subject();
    this.interval = interval(timeoutTime * 1000).pipe(
      takeUntil(this.unsubscriber)
    );
    this.intervalSub = this.interval.subscribe(_ => {
      if (this.page < this.meta.last_page) {
        this.page++;
        this.getEvents(this.page);
      } else {
        this.unsubscriber.complete();
        this.initEventsSlideshow(this.slideshowConfigs.interval);
      }
    });
  }

}
