import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { ErrorStateMatcher } from '@angular/material/core';
import { VALIDATION_PATTERN } from '../../../config/validation-patterns.config';

export function startTimeValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value < 0) {
    return { notPositiveNumber: true };
  }
}

export function rangepickerRequiredValidator(control: UntypedFormControl): ValidationErrors {
  if (control.value) {
    if (control.value.begin && control.value.end) {
      return null;
    }
    return { required: true };
  }
  return null;
}

export function timeValidator(startAtKey: string, endAtKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const startAt = moment(group.controls[startAtKey].value, 'HH:mm');
    const endAt = moment(group.controls[endAtKey].value, 'HH:mm');
    if (group.controls[startAtKey].value && !group.controls[startAtKey].value.match(VALIDATION_PATTERN.timePattern)) {
      return { startAtInvalid: true };
    }

    if (group.controls[endAtKey].value && !group.controls[endAtKey].value.match(VALIDATION_PATTERN.timePattern)) {
      return { endAtInvalid: true };
    }

    if (startAt.isAfter(endAt)) {
      return { isAfter: true };
    }

    // if (startAt.add(1, 'hours').isBefore(moment())) {
    //   return { startAtInPast: true };
    // }
  };
}
export function ageValidator(aboveKey: string, belowKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const above = group.controls[aboveKey].value;
    const below = group.controls[belowKey].value;

    if (above && below && above > below) {
      return { ageInvalid: true };
    }
  };
}

export function dateValidator(startAtKey: string, endAtKey: string, startAtTimeKey: string, endAtTimeKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const startAt = moment(group.controls[startAtKey].value);
    const endAt = moment(group.controls[endAtKey].value);

    const startAtTime = group.controls[startAtTimeKey].value
      ? moment(group.controls[startAtTimeKey].value, 'HH:mm')
      : moment().add(1, 'minutes');
    const endAtTime = group.controls[endAtTimeKey].value
      ? moment(group.controls[endAtTimeKey].value, 'HH:mm')
      : moment().add(1, 'minutes');

    startAt.set({ hour: startAtTime.hours(), minute: startAtTime.minutes() });
    endAt.set({ hour: endAtTime.hours(), minute: endAtTime.minutes() });

    if (startAt.isAfter(endAt)) {
      return { startIsAfterEnd: true };
    }
  };
}

export function maxDurationValidator(startAtKey: string, endAtKey: string, days: number) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const startAt = moment(group.controls[startAtKey].value);
    const endAt = moment(group.controls[endAtKey].value);

    if (endAt.diff(startAt, 'days') > days) {
      return { maxDuration : true };
    }
  };
}

export function dateInPastValidator(startAtKey: string, endAtKey: string, startAtTimeKey: string, endAtTimeKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const startAt = moment(group.controls[startAtKey].value);
    const endAt = moment(group.controls[endAtKey].value);

    const startAtTime = group.controls[startAtTimeKey].value
      ? moment(group.controls[startAtTimeKey].value, 'HH:mm')
      : moment().add(1, 'minutes');
    const endAtTime = group.controls[endAtTimeKey].value
      ? moment(group.controls[endAtTimeKey].value, 'HH:mm')
      : moment().add(1, 'minutes');

    startAt.set({ hour: startAtTime.hours(), minute: startAtTime.minutes() });
    endAt.set({ hour: endAtTime.hours(), minute: endAtTime.minutes() });

    if (startAt.isBefore(moment()) || endAt.isBefore(moment())) {
      return { startAtInPast: true };
    }
  };
}

export class StartAtErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched) ||
      (form.errors && (form.errors.endAtInvalid || form.errors.startAtInvalid || form.errors.isAfter)));
  }
}
export class AgeLimitErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched) ||
      (form.form.controls.age.errors && form.form.controls.age.errors.ageInvalid));
  }
}

export class StartAtInPastStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched)
      || (form.errors && form.errors.startAtInPast)
      || (form.errors && form.errors.maxDuration)
      || (form.errors && form.errors.startIsAfterEnd));
  }
}

export class StartAfterEndStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched)
      || (form.errors && form.errors.startIsAfterEnd));
  }
}

export class CustomTimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.parent.invalid && (control.dirty || control.touched) ||
      (form.errors && (form.errors.endAtInvalid || form.errors.startAtInvalid || form.errors.isAfter)));
  }
}

export class CustomStartAtInPastStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective): boolean {
    return !!(control && control.parent.invalid && (control.dirty || control.touched)
      || (form.errors && form.errors.startAtInPast));
  }
}
