<footer class="bg-primary footer d-none d-md-flex"
  [ngClass]="{'sticky': isSticky}"
  #footerElement>
  <div class="container">
    <ul class="d-flex">
      <li>
        <a href="https://www.friskusas.no/om-oss/"
          target="_blank"
          i18n="About Friskus link@@link.aboutFriskus">
          About Friskus
        </a>
      </li>
      <li>
        &#183;
      </li>
      <li>
        <a href="https://www.friskusas.no/privacy-policy/"
          target="_blank"
          i18n="Terms & Conditions link@@link.termsAndConditions">
          Terms & Conditions
        </a>
      </li>
      <li>
        &#183;
      </li>
      <li>
        <a href="https://hjelp.friskus.com/"
           target="_blank"
           i18n="Help faq link@@link.help">Help</a>
      </li>
      <li>
        &#183;
      </li>
      <li>
        <a href="https://ucarecdn.com/34b58950-e00b-4233-a299-045f50618bbc/Tilgjengelighetserklring.pdf"
           target="_blank"
           i18n="Help faq link@@link.uudeclaration">Tilgjengelighetserklæring</a>
      </li>
      <li class="ml-auto">
        <span i18n="label@@hint.contactUs">If you need help contact us at</span>:
        <a href="mailto:support@friskus.com"
          target="_blank">
          support@friskus.com
        </a>
      </li>
    </ul>
  </div>
</footer>
