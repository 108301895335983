<div class="container mt-7">
  <div class="auth-form d-flex justify-content-center">
    <div class="col-12 col-md-6 bg-wrap mt-5 login-form py-lg-3 px-lg-5 py-lg-5 px-lg-7">
      <form [formGroup]="form"
        (ngSubmit)="onSubmit()">

        <ng-container *ngIf="isSecondNameVerify">
          <h2 class="text-center text-uppercase font-weight-bold"
            i18n="Your user profile is created header@@header.verificationAfterRegister">Your user profile is created!</h2>
          <p i18n="Get started with your new user hint@@hint.verificationGetStartedInASecond"
            class="text-center mx-0 mb-1 text">
            Get started with your new user in just a second.
          </p>
        </ng-container>

        <ng-container *ngIf="!isSecondNameVerify">
          <h2 class="text-center text-uppercase font-weight-bold"
            i18n="Great verification header@@header.great">Great</h2>
        </ng-container>

        <p class="text-center mb-3 text"
          i18n="We sent you an email to verify your email address paragraph@@hint.weSentVerificationEmail"
          *ngIf="usernameObj?.email">
          We sent you an email to <b>{{usernameObj?.email}}</b>.
          <br>
          Enter the code from email
        </p>

        <p class="text-center mb-3 text"
          i18n="We sent you an SMS to verify your phone paragraph@@hint.weSentVerificationSMS"
          *ngIf="usernameObj?.phone">
          We sent you an SMS to <b>{{usernameObj?.phone}}</b>.
          <br>
          Enter the code from SMS
        </p>

        <p *ngIf="tooManyAttempts"
          class="bg-warning p-3 text-white rounded"
          i18n="Too many aatempts to receive verification code error@@error.tooManyAttemptsForCode">
          You have requested too many codes. Please wait some time and try again later if code won't come. 
          You could also check your SPAM folder in your email client or check if your phone has stable network connection to receive SMS.
        </p>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label i18n="Enter the code you have received label@@label.enterReceivedCode">Enter the code you have received</mat-label>
          <input matInput
            formControlName="code"
            [textMask]="{mask: verificationCodeMask}">
          <mat-error *ngIf="code.hasError('required')"
            i18n="Enter the 6-digit code required error@@error.code.required">
            Enter the 6-digit code is required
          </mat-error>
          <mat-error *ngIf="code.errors?.serverError">
            {{ code.errors?.serverError}}
          </mat-error>
        </mat-form-field>

        <p class="text-label text-error" *ngIf="validationError">
          {{validationError}}
        </p>

        <div class="text-label font-size-xs d-block mb-3" *ngIf="!isLoading">
          <span i18n="Still no verification code hint@@hint.stillNoCodeLong">Please wait a minute. The code is on the way. Still no verification code? </span>
          <br>
          <a (click)="navigateToUsernameChange()"
            class="link font-size-xs"
            tabindex="0"
            i18n="I haven't received a code link@@link.haventReceivedCode"> I haven't received a code</a>
        </div>

        <button mat-raised-button
          type="submit"
          color="primary"
          class="mb-4 w-100"
          [disabled]="form.invalid || isLoading">
          <span *ngIf="!isLoading">
            <span *ngIf="isRegistration" i18n="Next button label@@button.next">Next</span>
            <span *ngIf="!isRegistration" i18n="Done button label@@button.done">Done</span>
          </span>
          <mat-spinner *ngIf="isLoading"
            class="mx-auto"
            [diameter]="20"
            [strokeWidth]="2"></mat-spinner>
        </button>

      </form>
    </div>
  </div>
</div>
