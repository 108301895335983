import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessengerService } from '@services/remote-api/messenger.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from './../../../store/store';

@Component({
  selector: 'friskus-messenger-icon',
  templateUrl: './messenger-icon.component.html',
  styleUrls: ['./messenger-icon.component.scss']
})
export class MessengerIconComponent implements OnInit, OnDestroy {
  public totalUnreadMessages: number;
  messengerStoreSub: Subscription;
  constructor(
    private messengerService: MessengerService,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit() {
    this.newMessages();
    this.messengerStoreSub = this.store.select('messengerArr').subscribe(event => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const currentRouteState = this.route.snapshot['_routerState'].url;
      if (!currentRouteState.includes('/chat')) {
        this.newMessages();
      }
    });
  }

  ngOnDestroy() {
    if (this.messengerStoreSub) {
      this.messengerStoreSub.unsubscribe();
    }
  }

  public newMessages() {
    this.messengerService.getUnreadMessagesTotal().subscribe(response => {
      this.totalUnreadMessages = response.data.total_unread;
    });
  }

}
