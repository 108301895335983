import {InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const SandnesMunicipality: UserMunicipality = {
  name: 'sandnes',
  slogan: {
    value: 'Sandnes kommune',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/f69ebf5d-7d8b-4b8b-b745-f2bf3df47ac6/Vinterland1450.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: "Sandneskortet",
  infoSlots: [
    // {
    //   type: InfoType.FRISKUS_TICKETS,
    //   name: 'Sandneskortet',
    //   minAge: 6,
    //   maxAge: 15,
    //   url: 'https://www.sandnes.kommune.no/sti/kultur/fritidstilbud/sandneskortet/',
    // },
  ]
}
