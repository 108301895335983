import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Filter,
  FilterConfig,
  MultiSelectFilterConfig,
  MultiSelectFilterValue,
} from "@features/filters/services/filters.types";
import { take } from "rxjs/internal/operators/take";
import { FilterService } from "./filter.service";

@Injectable({
  providedIn: "root",
})
export class TransitionOldToNewFiltersService {
  constructor(
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {}

  public async parseOldMunicipalityFilter(filterConfigs: FilterConfig[]) {
    const params = await this.route.queryParams.pipe(take(1)).toPromise();
    const eventQueryParams = Object.assign({}, params);

    if (
      !eventQueryParams ||
      (!eventQueryParams["municipalities[]"] &&
        !eventQueryParams["municipalities"])
    )
      return;

    const municipalitiesQueryParam =
      eventQueryParams["municipalities[]"] ??
      eventQueryParams["municipalities"];
    const municipalitiesArray = Array.isArray(municipalitiesQueryParam)
      ? municipalitiesQueryParam
      : [municipalitiesQueryParam];

    const filterConfig = filterConfigs.find(
      (x) => x.queryParamName === "municipalities"
    ) as MultiSelectFilterConfig<string>;

    if (!filterConfig) return;

    const filterValue: MultiSelectFilterValue<string> = { value: municipalitiesArray.map(
      x => ({
        value: x,
        label: ""
      })
    ), indices: []};

    const filter: Filter<MultiSelectFilterValue<string>> = {
      ...filterConfig,
      filterValue,
    };
    this.filterService.addFilter(filter);
  }
}
