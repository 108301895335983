import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';

export class CustomOverlayRef {
  public eventEmitter: EventEmitter<any>;

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    this.overlayRef.dispose();
  }
  afterClosed(value?: EventEmitter<any> | any): EventEmitter<any> | any {
    return this.eventEmitter;
  }

}
