import {Component, Inject, OnInit} from '@angular/core';
import {MunicipalitiesService} from '@services/remote-api/municipalities.service';
import {Municipality} from '@shared/models/municipality.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'friskus-choose-municipality',
  templateUrl: './choose-municipality.component.html',
  styleUrls: ['./choose-municipality.component.scss']
})
export class ChooseMunicipalityComponent implements OnInit {

  chunks: Municipality[] = [];
  isLoading = true;

  constructor(private municipalityService: MunicipalitiesService,
              public dialogRef: MatDialogRef<ChooseMunicipalityComponent>) {}

  ngOnInit() {
    this.getMunicipalities();
  }

  getMunicipalities() {
    this.municipalityService
      .getMunicipalities()
      .subscribe(
        r => this.setMunicipalities(r.data),
        (error) => {
        },
        () => {
          this.isLoading = false;
        });
  }

  setMunicipalities(municipalities: Municipality[] = [], n = 10) {
    this.chunks = municipalities.reduce((r, e, i) =>
      (i % n ? r[r.length - 1].push(e) : r.push([e])) && r
      , []);
  }

  onSelectMunicipality(municipality: Municipality) {
    this.dialogRef.close(municipality);
  }
}
