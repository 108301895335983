import { GroupsService } from '@services/remote-api/groups.service';
import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Optional, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FilterUrlUtilsService } from '@features/filters/services/filter-url-utils.service';
import { GLOBAL_FILTER_GROUP } from '@features/filters/services/filter.service';

@Component({
  selector: 'friskus-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss']
})
export class MainSearchComponent implements OnInit, OnDestroy {
  @Input() showClose = false;
  @Optional() @Input() insideSidenav = false;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  @Output() chosen: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  public searchForm: UntypedFormGroup;
  public isLoading: boolean;
  public eventsSearchItems: any = [];
  public organizationSearchItems: any = [];
  public isFirstSearch = true;
  public isMobile: Observable<BreakpointState>;
  public selectedMunicipality: string;
  public searchSub: Subscription;
  public placeholder: string;

  constructor(
    private organizationService: GroupsService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private screenType: ScreenTypeService,
    private filterUrlUtilsService: FilterUrlUtilsService,
  ) {
    this.isMobile = this.screenType.isHandset;
    this.placeholder = $localize `:Search events and voluntary assignment|@@label.searchEventsVoluntary:Search events and voluntary assignments`;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      search: null
    });
    const searchControl = this.searchForm.get('search');
    this.filterUrlUtilsService.getQueryParamObject$(GLOBAL_FILTER_GROUP).subscribe(params => {
      this.selectedMunicipality = params.municipalities;
    });

    if (searchControl.value) {
      searchControl.setValue(searchControl.value);
    }

    this.searchSub = this.searchForm
      .get('search')
      .valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.eventsSearchItems = [];
          this.organizationSearchItems = [];
          this.isLoading = true;
        }),
        switchMap(value => {
          if (value) {
            return this.organizationService.search(value, this.selectedMunicipality)
              .pipe(
                finalize(() => {
                  this.isLoading = false;
                  this.cdr.detectChanges();
                }));
          } else {
            this.isLoading = false;
            this.cdr.detectChanges();
            return of({ data: { events: [], organizations: [] } });
          }
        }
        )
      )
      .subscribe((usersRes: any) => {
        this.eventsSearchItems = usersRes.data.events ? usersRes.data.events.slice(0, 3) : [];
        this.organizationSearchItems = usersRes.data.organizations ? usersRes.data.organizations.slice(0, 3) : [];
        this.isFirstSearch = false;
      });
  }

  ngOnDestroy(): void {
    this.searchSub.unsubscribe();
  }

  handleItemChoose(itemRoute, query) {
    this.autocomplete.closePanel();
    this.chosen.emit({ itemRoute, query });
  }

  handleOptionSelect(event: MatAutocompleteSelectedEvent): void {
    const itemRoute = event.option.value;
    this.autocomplete.closePanel();
    this.chosen.emit({ itemRoute, query: {} });
  }
}
