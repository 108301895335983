<div class="container">
  <div class="d-flex content pr-3">
    <div class="message">
      <ng-container [ngSwitch]="snackBarData.type">
        <ng-container *ngSwitchCase="snackBarType.CUSTOM">
          <h3 class="mt-0">
            {{snackBarData.header}}
          </h3>
          {{snackBarData.message}}
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.DATA_UPDATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Your data was successfully updated paragraph@@dialog.dataUpdated">Your data was successfully
            updated.</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CHANGES_SAVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Your changes successfully saved paragraph@@dialog.changesSaved">Your changes successfully
            saved</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.PASSWORD_CHANGED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Your password successfully changed paragraph@@dialog.passwordChanged">Your password successfully
            changed</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.GROUP_CREATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Group was created paragraph@@dialog.groupCreated">Group was created</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ORGANIZATION_DELETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Organization was deleted paragraph@@dialog.organizationDeleted">Organization was deleted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.IMAGE_DELETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Image was deleted paragraph@@dialog.imageDeleted">Image was deleted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.DOCUMENT_DELETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Document was deleted paragraph@@dialog.documentDeleted">Document was deleted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_UPDATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event was updated paragraph@@dialog.eventUpdated">Event was updated</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_CREATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event was created paragraph@@dialog.eventCreated">Event was created</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_DELETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event was deleted successfully paragraph@@dialog.eventDeleted">Event was deleted
            successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_CANCELED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event was canceled successfully paragraph@@dialog.eventCanceled">Event was canceled
            successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_DRAFT_CREATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The draft of event was created paragraph@@dialog.eventDraftCreated">The draft of event was created</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.EVENT_DRAFT_UPDATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The draft of event was updated paragraph@@dialog.eventDraftUpdated">The draft of event was updated</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.TASK_COMPLETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Task was completed successfully paragraph@@dialog.taskCompleted">Task was completed
            successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.SAVE_EVENT_DRAFT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event was drafted successfully paragraph@@dialog.eventDrafted">Event was drafted
            successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.COMMENT_ADDED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Comment is added successfully paragraph@@dialog.commentAdded">Comment is added successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.COMMENT_UPDATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Comment was successfully updated@@dialog.commentUpdated">Comment was successfully updated</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.COMMENT_DELETED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Comment was successfully removed@@dialog.commentDeleted">Comment was successfully removed</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.INVITE_SENT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Invite has been sent paragraph@@dialog.inviteSent">Invite has been sent</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.VERIFICATION_SEND">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Verification email has been sent paragraph@@dialog.verificationSent">Verification email has been sent</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.REQUEST_SENT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Request has been sent paragraph@@dialog.requestSent">Request has been sent</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ATTENDED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="You are participating paragraph@@dialog.attended">You are participating</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ACTIVITY_LEFT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="You have left this activity paragraph@@dialog.activityLeft">You have left this activity</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.OCCURENCY_PUBLISHED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event occurency was published@@dialog.occurencyPublished">Event occurency was published</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.OCCURENCY_UNPUBLISHED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Event occurency was unpublished@@dialog.occurencyUnpublished">Event occurency was
            unpublished</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CHECK_EMAIL">
          <span i18n="Email with instructions paragraph@@dialog.emailSent">Instructions to reset password sent.</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ROLE_CHANGED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The member role of the user was changed@@dialog.roleChanged">The member role of the user was
            changed</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MEMBER_REMOVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The member was removed from the organizaztion@@dialog.memberRemoved">The member was removed from
            the organizaztion</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MEMBER_REQUEST_APPROVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="he request from the user was approved@@dialog.requestApproved">Membership request from the user
            was approved</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.OWNER_CHANGED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Ownership transfered@@dialog.ownershipTransfered">Ownership of organization transfered</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MEMBER_REQUEST_CANCELED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Membership request was canceled@@dialog.requestCanceled">Membership request was canceled</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.COHOST_REQUEST_ACCEPTED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Co-host request from another organization was accepted@@dialog.cohostRequestAccepted">Co-host
            request from another organization was accepted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.COHOST_REQUEST_DECLINED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Co-host request was canceled@@dialog.cohostRequestCanceled">Co-host request was canceled</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.SUBSIDIARY_REQUEST_ACCEPTED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Subsidiary request from another organization was accepted@@dialog.subsidiaryRequestAccepted">Subsidiary
            request from another organization was accepted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.SUBSIDIARY_REQUEST_DECLINED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Subsidiary request was canceled@@dialog.subsidiaryRequestCanceled">Subsidiary request was canceled</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MEMBER_ASSIGNED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Members where assigned successfully@@dialog.membersWhereAssignedSuccessfully">Members where
            assigned successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MEMBER_ADDED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="You have become a member of this organization @@dialog.userBecomeMemberSuccessfully">You have successfully become a member of this organization</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ORG_REQUEST_ACCEPT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Organization creation request was accepted@@dialog.organizationAccepted">Organization creation
            request was accepted successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.ORG_REQUEST_DECLINED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Organization creation request was declined@@dialog.organizationDeclined">Organization creation
            request was declined</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.LEISURE_CARD_CREATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Leisure card was created and added to your profile@@dialog.leisureCardCreated">Leisure card was
            created and added to your profile</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.LEISURE_CARD_ENABLED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Leisure card was enabled for this organization@@dialog.leisureCardEnabledForOrg">Leisure card was
            enabled for this organization</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CARD_REFILL">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Card refill was added to queue@@dialog.leisureCardRefill">Card refill was added to queue. Amount will change in not more then 1 hour</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CHAT_MEMBER_REMOVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The member was successfully removed form this chat@@dialog.chatMemberRemoved">The member was
            successfully removed form this chat</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CHAT_REMOVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Chat was deleted@@dialog.chatWasDeleted">Chat was deleted</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CHAT_LEAVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="You leaved this chat@@dialog.chatWasLeaved">You leaved this chat</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CERTIFICATE_REQUESTED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The certificate was successfully requested@@dialog.certificateWasRequested">The certificate was
            successfully requested</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.MESSAGE_SENT">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="The message was sent successfully@@dialog.messageSent">The message was sent successfully</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CULTURE_CARD_ASSIGNED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Culture card was successfully assigned@@dialog.cultureCardAssigned">Culture card was successfully assigned</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CULTURE_CARD_MARKED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Culture card was successfully marked as used@@dialog.cultureCardUsed">Culture card was successfully marked as used a visit</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.CULTURE_CARD_USER_ADDED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="New user was succesfully added into organization@@dialog.cultureCardUserAdded">New user was succesfully added into organization a visit</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.PARTICIPANT_MOVED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Participant was moved in waiting list@@dialog.participantWasMoved">Participant was moved in waiting list</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.NOTIFICATION_SETTINGS_UPDATED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Notification settings updated@@dialog.notificationsSettingsUpdated">Settings updated</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.VERIFICATION_DISABLED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Verification disabled@@dialog.verificationDisabled">2-step verification was disabled</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.VERIFICATION_ENABLED">
          <ng-container *ngTemplateOutlet="successHeader"></ng-container>
          <span i18n="Verification enabled@@dialog.verificationEnabled">2-step verification was enabled</span>
        </ng-container>

        <!-- Error messages -->

        <ng-container *ngSwitchCase="snackBarType.PRIVATE_ORGANIZATION">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="The organization you trying to reach is private@@dialog.organizationprivate">The organization you
            trying to reach is private, please sign in to view it</span>
        </ng-container>
        <ng-container *ngSwitchCase="snackBarType.LEISURE_CARD_FAIL">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="Leisure card was nor created. Please check your data@@dialog.leisureCardFailed">Leisure card was
            nor created. Please check your data</span>
        </ng-container>

        <ng-container *ngSwitchCase="snackBarType.ROLE_CHANGE_FAIL">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="The role is higher than yours@@dialog.higherRole">The role is higher than yours</span>
        </ng-container>

        <ng-container *ngSwitchCase="snackBarType.NO_SELECTED_MEMBERS">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="You must select one or more users from the list@@dialog.selectMember">You must select one or more users from the list</span>
        </ng-container>

        <ng-container *ngSwitchCase="snackBarType.NO_SELECTED_USERS">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="Select a user before registration@@dialog.selectUser">Select a user before registration</span>
        </ng-container>

        <ng-container *ngSwitchCase="snackBarType.VERIFICATION_EMAIL_REUQUIRED">
          <ng-container *ngTemplateOutlet="errorHeader"></ng-container>
          <span i18n="Please enter your email first@@dialog.verificationEmailRequired">Please enter your email first to enable 2-step verification</span>
        </ng-container>

      </ng-container>

      <ng-template #successHeader>
        <h3 class="mt-0"
          i18n="Success header@@successHeader">Success</h3>
      </ng-template>

      <ng-template #errorHeader>
        <h3 class="mt-0"
          i18n="Attention header@@attentionHeader">Attention</h3>
      </ng-template>
    </div>
    <mat-icon class="add_circle pointer"
      (click)="dismiss()"
      matTooltip="Dismiss message"
      i18n-matTooltip="Dismiss message@@hint.dismissMessage">add_circle</mat-icon>
  </div>

</div>
