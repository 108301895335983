import {UserMunicipality} from '@shared/models/user-municipality.model';

export const DovreMunicipality: UserMunicipality = {
  name: 'dovre',
  slogan: {},
  data: {},
  bannerURL: 'https://ucarecdn.com/eda4ac8b-d714-47ef-9659-d86c1e800bf0/Dovre_panorama_Stor.jpeg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto'
}
