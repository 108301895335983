import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Store } from '@store';
import { environment } from '@environments/environment';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { atob as atobNode } from 'atob/node-atob';
import { CookiesService } from '@services/cookies/cookies.service';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { FilterService } from '@features/filters/services/filter.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private baseAuthUrl = environment.apiUrl.identity;
  // Enable superadmin login for local serving
  private cookieDomain = environment.prefix === 'none' || environment.prefix === 'local' ? null : '.friskus.com';

  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private authHttp: GenericHttpService,
    private router: Router,
    private cookiesService: CookiesService,
    private filterService: FilterService,
    @Inject(PLATFORM_ID) private platformId: object) {
    this.restoreAuthStateFromStorage();
    this.saveStateToStorageOnChange();
  }

  restoreAuthStateFromStorage() {
    const ticket = this.getTicket();
    if (ticket) {
      this.saveTicketToStore(ticket);
    }
  }

  saveStateToStorageOnChange() {
    this.authState.subscribe(ticket => {
      this.saveTicketToStorage(ticket);
    });
  }

  saveTicketToStorage(ticket) {
    localStorage.setItem('auth:user', (ticket ? JSON.stringify(ticket) : ''));
    if (ticket && ticket.access_token) {
      const tokenData = this.parseJwt(ticket.access_token);
      const isSuperAdmin = tokenData && tokenData.scopes.includes('admin');

      if (isSuperAdmin) {
        let cookieStorage = this.cookiesService.getItem(document.cookie, `${environment.prefix}-storage`);
        cookieStorage = cookieStorage ? JSON.parse(cookieStorage) : {};
        cookieStorage['auth:user'] = ticket;
        document.cookie = this.cookiesService.setItem(
          document.cookie, `${environment.prefix}-storage`, JSON.stringify(cookieStorage), ticket.expires_in, '/', this.cookieDomain);
        localStorage.removeItem('auth:user');
      } else {
        document.cookie = this.cookiesService.removeItem(document.cookie, `${environment.prefix}-storage`, '/', this.cookieDomain);
      }
    }

    if (!ticket) {
      localStorage.removeItem('auth:user');
    }
  }

  saveGetStartedTicketToStorage(ticket) {
    localStorage.setItem('get-started:username', (ticket ? JSON.stringify(ticket) : ''));
    if (!ticket) {
      localStorage.removeItem('get-started:username');
    }
  }

  saveTicketToStore(ticket) {
    this.store.set('user', ticket);
  }

  getTicket() {
    const storageCookie = this.cookiesService.getItem(document.cookie, `${environment.prefix}-storage`);
    if (storageCookie) {
      const ticket = JSON.parse(storageCookie)['auth:user'];
      if (ticket && ticket.access_token) {
        const tokenData = this.parseJwt(ticket.access_token);
        const isSuperAdmin = tokenData && tokenData.scopes.includes('admin');
        if (isSuperAdmin) {
          // @TODO inconsistent return, whole ticket for Superadmins?!
          return ticket;
        }
      }
    }

    const accessToken = localStorage.getItem('auth:user') || null;
    if (accessToken) {
      return JSON.parse(accessToken);
    }

    return null;
  }

  getStartedTicket() {
    const userInput = localStorage.getItem('get-started:username') || null;
    if (userInput) {
      return JSON.parse(userInput);
    }
    return null;
  }

  register(registerData: any) {
    return this.httpClient
      .post(`${this.baseAuthUrl}/auth/register`, registerData)
      .pipe(tap(ticket => {
        this.saveTicketToStore(ticket);
      }));
  }

  login(username: string, password: string, verificationId?: string) {
    return this.httpClient
      .post(`${this.baseAuthUrl}/auth/login`, {
        username,
        password,
        verification_id: verificationId
      })
      .pipe(tap((ticket: any) => {
        if (!ticket.is_two_factor_enabled) {
          this.saveTicketToStore(ticket);
        } else {
          this.saveTicketToStorage(ticket);
        }
      }));
  }

  getStarted(username: string) {
    return this.httpClient
      .post(`${this.baseAuthUrl}/auth/exists`, {
        username
      })
      .pipe(tap(ticket => {
        this.saveGetStartedTicketToStorage(username);
      }));
  }

  changePassword({ password, password_confirmation }) {
    return this.httpClient
      .post(`${this.baseAuthUrl}/auth/password/change`, {
        password,
        password_confirmation
      });
  }

  restorePassword(resoreData: any) {
    return this.httpClient
      .post(`${this.baseAuthUrl}${API_URL_MAP.auth.base}${API_URL_MAP.auth.password.base}${API_URL_MAP.auth.password.restore}`, {
        ...resoreData
      })
      .pipe(tap(ticket => {
        this.saveTicketToStore(ticket);
      }));
  }

  resetPassword(password, token) {
    return this.httpClient
      .post(`${this.baseAuthUrl}${API_URL_MAP.auth.base}${API_URL_MAP.auth.password.base}${API_URL_MAP.auth.password.reset}`, {
        password,
        token
      });
  }

  resendVerificationEmail() {
    return this.authHttp.post(API_URL_KEY.identity, `${API_URL_MAP.auth.base}/verify/resend`, {});
  }

  verifyTwoFactorCode(two_factor_code: string) {
    return this.authHttp.post(API_URL_KEY.identity, `${API_URL_MAP.auth.base}/verify`, {two_factor_code})
      .pipe(tap(_ => {
        const ticket = this.getTicket();
        this.saveTicketToStore(ticket);
      }));
  }

  enableTwoFactorAuth() {
    return this.authHttp.post(API_URL_KEY.identity, `${API_URL_MAP.auth.base}/two-factor-authentication`, {})
      .pipe(tap(_ => {
        this.updateTwoFactorAuthStatus(true);
      })
    );
  }

  disableTwoFactorAuth() {
    return this.authHttp.del(API_URL_KEY.identity, `${API_URL_MAP.auth.base}/two-factor-authentication`)
      .pipe(tap(_ => {
        this.updateTwoFactorAuthStatus(false);
      })
    );
  }

  public createCode(username: string) {
    const url = `${API_URL_MAP.auth.usernameVerifications}${API_URL_MAP.auth.sendCode}`;
    return this.authHttp.post(API_URL_KEY.identity, url, {username});
  }

  public createEmailVerificationCode(email: string) {
    const url = `${API_URL_MAP.auth.usernameVerifications}${API_URL_MAP.auth.sendEmailCode}`;
    return this.authHttp.post(API_URL_KEY.identity, url, {email});
  }

  public createPhoneVerificationCode(phone: string) {
    const url = `${API_URL_MAP.auth.usernameVerifications}${API_URL_MAP.auth.sendPhoneCode}`;
    return this.authHttp.post(API_URL_KEY.identity, url, {phone});
  }

  public resendVerificationCode(verificationId: string) {
    const url = `${API_URL_MAP.auth.usernameVerifications}/${verificationId}${API_URL_MAP.auth.resend}`;
    return this.authHttp.post(API_URL_KEY.identity, url, {});
  }

  public verifyCode(verification_id: string, code: string) {
    const url = `${API_URL_MAP.auth.usernameVerifications}${API_URL_MAP.auth.verifyCode}`;
    return this.authHttp.post(API_URL_KEY.identity, url, {verification_id, code});
  }

  get authState(): Observable<any> {
    return this.store.select('user');
  }

  get loggedInUserId(): string|null {
    const ticket = this.getTicket();
    if (ticket.access_token !== undefined) {
      const tokenData = this.parseJwt(ticket.access_token);
      return tokenData.sub;
    } else if (ticket.sub !== undefined) {
      return ticket.sub;
    }
    return null;
  }

  get isLoggedIn(): Observable<boolean> {
    return this.store.select('user').pipe(map(user => !!user));
  }

  logout() {
    this.filterService.clearFilters();
    this.store.set('user', undefined);
    this.store.set('userInfo', undefined);
    localStorage.removeItem('auth:user');
    localStorage.removeItem('get-started:username');
    document.cookie = this.cookiesService.removeItem(document.cookie, `${environment.prefix}-storage`, '/', this.cookieDomain);
    if (!this.router.url.split('/').find(item => item === 'events')) {
      this.router.navigate(['/auth/get-started']);
    }
  }

  public parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload;
    if (isPlatformServer(this.platformId)) {
      jsonPayload = decodeURIComponent(atobNode(base64).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    } else {
      jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }

    return JSON.parse(jsonPayload);
  }

  private updateTwoFactorAuthStatus(isEnabled: boolean) {
    const ticket = this.getTicket();
    ticket.is_two_factor_enabled = isEnabled;
    this.saveTicketToStorage(ticket);
    this.saveTicketToStore(ticket);
  }

}
