import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ChooseMunicipalityComponent} from '@shared/components/dialogs/choose-municipality/choose-municipality.component';
import {Municipality} from '@shared/models/municipality.model';
import {MunicipalitiesService} from '@services/remote-api/municipalities.service';
import {Store} from '@store';

@Component({
  selector: 'friskus-check-municipality',
  templateUrl: './check-municipality.component.html',
  styleUrls: ['./check-municipality.component.scss'],
})
export class CheckMunicipalityComponent implements OnInit, OnDestroy {
  public state = false;
  private USER_MUNICIPALITY = 'USER_MUNICIPALITY';
  private ipMunicipalitySubscription;
  private ipMunicipalityKey;

  constructor(public dialog: MatDialog,
              private store: Store,
              private municipalityService: MunicipalitiesService) {
  }

  ngOnInit() {
    this.ipMunicipalitySubscription = this.store
      .select('ipMunicipality')
      .subscribe(municipalityKey => {

        if (!municipalityKey) {
          return;
        }

        this.ipMunicipalityKey = municipalityKey;
        this.state = this.getState(municipalityKey);
      });
  }

  ngOnDestroy() {
    if (this.ipMunicipalitySubscription) {
      this.ipMunicipalitySubscription.unsubscribe();
    }
  }

  close() {
    this.state = false;
  }

  getState(municipalityKey): boolean {
    let isVisible = true;
    const approvedMunicipality = window.localStorage.getItem(this.USER_MUNICIPALITY);
    const selectedMunicipalities = this.getSelectedMunicipalities();

    if (selectedMunicipalities.length > 1) {
      isVisible = false;
    } else if (approvedMunicipality === municipalityKey) {
      isVisible = false;
    }

    return isVisible;
  }

  getSelectedMunicipalities() {
    return JSON.parse(window.localStorage.getItem('municipalities')) || [];
  }

  chooseMunicipality() {
    this.dialog.open(ChooseMunicipalityComponent)
      .afterClosed()
      .subscribe((municipality: Municipality) => this.goToMunicipality(municipality));
  }

  goToMunicipality(municipality: Municipality) {
    window.localStorage.setItem(this.USER_MUNICIPALITY, municipality.key);
    window.location.href = this.municipalityService.getMunicipalityDomain(municipality);
  }

  useCurrentMunicipality() {
    window.localStorage.setItem(this.USER_MUNICIPALITY, this.ipMunicipalityKey);
    this.close();
  }
}
